<template xmlns="http://www.w3.org/1999/html">
  <div v-if="mineData.info" class="wrapper">
    <div class="wrapBox">
      <div class="content">
        <el-form
          ref="form"
          :disabled="isEdit"
          :model="mineData.info"
          label-width="120px"
          :inline="true"
          :rules="rules"
          inline-message
        >
          <el-form-item prop="goods_name" label="商品名称:">
            <el-input
              v-model="mineData.info.goods_name"
              placeholder="请输入商品名称"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item prop="specs" label="规格:">
            <el-input
              v-model="mineData.info.specs"
              placeholder="请输入规格"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item prop="easy_code" label="助记码:">
            <el-input
              v-model="mineData.info.easy_code"
              placeholder="请输入助记码"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="goods_pinyin" label="简拼:">
            <el-input
              v-model="mineData.info.goods_pinyin"
              placeholder="请输入简拼"
            ></el-input>
          </el-form-item> -->
          <!-- <el-form-item prop="goods_code" label="编码:">
            <el-input
              v-model="mineData.info.goods_code"
              placeholder="请输入编码"
              style="width: 200px"
            ></el-input>
          </el-form-item> -->

          <el-form-item prop="class_id" label="品类:">
            <el-select
              v-model="mineData.info.class_id"
              filterable
              placeholder="请选择类别"
            >
              <el-option
                v-for="item in mineData.init.class"
                :key="item.id"
                :label="item.class_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="brand_id" label="品牌:">
            <brand-select
              ref="brandSelect"
              :brand-id="Number(mineData.info.brand_id)"
              @brand-select-change="setBrand"
            ></brand-select>
            <!-- <el-select
              v-model="mineData.info.brand_id"
              filterable
              placeholder="请选择品牌"
            >
              <el-option
                v-for="item in mineData.init.brand"
                :key="item.id"
                :label="item.brand_name"
                :value="item.id"
              ></el-option>
            </el-select> -->
          </el-form-item>

          <el-form-item prop="expire_day_text" label="保质期:">
            <el-row type="flex" justify="space-between">
              <el-col>
                <el-input
                  v-model="expire_day"
                  placeholder=""
                  style="width: 50px"
                  @change="handlerexpireday"
                ></el-input>
                <span>年</span>
              </el-col>
              <el-col>
                <el-input
                  v-model="expire_day1"
                  placeholder=""
                  style="width: 50px"
                  @change="handlerexpireday"
                ></el-input>
                <span>月</span>
              </el-col>
              <el-col>
                <el-input
                  v-model="expire_day2"
                  placeholder=""
                  style="width: 50px"
                  @change="handlerexpireday"
                ></el-input>
                <span>天</span>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item prop="expire_day_stock" label="临期预警天数:">
            <el-input
              v-model="mineData.info.expire_day_stock"
              placeholder="请输入临期预警天数"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item prop="paht" label="通路:" style="width: 100%">
            <el-checkbox-group v-model="path">
              <el-checkbox label="线下" name="paht"></el-checkbox>
              <el-checkbox label="网销" name="paht"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <el-form
          ref="form2"
          :model="mineData"
          :rules="rules2"
          :disabled="isEdit"
          label-width="120px"
          inline
          inline-message
        >
          <el-form-item
            prop="init.unit.unit1.selected"
            label="小单位:"
            style="width: 100%"
          >
            <div class="form-item-wrap">
              <div class="form-item-left">
                <el-select
                  v-model="mineData.init.unit.unit1.selected"
                  placeholder="请选择小单位"
                  clearable
                  @change="xUnitChange"
                  @focus="handlerfocus"
                >
                  <el-option
                    v-for="item in mineData.init.unit.unit1.list"
                    :key="item.dealer_unit_id"
                    :label="item.unit_name"
                    :value="item.dealer_unit_id"
                  ></el-option>
                </el-select>
                <el-checkbox
                  v-model="mineData.info.units.unit1.is_default"
                  :true-label="2"
                  :false-label="1"
                  style="margin-left: 10px"
                  @change="checkChange($event, 1)"
                >
                  默认
                </el-checkbox>
                <el-button
                  type="primary"
                  style="margin-left: 20px"
                  @click="setUnit"
                >
                  设置单位
                </el-button>
              </div>
              <div class="form-item-right">
                <span>条码:</span>
                <el-input
                  v-model="mineData.info.units.unit1.unit_code"
                ></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            v-show="mineData.init.unit.unit1.selected !== ''"
            prop="init.unit.unit3.selected"
            label="大单位:"
            style="width: 100%"
          >
            <div class="form-item-wrap">
              <div class="form-item-left">
                <el-select
                  v-model="mineData.init.unit.unit3.selected"
                  placeholder="请选择大单位"
                  clearable
                  @change="dUnitChange"
                  @focus="handlerfocus"
                >
                  <el-option
                    v-for="item in mineData.init.unit.unit3.list"
                    :key="item.dealer_unit_id"
                    :label="item.unit_name"
                    :value="item.dealer_unit_id"
                  ></el-option>
                </el-select>
                <el-checkbox
                  v-model="mineData.info.units.unit3.is_default"
                  :false-label="1"
                  :true-label="2"
                  style="margin-left: 10px"
                  @change="checkChange($event, 3)"
                >
                  默认
                </el-checkbox>
              </div>
              <div class="form-item-right">
                <span>条码:</span>
                <el-input
                  v-model="mineData.info.units.unit3.unit_code"
                ></el-input>
              </div>
            </div>
          </el-form-item>

          <el-form-item
            v-show="
              mineData.init.unit.unit1.selected !== '' &&
              mineData.init.unit.unit3.selected !== ''
            "
            prop="init.unit.unit2.selected"
            label="中单位:"
            style="width: 100%"
          >
            <div class="form-item-wrap">
              <div class="form-item-left">
                <el-select
                  v-model="mineData.init.unit.unit2.selected"
                  placeholder="请选择中单位"
                  clearable
                  @change="zUnitChange"
                  @focus="handlerfocus"
                >
                  <el-option
                    v-for="item in mineData.init.unit.unit2.list"
                    :key="item.dealer_unit_id"
                    :label="item.unit_name"
                    :value="item.dealer_unit_id"
                  ></el-option>
                </el-select>
                <el-checkbox
                  v-model="mineData.info.units.unit2.is_default"
                  :false-label="1"
                  :true-label="2"
                  style="margin-left: 10px"
                  @change="checkChange($event, 2)"
                >
                  默认
                </el-checkbox>
              </div>
              <div class="form-item-right">
                <span>条码:</span>
                <el-input
                  v-model="mineData.info.units.unit2.unit_code"
                ></el-input>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <el-form
          ref="form3"
          :disabled="isEdit"
          :model="mineData.info.units"
          :rules="rules3"
          label-width="120px"
          inline
          inline-message
        >
          <!-- 大单位 -->
          <el-form-item
            v-if="
              mineData.init.unit.unit1.selected !== '' &&
              mineData.init.unit.unit3.selected !== ''
            "
            prop="unit3.goods_cv"
            :label="'1' + numtoval(3) + '='"
          >
            <el-input
              v-model="mineData.info.units.unit3.goods_cv"
              style="width: 65px; margin-right: 5px"
            ></el-input>
            <!-- 小单位 -->
            <span>{{ numtoval(1) }}</span>
          </el-form-item>
          <!-- 中单位 -->
          <el-form-item
            v-if="
              mineData.init.unit.unit1.selected !== '' &&
              mineData.init.unit.unit2.selected !== '' &&
              mineData.init.unit.unit3.selected !== ''
            "
            prop="unit2.goods_cv"
            :label="'1' + numtoval(2) + '='"
          >
            <el-input
              v-model="mineData.info.units.unit2.goods_cv"
              style="width: 65px; margin-right: 5px"
            ></el-input>
            <!-- 小单位 -->
            <span>{{ numtoval(1) }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="checkBox">
        <el-checkbox
          v-model="middleCheck.a"
          :disabled="isEdit"
          label="自动换算价格"
          :true-label="2"
          :false-label="1"
        ></el-checkbox>
        <el-checkbox
          v-model="middleCheck.b"
          :disabled="isEdit"
          :true-label="1"
          :false-label="0"
          label="下单时需选生产日期"
        ></el-checkbox>
        <el-checkbox
          v-model="middleCheck.c"
          :true-label="2"
          :false-label="1"
          label="下单时销售价可修改"
          :disabled="isEdit"
        ></el-checkbox>
        <el-checkbox
          v-model="middleCheck.d"
          :disabled="isEdit"
          :true-label="2"
          :false-label="1"
          label="下单时退货价可修改"
        ></el-checkbox>
      </div>
      <div class="table">
        <el-table ref="table" stripe :data="tableData" border>
          <el-table-column
            v-for="(item, index) in columns"
            :key="index"
            :prop="item.prop"
            :align="item.align"
            :label="item.label"
            width=""
          >
            <template v-if="item.prop === 'units'" #default="{ row, $index }">
              <!-- <el-input v-model="row.unit_name"></el-input> -->
              <template v-if="$index == 0">
                {{ row.unit_name }}
                <!-- <el-select
                  v-model="mineData.init.unit.unit1.selected"
                  disabled
                  style="width: 90%"
                >
                  <el-option
                    v-for="rowitem in mineData.init.unit.unit1.list"
                    :key="rowitem.dealer_unit_id"
                    :label="rowitem.unit_name"
                    :value="rowitem.dealer_unit_id"
                  ></el-option>
                </el-select> -->
              </template>
              <template v-else-if="$index == 1">
                {{ row.unit_name }}
                <!-- <el-select
                  v-model="mineData.init.unit.unit3.selected"
                  disabled
                  style="width: 90%"
                >
                  <el-option
                    v-for="rowitem in mineData.init.unit.unit3.list"
                    :key="rowitem.dealer_unit_id"
                    :label="rowitem.unit_name"
                    :value="rowitem.dealer_unit_id"
                  ></el-option>
                </el-select> -->
              </template>
              <template v-else-if="$index == 2">
                {{ row.unit_name }}
                <!-- <el-select
                  v-model="mineData.init.unit.unit2.selected"
                  disabled
                  style="width: 90%"
                >
                  <el-option
                    v-for="rowitem in mineData.init.unit.unit2.list"
                    :key="rowitem.dealer_unit_id"
                    :label="rowitem.unit_name"
                    :value="rowitem.dealer_unit_id"
                  ></el-option>
                </el-select> -->
              </template>
              <!-- <span>{{ row.sell_price }}</span> -->
            </template>
            <!-- <template v-else-if="item.prop === 'base_price'" #default="{ row }">
              <span>{{ row.base_price }}</span>
            </template> -->
            <template v-else #default="{ $index, column, row }">
              <!-- <el-input v-model="row.base_price"></el-input> -->
              <el-input
                v-model="row[item.prop]"
                :disabled="isEdit"
                @input="priceInput($event, row, $index, column)"
              ></el-input>
              <!-- <span style="background-color: red">{{ row[item.prop] }}</span> -->
            </template>
          </el-table-column>
        </el-table>
        </br>
        <label>
         <span>备注：</span> <el-input :disabled="isEdit" v-model="mineData.info.goods_remark" placeholder="填写的文字将显示在手机端下单商品详情弹框中" style="width: 500px"/>
        </label>
        <el-table
          v-if="costshow"
          :data="costtable"
          style="width: 50%; margin-top: 50px"
        >
          <el-table-column
            prop="new_price"
            label="小单位成本价"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_at"
            label="时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="操作记录"
            align="center"
          ></el-table-column>
        </el-table>
      </div>

    </div>
  </div>
</template>
<script>
  import toPinYin from '@/utils/toPinyin'
  import BrandSelect from '@/baseComponents/brandSelect'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'BaseInfo',
    components: { BrandSelect },
    props: {
      alldata: {
        type: Object,
        default: () => {},
        required: true,
      },
      showdialog: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      isEdit: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      tit: {
        type: String,
        default: () => {
          return ''
        },
      },
    },
    data() {
      var isNumber = (rule, val, cb) => {
        if (val == '') {
          cb(new Error('不能为空'))
        } else if (!Number(val)) {
          cb(new Error('请输入数字'))
        } else if (
          this.mineData.info.units.unit2.goods_cv !== '' &&
          this.mineData.info.units.unit1.goods_cv !== ''
        ) {
          console.log(this.mineData.info.units.unit2.goods_cv)
          if (this.mineData.info.units.unit2.goods_cv) {
            if (
              (this.mineData.info.units.unit3.goods_cv /
                this.mineData.info.units.unit2.goods_cv) %
                1 !==
              0
            ) {
              cb()
              // cb(new Error('大单位除以中单位必须是大于1的整数'))
            } else {
              cb()
            }
          }
        } else {
          cb()
        }
      }
      var choosexUnit = (rule, val, cb) => {
        if (val == 0) {
          cb(new Error('请选择小单位'))
        } else {
          cb()
        }
      }
      var isBrand = (rule, val, cb) => {
        if (val == 0) {
          cb(new Error('请选择品牌'))
        } else {
          cb()
        }
      }
      var isClass = (rule, val, cb) => {
        if (val == 0) {
          cb(new Error('请选择类别'))
        } else {
          cb()
        }
      }
      return {
        costshow: false,
        costtable: [],
        // data: {},
        // 表单是否通过验证
        formgo: false,
        mineData: {},
        baseData: {
          topForm: {},
          form: {
            goods_name: '',
            zhujima: '',
            pinyin: '',
            code: '',
            specification: '',
            brand: '',
            class: '',
            shelfLife: '',
            warnDate: '',
            path: [],
            xUnits: '请选择',
            zUnits: '请选择',
            dUnits: '请选择',
            xUnitsR: false,
            xUnitsCode: '',
            zUnitsR: false,
            zUnitsCode: '',
            dUnitsR: false,
            dUnitsCode: '',
            unitsA: '',
            unitsB: '',
          },
          // form下拉List
          brandList: [],
          classList: [],
          xUnitsList: [],
          zUnitsList: [],
          dUnitsList: [],
          // checkBox
          checkBox: [],
          tableData: [],
        },
        expire_day: '',
        expire_day1: '',
        expire_day2: '',
        // 通路
        path: [],

        rules: {
          goods_name: [
            {
              required: true,
              message: '请输入商品名称',
              trigger: 'blur',
            },
            {
              min: 1,
              max: 60,
              message: '长度在 1 到 60 个字符',
              trigger: 'blur',
            },
          ],
          brand_id: [
            {
              required: true,
              validator: isBrand,
              trigger: 'change',
            },
          ],
          class_id: [
            {
              required: true,
              validator: isClass,
              trigger: 'change',
            },
          ],
        },
        rules2: {
          'init.unit.unit1.selected': [
            { required: true, validator: choosexUnit, trigger: 'blur' },
          ],
        },
        rules3: {
          'unit2.goods_cv': [
            {
              required: true,
              validator: isNumber,
              trigger: 'change',
            },
          ],
          'unit3.goods_cv': [
            {
              required: true,
              validator: isNumber,
              trigger: 'change',
            },
          ],
        },
        // 表格
        checkList: [],
        columns: [],
        tableData: [],
        middleCheck: {
          a: '',
          b: '',
          c: '',
          d: '',
        },
      }
    },
    computed: {
      // finallyColumns() {
      //   let finallyArray = []
      //   this.checkList.forEach((checkItem) => {
      //     finallyArray.push(
      //       this.mineData.init.columns.filter(
      //         (item) => item.label === checkItem
      //       )[0]
      //     )
      //   })
      //   return _.sortBy(finallyArray, (item) => item.order)
      // },
      // this.mineData.init.unit.unit2.is_default = this.mineData.init.unit.unit2.is_default == 1 ? 0 ? 1() {
      //   return mineData.init.unit.unit2.is_default == 1 ? 0 : 1
      // },
    },
    watch: {
      showdialog(val) {
        if (!val) {
          this.mineData = {}
        }
        // this.mineData.init.unit.unit1.selected = ''
        // this.mineData.init.unit.unit2.selected = ''
        // this.mineData.init.unit.unit3.selected = ''
        // if (!val) {
        //   this.baseData.form.xUnits = '请选择'
        //   // 关闭窗口 重置表单
        // this.resetForm('form')
        // this.resetForm('form2')
        // this.resetForm('form3')
        //   // 选择框清空
        //   this.baseData.checkBox = []
        // }
      },
      // // 转拼音
      // 'mineData.info.goods_name'(val) {
      //   this.mineData.info.goods_pinyin = toPinYin.toPinYin(val)
      // },
    },
    created() {
      this.mineData = this.alldata
      if (this.mineData.info.brand_id == '0') {
        this.mineData.info.brand_id == ''
      }

      // 处理通路的选择框
      this.path = this.pathF()
      // 处理默认选择框
      console.log('1小单位', this.mineData.info.units.unit1.is_default)
      console.log('1中单位', this.mineData.info.units.unit2.is_default)
      console.log('1大单位', this.mineData.info.units.unit3.is_default)
      this.mineData.info.units.unit1.is_default = this.defaultCheckbox(1)
      this.mineData.info.units.unit2.is_default = this.defaultCheckbox(2)
      this.mineData.info.units.unit3.is_default = this.defaultCheckbox(3)
      // 处理中间选择框
      this.middleCheck.a = this.mineData.info.is_max_price
      this.middleCheck.b = this.mineData.info.must_choose_date
      this.middleCheck.c = this.mineData.info.sell_price_change
      this.middleCheck.d = this.mineData.info.return_price_change

      // 处理表格头名称
      let checkList = []
      this.mineData.init.columns.forEach((item) => {
        checkList.push(item.label)
      })
      this.checkList = checkList
      this.columns = this.finallyColumns()
      // 处理表格内数据
      let tableData = []
      if (
        this.mineData.info.units.unit1.dealer_unit_id !== 'null' &&
        this.mineData.info.units.unit1 != 0
      ) {
        tableData.push(this.mineData.info.units.unit1)
      }
      if (
        this.mineData.info.units.unit3.dealer_unit_id !== 'null' &&
        this.mineData.info.units.unit3 != 0
      ) {
        tableData.push(this.mineData.info.units.unit3)
      }
      if (
        this.mineData.info.units.unit2.dealer_unit_id !== 'null' &&
        this.mineData.info.units.unit2 != 0
      ) {
        tableData.push(this.mineData.info.units.unit2)
      }
      this.tableData = tableData
    },
    mounted() {
      // console.log(this.basedata)
      if (this.tit == '添加商品') {
        this.mineData.info.units.unit1.is_default = 2
        console.log('middleCheck', this.middleCheck)
        this.middleCheck = {
          // a: this.alldata.info.is_max_price,
          a: 2,
          b: 0,
          c: 2,
          d: 2,
        }
        this.costshow = false
      } else {
        var day = JSON.parse(JSON.stringify(this.mineData.info.expire_day_text))
        if (day != '') {
          console.log(day, '年月天')
          day = day.replace(/[\u4e00-\u9fa5]/g, ',').split(',')
          console.log(day, '年月天')
          this.expire_day = day[0]
          this.expire_day1 = day[1]
          this.expire_day2 = day[2]
        }

        this.handlercost()
        this.costshow = true
      }
    },

    methods: {
      handlerexpireday() {
        this.mineData.info.expire_day_text =
          this.expire_day +
          '年' +
          this.expire_day1 +
          '月' +
          this.expire_day2 +
          '天'
        console.log(this.mineData.info.expire_day_text)
      },
      handlerfocus() {
        postAction('/baseAdmin/goods/init-unit', {}).then((res) => {
          console.log(res)
          this.mineData.init.unit.unit1.list = res.data.unit.unit1.list
          this.mineData.init.unit.unit2.list = res.data.unit.unit2.list
          this.mineData.init.unit.unit3.list = res.data.unit.unit3.list
        })
      },
      //设置单位
      setUnit() {
        let page = this.$router.resolve({
          path: 'units',
        })
        window.open(page.href, '_blank')
        // this.$router.push('units')
      },
      async handlercost() {
        await postAction('/baseAdmin/goods/price-log', {
          goods_id: this.alldata.info.id,
        }).then((res) => {
          console.log(res)
          this.costtable = res.data.list
        })
      },
      setBrand(val) {
        if (val != '0' && val !== '' && val !== undefined) {
          this.mineData.info.brand_id = val
        }
      },
      // 表格头
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.mineData.init.columns.filter(
              (item) => item.label === checkItem
            )[0]
          )
        })
        this.columns = _.sortBy(finallyArray, (item) => item.order)
        return _.sortBy(finallyArray, (item) => item.order)
      },

      // 处理通路的方法
      pathF() {
        if (this.mineData.info.sale_type == 1) {
          return ['线下', '网销']
        } else if (this.mineData.info.sale_type == 2) {
          return ['线下']
        } else if (this.mineData.info.sale_type == 3) {
          return ['网销']
        } else {
          return []
        }
      },
      // 处理默认选择框
      defaultCheckbox(unit) {
        if (unit == 1) {
          if (this.mineData.info.units.unit1.is_default == '') {
            return 1
          } else {
            return this.mineData.info.units.unit1.is_default
          }
        } else if (unit == 2) {
          if (this.mineData.info.units.unit2.is_default == '') {
            return 1
          } else {
            return this.mineData.info.units.unit2.is_default
          }
        } else if (unit == 3) {
          if (this.mineData.info.units.unit3.is_default == '') {
            return 1
          } else {
            return this.mineData.info.units.unit3.is_default
          }
        }
      },
      numtoval(unit) {
        if (unit == 2) {
          var a = this.mineData.init.unit.unit2.list.filter(
            (item) =>
              item.dealer_unit_id == this.mineData.init.unit.unit2.selected
          )
        } else if (unit == 1) {
          var a = this.mineData.init.unit.unit1.list.filter(
            (item) =>
              item.dealer_unit_id == this.mineData.init.unit.unit1.selected
          )
        } else if (unit == 3) {
          var a = this.mineData.init.unit.unit3.list.filter(
            (item) =>
              item.dealer_unit_id == this.mineData.init.unit.unit3.selected
          )
        }
        if (a.length !== 0) {
          return a[0].unit_name
        } else {
          return false
        }
      },
      xUnitChange(val) {
        console.log('小单位变化')
        console.log(val)

        if (this.showdialog) {
          if (val == 0 || val == '') {
            console.log('小单位变化 0')
            this.mineData.info.units.unit1.dealer_unit_id = 'null'
            this.mineData.info.units.unit2.dealer_unit_id = 'null'
            this.mineData.info.units.unit3.dealer_unit_id = 'null'
            this.mineData.init.unit.unit2.selected = ''
            this.mineData.init.unit.unit3.selected = ''
            this.tableData = []
            // this.tableData[0] = {
            // sell_price: '',
            // min_sell_price: '',
            // max_sell_price: '',
            // return_price: '',
            // market_price: '',
            // channelpriceid_1: '',
            // channelpriceid_4: '',
            // channelpriceid_5: '',
            // channelpriceid_6: '',
            // cost_price: '',
            // base_price: '',
            // }
            return false
          } else {
            let unita = this.mineData.init.unit.unit1.list.filter(
              (item) =>
                item.dealer_unit_id == this.mineData.init.unit.unit1.selected
            )[0].unit_name
            this.mineData.init.unit.unit1.selected_name = unita
            this.mineData.info.units.unit1.dealer_unit_id = val

            if (this.tableData.length == 0) {
              let ob = {
                sort: 1,
                unit_type: 3,
                return_price: '0.0000',
                cost_price: '0.0000',
                base_price: '0.0000',
                sell_price: '0.0000',
                market_price: '0.0000',
                min_sell_price: '0.0000',
                max_sell_price: '0.0000',
                channelpriceid_1: '0.0000',
                channelpriceid_4: '0.0000',
                channelpriceid_5: '0.0000',
                channelpriceid_6: '0.0000',
              }
              let obj = Object.assign(this.alldata.info.units.unit1, {
                sort: 1,
                unit_type: 1,
              })
              this.tableData.push(obj)
            }
            // 表格单位联动
            this.tableData[0].dealer_unit_id = val
            this.tableData[0].unit_name = unita
            // this.tableData.push({
            //   sort: 1,
            //   units: this.numtoval(1),
            //   salePrice: '',
            //   lowestPrice: '',
            //   highestPrice: '',
            //   returnPrice: '',
            //   suggestPrice: '',
            //   channel1: '',
            //   channel2: '',
            //   buyPrice: '',
            //   costPrice: '',
            // })
          }
        }
      },
      zUnitChange(val) {
        console.log('中单位变化')
        console.log(val)
        if (this.showdialog) {
          console.log('中单位', val)
          if (val == 0 || val == '') {
            console.log('中单位变化 0')
            this.mineData.info.units.unit2.goods_cv = ''
            this.mineData.info.units.unit2.dealer_unit_id = 'null'

            this.tableData.splice(2, 1)

            // this.tableData[2] = {
            //   sell_price: '',
            //   min_sell_price: '',
            //   max_sell_price: '',
            //   return_price: '',
            //   market_price: '',
            //   channelpriceid_1: '',
            //   channelpriceid_4: '',
            //   channelpriceid_5: '',
            //   channelpriceid_6: '',
            //   cost_price: '',
            //   base_price: '',
            // }
          } else {
            if (this.tableData.length == 2) {
              let ob = {
                sort: 3,
                unit_type: 2,
                return_price: '0.0000',
                cost_price: '0.0000',
                base_price: '0.0000',
                sell_price: '0.0000',
                market_price: '0.0000',
                min_sell_price: '0.0000',
                max_sell_price: '0.0000',
                channelpriceid_1: '0.0000',
                channelpriceid_4: '0.0000',
                channelpriceid_5: '0.0000',
                channelpriceid_6: '0.0000',
              }
              let obj = Object.assign(this.alldata.info.units.unit2, {
                sort: 3,
                unit_type: 2,
              })
              this.tableData.push(obj)
            }

            this.mineData.info.units.unit2.dealer_unit_id = val
            this.tableData[2].dealer_unit_id = val
            let unita = this.mineData.init.unit.unit2.list.filter(
              (item) =>
                item.dealer_unit_id == this.mineData.init.unit.unit2.selected
            )[0].unit_name
            this.tableData[2].dealer_unit_id = val
            this.tableData[2].unit_name = unita
            // this.tableData.push({
            //   sort: 3,
            //   units: this.numtoval(2),
            //   salePrice: '',
            //   lowestPrice: '',
            //   highestPrice: '',
            //   returnPrice: '',
            //   suggestPrice: '',
            //   channel1: '',
            //   channel2: '',
            //   buyPrice: '',
            //   costPrice: '',
            // })
          }
        }
      },
      dUnitChange(val) {
        console.log('大单位变化')
        console.log(val)
        if (this.showdialog) {
          console.log('大单位', val)
          if (val == 0 || val == '') {
            console.log('大单位变化 0')
            this.mineData.info.units.unit3.goods_cv = ''
            this.mineData.info.units.unit2.dealer_unit_id = 'null'
            this.mineData.info.units.unit3.dealer_unit_id = 'null'
            this.mineData.init.unit.unit2.selected = ''
            this.tableData.splice(1, 2)
            // this.tableData[1] = {
            //   sell_price: '',
            //   min_sell_price: '',
            //   max_sell_price: '',
            //   return_price: '',
            //   market_price: '',
            //   channelpriceid_1: '',
            //   channelpriceid_4: '',
            //   channelpriceid_5: '',
            //   channelpriceid_6: '',
            //   cost_price: '',
            //   base_price: '',
            // }
          } else {
            if (this.tableData.length == 1) {
              let ob = {
                sort: 2,
                unit_type: 3,
                return_price: '0.0000',
                cost_price: '0.0000',
                base_price: '0.0000',
                sell_price: '0.0000',
                market_price: '0.0000',
                min_sell_price: '0.0000',
                max_sell_price: '0.0000',
                channelpriceid_1: '0.0000',
                channelpriceid_4: '0.0000',
                channelpriceid_5: '0.0000',
                channelpriceid_6: '0.0000',
              }
              this.mineData.info.units.unit3.dealer_unit_id = val
              let obj = Object.assign(this.alldata.info.units.unit3, {
                sort: 2,
                unit_type: 3,
              })
              this.tableData.push(obj)
            }
            // 表格单位联动
            this.tableData[1].dealer_unit_id = val
            let unita = this.mineData.init.unit.unit3.list.filter(
              (item) =>
                item.dealer_unit_id == this.mineData.init.unit.unit3.selected
            )[0].unit_name
            this.tableData[1].unit_name = unita
          }
        }
      },

      // 重置表单
      resetForm(formName) {
        this.$refs[formName].resetFields()
      },
      checkChange(val, df = 1) {
        console.log(val)
        this.mineData.info.units.unit1.is_default = 1
        this.mineData.info.units.unit2.is_default = 1
        this.mineData.info.units.unit3.is_default = 1
        eval('this.mineData.info.units.unit' + df + '.is_default = val')
      },
      checkForm() {
        console.log('jiaancha')
        let a = this.$refs['form'].validate((res) => {
          if (res) {
            this.$refs['form2'].validate((res) => {
              if (res) {
                this.$refs['form3'].validate((res) => {
                  if (res) {
                    console.log('表单验证成功')
                    this.formgo = true
                    return 1
                  } else {
                    console.log('表单验证失败')
                    this.formgo = false
                    return false
                  }
                })
              } else {
                console.log('表单验证失败')
                this.formgo = false
                return false
              }
            })
          } else {
            console.log('表单验证失败')
            this.formgo = false
            return false
          }
        })
        return a
      },
      priceInput(val, row, index, column) {
        // console.log(val, row, index, column)
        if (this.middleCheck.a == 2) {
          let n = column.property
          let tableD = this.tableData
          let units = this.mineData.info.units
          let aUnit = Number(units.unit3.goods_cv)
          let bUnit = Number(units.unit2.goods_cv)
          let num = Number(val)
          console.log(num, aUnit, bUnit)
          if (num !== NaN && aUnit) {
            if (index == 0) {
              if (tableD[1]) {
                tableD[1][n] = (num * aUnit).toFixed(4)
              }
              if (tableD[2]) {
                tableD[2][n] = (num * bUnit).toFixed(4)
              }
            } else if (index == 1) {
              if (tableD[0]) {
                tableD[0][n] = (num / aUnit).toFixed(4)
              }
              if (tableD[2]) {
                tableD[2][n] = ((num / aUnit).toFixed(4) * bUnit).toFixed(4)
              }
            } else if (index == 2) {
              if (tableD[0]) {
                tableD[0][n] = (num / bUnit).toFixed(4)
              }
              if (tableD[1]) {
                tableD[1][n] = ((num / bUnit).toFixed(4) * aUnit).toFixed(4)
              }
            }
          }
        } else {
          return false
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    margin-top: 15px;
  }
  .form-item-wrap {
    display: flex;

    .form-item-left {
      width: 90%;
    }

    .form-item-right {
      display: flex;
      width: 583px;

      span {
        width: 110px;
        margin-right: 5px;
        text-align: right;
      }
    }
  }

  .checkBox {
    div {
      display: flex !important;
      justify-content: space-around;
    }
  }

  .el-select {
    width: 200px;
  }
  .el-form-item__error {
    display: block !important;
  }
  .el-form-item__error--inline {
    display: block !important;
  }
</style>
