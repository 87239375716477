var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "merge-header-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.downloadLoading, type: "primary" },
                  on: { click: _vm.handleDownload },
                },
                [_vm._v(" 导出 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "multipleTable",
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  return [_vm._v(" " + _vm._s($index + 1) + " ")]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "Main Information" } },
            [
              _c("el-table-column", {
                attrs: { label: "Title" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.title) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "Author" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("el-tag", [_vm._v(_vm._s(row.author))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "Readings" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.pageViews) + " ")]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { align: "center", label: "Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.datetime))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }