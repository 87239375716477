var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "600px",
        top: "15vh",
        "close-on-click-modal": false,
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-button-group",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEdit,
                  expression: "!isEdit",
                },
              ],
            },
            _vm._l(_vm.nav, function (item, index) {
              return _c(
                "el-button",
                {
                  key: index,
                  attrs: { type: _vm.form.type == index + 1 ? "primary" : "" },
                  on: {
                    click: function ($event) {
                      _vm.form.type = index + 1
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item) + " ")]
              )
            }),
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "margin-top": "15px" },
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "任务名称:", prop: "name" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm.form.type == 1
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { label: "每日拜访家数:", prop: "count" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "240px" },
                          attrs: { placeholder: "请输入数字", type: "number" },
                          model: {
                            value: _vm.form.count,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "count", _vm._n($$v))
                            },
                            expression: "form.count",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("家")])],
                        2
                      ),
                      _vm.form.type == 1
                        ? _c("div", [_vm._v("月度目标=每日拜访家数X当月天数")])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.type == 2
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { label: "每日建店家数:", prop: "count" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "240px" },
                          attrs: { placeholder: "请输入数字", type: "number" },
                          model: {
                            value: _vm.form.count,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "count", _vm._n($$v))
                            },
                            expression: "form.count",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("家")])],
                        2
                      ),
                      _vm.form.type == 2
                        ? _c("div", [_vm._v("月度目标=每日建店家数X当月天数")])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.type == 3
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { label: "月度目标:", prop: "money" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "240px" },
                          attrs: { type: "number", placeholder: "请输入数字" },
                          model: {
                            value: _vm.form.money,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "money", _vm._n($$v))
                            },
                            expression: "form.money",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元")])],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "任务描述:", prop: "note" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "note", $$v)
                      },
                      expression: "form.note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "优先级:", prop: "level" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.level,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "level", $$v)
                      },
                      expression: "form.level",
                    },
                  }),
                  _c("div", [_vm._v("优先级必须是整数")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.isEdit
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v(" 提交 ")]
              )
            : _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }