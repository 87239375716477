var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择仓库", clearable: "" },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.warehouse, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.depot_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "class_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: {
                        placeholder: "请选择品类",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.form.class_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "class_id", $$v)
                        },
                        expression: "form.class_id",
                      },
                    },
                    _vm._l(_vm.Category, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.class_name_txt, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "brand_id" } },
                [
                  _c("brand-select", {
                    ref: "brandSelect",
                    staticStyle: { width: "160px" },
                    attrs: { duoxuan: true },
                    on: { "brand-select-change": _vm.brandChange },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "class_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择货值", clearable: "" },
                      model: {
                        value: _vm.form.is_base,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_base", $$v)
                        },
                        expression: "form.is_base",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "成本价", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "销售价", value: "2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "keyword" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "商品名称，简拼" },
                    model: {
                      value: _vm.form.keyword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "keyword", $$v)
                      },
                      expression: "form.keyword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.form.is_zero,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_zero", $$v)
                        },
                        expression: "form.is_zero",
                      },
                    },
                    [_vm._v(" 显示零库存商品 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlersver },
                    },
                    [_vm._v("查 询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: { click: _vm.handlerReset },
                    },
                    [_vm._v("重 置")]
                  ),
                  _c("el-button", { on: { click: _vm.hanlderExport } }, [
                    _vm._v("导 出1"),
                  ]),
                  _c("el-button", { on: { click: _vm.hanlderExport2 } }, [
                    _vm._v("导 出2"),
                  ]),
                ],
                1
              ),
              _c("div", { staticStyle: { float: "right" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "货值合计:", prop: "" } },
                      [_vm._v(" " + _vm._s(_vm.total_amount_new) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            stripe: "",
            data: _vm.tableData,
            fit: true,
            "row-key": "id",
          },
          on: {
            "selection-change": _vm.selectChange,
            "sort-change": _vm.sortChange,
          },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.colemd, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "selection",
              "reserve-selection": "",
              width: "50",
            },
          }),
          _vm._l(_vm.finallyColumns, function (list, index) {
            return _c(
              "el-table-column",
              {
                key: index,
                attrs: {
                  label: list.label,
                  align: list.align,
                  prop: list.prop,
                  width: list.width,
                  sortable: list.sortable ? "custom" : false,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          list.label == "占用库存"
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDepotDetail(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row[list.prop]) + " ")]
                                ),
                              ])
                            : list.label == "实时库存"
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                      cursor: "pointer",
                                      "font-weight": "bold",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleactuattime(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row[list.prop]) + " ")]
                                ),
                              ])
                            : _c("div", [_vm._v(_vm._s(row[list.prop]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c("template", { slot: "header" }, [
                  list.label == "可用库存"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom",
                                title: "说明",
                                width: "200",
                                trigger: "click",
                                content:
                                  "可用库存主要为避免下单量超出库存余量，\n可用库存等于实际库存减占用库存，\n数值仅供参考，下访销单会先记录占用库存，\n调拨审核后会释放占用库存。",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(" " + _vm._s(list.label) + " "),
                                  _c("i", { staticClass: "el-icon-warning" }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : list.label == "货值"
                    ? _c("div", [
                        _vm.form.is_base == 1
                          ? _c("div", [_vm._v("成本价货值")])
                          : _c("div", [_vm._v("销售价货值")]),
                      ])
                    : _vm._e(),
                ]),
              ],
              2
            )
          }),
        ],
        2
      ),
      _c(
        "el-row",
        { staticClass: "row-bg ww", attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "div",
            { staticStyle: { "align-item": "center" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "5px" },
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.hanldeClearDepot },
                },
                [_vm._v(" 修正占用库存 ")]
              ),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "page",
            staticStyle: { width: "70%" },
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("check", { ref: "check" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }