<template>
  <el-table stripe class="tab">
    <el-table-column
      v-for="(item, index) in tabColumn_"
      :key="index"
      :label="item.label"
      :align="item.align"
      width=""
    ></el-table-column>
  </el-table>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      sumText: {
        type: String,
        default: () => '总计',
      },
      textWidth: {
        type: String,
        default: () => '50px',
      },
      tabColumn: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {}
    },
    computed: {
      tabColumn_() {
        if (this.tabColumn.length == 0) {
          console.log('传入表头数据错误')
          return false
        } else {
          let arr = []
          let data = JSON.parse(JSON.stringify(this.tabColumn))
          data.unshift({
            label: this.sumText,
            align: 'left',
            width: this.textWidth,
          })
          data.forEach((item, idx) => {
            arr.push(Object.assign(item, { align: 'center' }))
          })
          return arr
        }
      },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss">
  .tab {
    .el-table__body-wrapper {
      display: none !important;
    }
  }
</style>
