<template>
  <div class="orderTest-container">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="keyword">
          <el-input
            v-model="form.cust_keywords"
            clearable
            placeholder="客户名称、简拼、电话"
          ></el-input>
        </el-form-item>
        <el-form-item prop="depart_id">
          <el-select
            v-model="form.funds_type"
            placeholder="销售费用科目"
            multiple
            collapse-tags
            clearable
            style="width: 180px"
          >
            <el-option
              v-for="(item, index) in department"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="user_id">
          <el-select
            v-model="form.supp_id"
            placeholder="供应商"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in supplier"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="bill_status">
          <el-select
            v-model="form.user_id"
            placeholder="经办人"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in areaList"
              :key="index"
              :label="item.user_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="print_status">
          <el-select
            v-model="form.settle_type"
            placeholder="结算方式"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Typesof"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="cust_area_id">
          <el-select
            v-model="form.status"
            placeholder="状态"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Customertype"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="keyword">
          <el-input
            v-model="form.remark"
            clearable
            placeholder="备注"
          ></el-input>
        </el-form-item>
        <el-form-item prop="Time">
          <el-date-picker
            v-model="form.Time"
            clearable
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlersver">查 询</el-button>
          <el-button type="primary" @click="handleradd">添加销售费用</el-button>
          <el-button @click="handleImport">导 入</el-button>

          <el-button @click="handleExport">导 出</el-button>
          <el-button type="warning" @click="handlerReset">
            费用科目设置
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <import-tpl ref="importTpl" @refresh="handlersver"></import-tpl>
    <el-row>
      <el-table
        ref="table"
        stripe
        :data="tableData"
        show-summary
        :summary-method="summaryFunction"
      >
        <el-table-column
          label="序号"
          align="center"
          type="index"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in Columns"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template #default="{ row }">
            <div v-if="list.label == '原始单号'">
              <span
                v-if="row.order_ori_type == 1"
                style="text-decoration: underline; cursor: pointer"
                @click="handleCheckOrder(row)"
              >
                {{ row[list.prop] }}
              </span>
              <span v-else>{{ row[list.prop] }}</span>
            </div>
            <div v-else>{{ row[list.prop] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          fixed="right"
          width="160px"
        >
          <template #default="{ row }">
            <el-button type="text" @click="handlerlink(row)">查看</el-button>
            <el-button
              v-show="row.status != 1"
              type="text"
              @click="handlerexamine(row)"
            >
              审核
            </el-button>
            <el-popconfirm title="确定要作废吗？" @confirm="handlerstorp(row)">
              <el-button slot="reference" style="margin-left: 10px" type="text">
                作废
              </el-button>
            </el-popconfirm>
            <el-button
              style="margin-left: 10px"
              type="text"
              @click="handleprint(row)"
            >
              打印
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <add-sale
      ref="add"
      :department="department"
      :supplier="supplier"
      :area-list="areaList"
      @getlist="handlerlist"
    />
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="FY"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
  </div>
</template>

<script>
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { getSelect } from '@/api/saleOrder'
  import { downloadFile, postAction } from '@/api/Employee'
  import AddSale from './components/AddSale'
  import importTpl from '@/views/index/components/importTpl.vue'

  export default {
    name: 'Index',
    components: { AddSale, RuilangDisplayDialog, importTpl },
    data() {
      return {
        data_id: 0,
        form: {
          supp_id: '', //供应商ID
          user_id: '', //经办人ID
          funds_type: '', //费用科目id，多个用逗号隔开
          status: '', //状态，0未审核，1已审核，2已作废
          Time: [],
          start_time: '', //开始时间
          end_time: '', //结束时间
          cust_keywords: '', //店铺名称 简拼
          pageNo: 1,
          pageSize: 10,
          remark: '', //客户ID
          settle_type: '', //结算方式 1=账期、2=现结、 0和空=全部
        },
        total: 0,
        Typesof: [
          {
            id: '0',
            name: '全部',
          },
          {
            id: '1',
            name: '账期',
          },
          {
            id: '2',
            name: '现结',
          },
        ],
        //1未收款2部分结款3已结清5坏账
        Customertype: [
          {
            id: '0',
            name: '未审核',
          },
          {
            id: '1',
            name: '已审核',
          },
          {
            id: '2',
            name: '已作废',
          },
        ],
        department: [],
        supplier: [],
        areaList: [],
        url: {
          info: '/cashAdmin/funds/sale-funds-detail', //详情
          list: '/cashAdmin/funds/sale-funds-list',
          examine: '/cashAdmin/funds/sale-funds-check', //审核
          stop: '/cashAdmin/funds/sale-funds-scrap', //作废
        },
        tableData: [],
        Columns: [
          {
            order: 1,
            label: '单号',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 2,
            label: '客户名称',
            align: 'center',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '费用科目',
            align: 'center',
            prop: 'funds_type',
            width: '',
          },
          {
            order: 4,
            label: '金额',
            align: 'center',
            prop: 'money',
            width: '',
          },
          {
            order: 5,
            label: '结算方式',
            align: 'center',
            prop: 'settle_type',
            width: '',
          },
          {
            order: 5.5,
            label: '未结算金额',
            align: 'center',
            prop: 'left_money',
            width: '',
          },
          {
            order: 6,
            label: '供应商',
            align: 'center',
            prop: 'supp_name',
            width: '',
          },

          {
            order: 7,
            label: '状态',
            align: 'center',
            prop: 'status_text',
            width: '',
          },
          {
            order: 7.1,
            label: '备注',
            align: 'center',
            prop: 'remark',
            width: '',
          },
          {
            order: 7.2,
            label: '创建时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
          {
            order: 8,
            label: '审核时间',
            align: 'center',
            prop: 'check_at',
            width: '',
          },
          {
            order: 8.2,
            label: '经办人',
            align: 'center',
            prop: 'user_name',
            width: '',
          },
        ],
        sum: [],
        in_total_amount: null,
        out_total_amount: null,
      }
    },
    watch: {
      tableData(val) {
        this.$refs.table.clearSelection()
      },
      'form.Time'(val) {
        console.log(val)
        try {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
          console.log(this.form.start_time)
        } catch (error) {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      'form.goodsname'(val) {
        if (val == '') {
          this.form.goods_id = ''
        }
      },
    },
    mounted() {
      this.handlerlist()
      this.handlerselect()
    },
    methods: {
      handleImport() {
        this.$refs.importTpl.data = {
          type: 17,
          files: '',
          // check_id: this.form.check_id,
        }
        this.$refs.importTpl.title = '批量导入费用'
        this.$refs.importTpl.showDialog = true
      },
      handleradd() {
        this.$refs.add.showadd = true
      },
      handlerlink(row) {
        this.$refs.add.handlerinfo(row)
        this.$refs.add.type = 3
        this.$refs.add.showadd = true
        this.$refs.add.title = '查看销售费用'
      },

      handlerexamine(row) {
        this.$refs.add.handlerinfo(row)
        this.$refs.add.type = 2
        this.$refs.add.showadd = true
        this.$refs.add.title = '审核销售费用'
      },
      //下拉选型
      async handlerselect() {
        //费用科目
        const fundslist = await postAction('cashAdmin/subject/funds-list', {
          is_select: 1,
          app_display: 1,
        })
        this.department = fundslist.data
        //供应商
        const supplist = await postAction('purchaseAdmin/base/supp-list', {})
        this.supplier = supplist.data.rows
        //经办人
        const operatorlist = await postAction(
          'purchaseAdmin/base/operator-list',
          {}
        )
        this.areaList = operatorlist.data.rows
      },

      handlerstorp(row) {
        postAction(this.url.stop, { id: row.id }).then((res) => {
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.handlerlist()
        })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlersver() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        let sums = []
        sums[0] = '总计'
        columns.forEach((list, index) => {
          if (list.label == '金额') {
            sums[index] = this.in_total_amount
          }
          if (list.label == '未结算金额') {
            sums[index] = this.out_total_amount
          }
        })
        return sums
      },
      handlerReset() {
        this.$router.push({ path: 'addcost' })
      },
      handleprint(row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      handlerlist() {
        var data = JSON.parse(JSON.stringify(this.form))
        delete data.Time
        postAction(this.url.list, data)
          .then((res) => {
            console.log(res, 'tablelie')
            this.tableData = res.data.rows
            this.in_total_amount = res.data.in_total_amount
            this.out_total_amount = res.data.out_total_amount
            this.total = Number(res.totalCount)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleCheckOrder(row) {
        this.$refs.saleOrder.id = row.order_ori_id
        this.$refs.saleOrder.isshowDialog = true
      },
      // 导出
      handleExport() {
        downloadFile(
          'cashAdmin/funds/sale-funds-list-export',
          '销售费用.xlsx',
          this.form
        )
      },
    },
  }
</script>

<style scoped></style>
