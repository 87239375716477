<!--
 * @Author: 候怀烨
 * @Date: 2020-11-20 18:51:21
 * @LastEditTime: 2020-11-20 18:53:10
 * @LastEditors: Please set LastEditors
 * @Description: 数据权限公共组件
 * @FilePath: \sd-vue-admin\src\components\Authority\index.vue
-->

<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'Authority',
  }
</script>

<style></style>
