var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "下单时间" },
                  model: {
                    value: _vm.form.time_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "time_type", $$v)
                    },
                    expression: "form.time_type",
                  },
                },
                _vm._l(_vm.timeTypeSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "部门" },
                  model: {
                    value: _vm.form.depart_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depart_id", $$v)
                    },
                    expression: "form.depart_id",
                  },
                },
                _vm._l(_vm.departmentSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.text },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [_c("staff-select", { on: { "change-staff": _vm.changeStaff } })],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("client-search", {
                attrs: { w: 330 },
                on: { "select-val-alldata": _vm.clientChange },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [_c("class-select", { on: { "change-class": _vm.changeClass } })],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [_c("brand-select", { on: { "change-brand": _vm.changeBrand } })],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("goods-search", {
                attrs: { "is-table": false },
                on: { "select-goods-all": _vm.changeGoods },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "结算方式" },
                  model: {
                    value: _vm.form.account_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "account_id", $$v)
                    },
                    expression: "form.account_id",
                  },
                },
                _vm._l(_vm.payTypeSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "是否结清",
                    "popper-class": "select-idx",
                  },
                  model: {
                    value: _vm.form.pay_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "pay_status", $$v)
                    },
                    expression: "form.pay_status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "未结清", value: 0 } }),
                  _c("el-option", { attrs: { label: "已结清", value: 1 } }),
                  _c("el-option", { attrs: { label: "坏账", value: 2 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "订单备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "自定义客户组" },
                  model: {
                    value: _vm.form.cust_group_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cust_group_id", $$v)
                    },
                    expression: "form.cust_group_id",
                  },
                },
                _vm._l(_vm.customClientSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "自定义商品组" },
                  model: {
                    value: _vm.form.goods_group_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "goods_group_id", $$v)
                    },
                    expression: "form.goods_group_id",
                  },
                },
                _vm._l(_vm.customGoodsSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.time_type == "create_at"
            ? _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.form.has_yu,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "has_yu", $$v)
                        },
                        expression: "form.has_yu",
                      },
                    },
                    [_vm._v("包含未到货预订单")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.time_type == "create_at"
            ? _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1 },
                      model: {
                        value: _vm.form.easy_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "easy_code", $$v)
                        },
                        expression: "form.easy_code",
                      },
                    },
                    [_vm._v("显示商品助记码")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleQueryFlow(1)
                    },
                  },
                },
                [_vm._v(" 查询流水 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.handleQueryFlow(2)
                    },
                  },
                },
                [_vm._v(" 查询汇总 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleExport(1)
                    },
                  },
                },
                [_vm._v("导出流水")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleExport(2)
                    },
                  },
                },
                [_vm._v("导出汇总")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showTable
        ? _c(
            "div",
            { staticClass: "table" },
            [
              _vm.goodsTable.length > 0
                ? _c(
                    "el-card",
                    _vm._l(_vm.goodsTable, function (table, index) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          key: index,
                        },
                        [
                          _vm._l(table.cust_list, function (t, idx) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading",
                                  },
                                ],
                                key: idx,
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      stripe: "",
                                      data: t.list,
                                      "span-method": function (data) {
                                        return _vm.objectTplSpanMethod(
                                          data,
                                          t.list
                                        )
                                      },
                                      "show-header": idx == 0,
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop:
                                          _vm.type == 1
                                            ? "cust_name"
                                            : "brand_name",
                                        label: t.goods_btype_text,
                                        width: "",
                                        align: "center",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "goods_name",
                                        label: "商品名称",
                                        width: "220",
                                        align: "center",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "sum_quantity_text",
                                        label: "总销量",
                                        width: "",
                                        align: "center",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "goods_type_text",
                                        label: "类型",
                                        width: "",
                                        align: "center",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "goods_price",
                                        label: "单价",
                                        width: "",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    class: row.is_red
                                                      ? "fontcolor"
                                                      : "",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          row.goods_price
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "quantity",
                                        label: "数量",
                                        width: "",
                                        align: "center",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "goods_money",
                                        label: "金额",
                                        width: "",
                                        align: "center",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "goods_chengben",
                                        label: "成本 ",
                                        width: "",
                                        align: "center",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                t.goods_btype_text &&
                                t.goods_btype_text.indexOf("退货") >= 0
                                  ? _c("div", { staticClass: "total" }, [
                                      _c("div", [
                                        _vm._v(
                                          "退货金额: " + _vm._s(t.goods_money)
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "毛利小结: " + _vm._s(t.goods_maoli)
                                        ),
                                      ]),
                                    ])
                                  : t.goods_btype_text &&
                                    t.goods_btype_text.indexOf("陈列兑付") >= 0
                                  ? _c("div", { staticClass: "total" }, [
                                      _c("div", [
                                        _vm._v(
                                          "兑付商品货值: " +
                                            _vm._s(t.goods_money)
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "成本合计: " +
                                            _vm._s(t.goods_chengben)
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "兑付现金: " + _vm._s(t.cash_amount)
                                        ),
                                      ]),
                                    ])
                                  : _c("div", { staticClass: "total" }, [
                                      _c("div", [
                                        _vm._v(
                                          "销售小结: " + _vm._s(t.goods_money)
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "赠送货值: " + _vm._s(t.give_money)
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "毛利小结: " + _vm._s(t.goods_maoli)
                                        ),
                                      ]),
                                    ]),
                              ],
                              1
                            )
                          }),
                          _c("div", { staticClass: "total" }, [
                            _c("div", [
                              _vm._v(
                                "金额合计：" + _vm._s(table.goods_money_sum)
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "毛利合计：" + _vm._s(table.goods_maoli_sum)
                              ),
                            ]),
                          ]),
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.listSheqian.list && _vm.listSheqian.list.length > 0
                ? _c(
                    "el-card",
                    [
                      _c("base-table", {
                        attrs: {
                          list: _vm.listSheqian.list,
                          columns: _vm.columnsSheqian,
                        },
                      }),
                      _c("div", { staticClass: "total" }, [
                        _c("div", [
                          _vm._v(
                            "应收总额: " +
                              _vm._s(_vm.listSheqian.sum.receiv_money)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "已收款: " + _vm._s(_vm.listSheqian.sum.pay_amount)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "已清欠: " + _vm._s(_vm.listSheqian.sum.qing_money)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "欠款合计: " +
                              _vm._s(_vm.listSheqian.sum.left_amount)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQingqian.list && _vm.listQingqian.list.length > 0
                ? _c(
                    "el-card",
                    [
                      _c("base-table", {
                        attrs: {
                          list: _vm.listQingqian.list,
                          columns: _vm.columnsQingqian,
                        },
                      }),
                      _c("div", { staticClass: "total" }, [
                        _c("div", [
                          _vm._v(
                            "清欠收款合计: " +
                              _vm._s(_vm.listQingqian.sum.bill_amount)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listYucunkuan.list && _vm.listYucunkuan.list.length > 0
                ? _c(
                    "el-card",
                    [
                      _c("base-table", {
                        attrs: {
                          list: _vm.listYucunkuan.list,
                          columns: _vm.columnsYucunkuan,
                        },
                      }),
                      _c("div", { staticClass: "total" }, [
                        _c("div", [
                          _vm._v(
                            "签约合计: " +
                              _vm._s(_vm.listYucunkuan.sum.total_amount)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "赠送合计: " +
                              _vm._s(_vm.listYucunkuan.sum.gift_amount)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "已收款合计: " +
                              _vm._s(_vm.listYucunkuan.sum.receive_amount)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "待收款合计: " +
                              _vm._s(_vm.listYucunkuan.sum.left_amount)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "退款: " +
                              _vm._s(_vm.listYucunkuan.sum.return_amount)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listYucunhuo.list && _vm.listYucunhuo.list.length > 0
                ? _c(
                    "el-card",
                    [
                      _c("base-table", {
                        attrs: {
                          list: _vm.listYucunhuo.list,
                          columns: _vm.columnsYucunhuo,
                        },
                      }),
                      _c("div", { staticClass: "total" }, [
                        _c("div", [
                          _vm._v(
                            "签约合计: " +
                              _vm._s(_vm.listYucunhuo.sum.total_amount)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "已收款合计: " +
                              _vm._s(_vm.listYucunhuo.sum.receive_amount)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "待收款合计: " +
                              _vm._s(_vm.listYucunhuo.sum.left_amount)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "退款: " +
                              _vm._s(_vm.listYucunhuo.sum.return_amount)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listChenlie.list && _vm.listChenlie.list.length > 0
                ? _c(
                    "el-card",
                    [
                      _c("base-table", {
                        attrs: {
                          list: _vm.listChenlie.list,
                          columns: _vm.columnsChenlie,
                        },
                      }),
                      _c("div", { staticClass: "total" }, [
                        _c("div", [
                          _vm._v(
                            "承兑合计: " +
                              _vm._s(_vm.listChenlie.sum.total_amount)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listEnd.list && _vm.listEnd.list.length > 0
                ? _c(
                    "el-card",
                    [
                      _c("base-table", {
                        attrs: {
                          list: _vm.listEnd.list,
                          columns: _vm.columnsEnd,
                        },
                      }),
                      _c("div", { staticClass: "total" }, [
                        _c("div", [
                          _vm._v(
                            "金额合计: " + _vm._s(_vm.listEnd.sum.total_amount)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "毛利合计: " +
                              _vm._s(_vm.listEnd.sum.gross_profit_amount)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.allTotal.receive_amount
        ? _c("div", { staticClass: "total" }, [
            _c("div", [
              _vm._v("销售实收:" + _vm._s(_vm.allTotal.receive_amount)),
            ]),
            _c("div", [
              _vm._v("退货金额:" + _vm._s(_vm.allTotal.refund_amount)),
            ]),
            _c("div", [
              _vm._v("预存款收款:" + _vm._s(_vm.allTotal.pre_receive_amount)),
            ]),
            _c("div", [
              _vm._v("预存款退款:" + _vm._s(_vm.allTotal.pre_return_amount)),
            ]),
            _c("div", [
              _vm._v("预存货收款:" + _vm._s(_vm.allTotal.goods_receive_amount)),
            ]),
            _c("div", [
              _vm._v("预存货退款:" + _vm._s(_vm.allTotal.goods_return_amount)),
            ]),
            _c("div", [_vm._v("清欠收款:" + _vm._s(_vm.allTotal.qing_money))]),
            _c("div", [
              _vm._v("陈列兑付现金:" + _vm._s(_vm.allTotal.cash_amount)),
            ]),
            _c("div", [_vm._v("收款总计:" + _vm._s(_vm.allTotal.shou_sum))]),
            _c("div", [_vm._v("毛利总计:" + _vm._s(_vm.allTotal.maoli_sum))]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }