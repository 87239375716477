<template>
  <!-- NAME[epic=动销] 创建预存货活动 -->
  <div class="orderTest-container">
    <el-form :model="queryForm" inline>
      <el-form-item>
        <el-date-picker
          v-model="queryForm.start_time"
          :popper-class="'select-idx'"
          value-format="yyyy-MM-dd"
          placeholder="开始时间"
          style="width: 130px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryForm.end_time"
          value-format="yyyy-MM-dd"
          :popper-class="'select-idx'"
          placeholder="结束时间"
          style="width: 130px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryForm.name"
          placeholder="请输入活动名称"
          :popper-class="'select-idx'"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryForm.activity_status"
          placeholder="状态"
          style="width: 140px"
          :popper-class="'select-idx'"
        >
          <!-- ：1未开始，2进行中，3已结束，4已停止 -->
          <el-option
            v-for="(item, index) in statusList"
            :key="index"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleAdd">创建新活动</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in finallyColunms"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template v-if="item.label === '排序'" #default="{ row }">
          <el-input
            v-model.trim="row.sort"
            @change="blur_(row.id, row.sort)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheckRow($index, row)"
          >
            查看
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleEditRow($index, row)"
          >
            修改
          </el-button>
          <el-button
            v-if="row.status != 0"
            type="text"
            @click.native.prevent="handleCopyRow($index, row)"
          >
            复制
          </el-button>
          <el-button
            v-if="row.status_text == '进行中'"
            type="text"
            @click.native.prevent="handleStopRow($index, row)"
          >
            停止
          </el-button>
          <!-- <el-button
            type="text"
            v-else
            @click.native.prevent="handleActiveRow($index, row)"
          >
            启用
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="totalCount"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" @refresh="fetchData"></edit>
    <create ref="create" @refresh="fetchData"></create>
  </div>
</template>
<script>
  import _ from 'lodash'
  import {
    getListData,
    listSort,
    copyRow,
    stopRow,
  } from '@/api/createPreInventory'
  import Edit from './edit'
  import Create from './create'
  export default {
    name: 'CreatePreInventory',
    components: {
      Edit,
      Create,
    },
    data() {
      return {
        loading: true,
        totalCount: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        queryForm: {
          pageSize: 10, //pageSize: 10,
          pageNo: 1, //pageNo: 1,
          start_time: '',
          end_time: '',
          name: '',
          sort: 'sort',
          order: 'asc',
          activity_status: '',
        },
        // ：1未开始，2进行中，3已结束，4已停止
        statusList: [
          {
            label: '未开始',
            value: '1',
          },
          {
            label: '进行中',
            value: '2',
          },
          {
            label: '已结束',
            value: '3',
          },
          {
            label: '已停止',
            value: '4',
          },
        ],
        list: [],
        checkList: [
          '排序',
          '活动ID',
          '活动名称',
          '活动说明',
          '报名开始时间',
          '报名结束时间',
          '参与终端数量',
          '签单金额',
          '收款金额',
          '状态',
        ],

        columns: [
          {
            order: 1,
            prop: '',
            label: '排序',
            width: '70px',
          },
          {
            order: 2,
            prop: 'id',
            label: '活动ID',
            width: '120px',
          },
          {
            order: 3,
            prop: 'name',
            label: '活动名称',
            width: '120px',
          },
          {
            order: 4,
            prop: 'desc',
            label: '活动说明',
            width: '120px',
          },
          {
            order: 5,
            prop: 'join_start_at',
            label: '报名开始时间',
            width: '120px',
          },
          {
            order: 6,
            prop: 'join_end_at',
            label: '报名结束时间',
            width: '120px',
          },
          {
            order: 7,
            prop: 'count',
            label: '参与终端数量',
            width: '120px',
          },
          {
            order: 8,
            prop: 'total_amount',
            label: '签单金额',
            width: '120px',
          },
          {
            order: 9,
            prop: 'receive_amount',
            label: '收款金额',
            width: '120px',
          },
          {
            order: 10,
            prop: 'status_text',
            label: '状态',
            width: '120px',
          },
        ],
      }
    },
    computed: {
      finallyColunms() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await getListData(this.queryForm)
        if (code == 200) {
          console.log(data)
          this.list = data
          this.totalCount = totalCount
        }
        this.loading = false
      },
      handleQuery() {
        this.queryForm.pageSize = 10
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      handleAdd() {
        this.$refs.create.showDialog = true
      },
      // 排序输入框失去焦点 排序
      blur_(id, val) {
        listSort({ id: id, sort: val }).then((res) => {
          if (res.code == 200) {
            this.fetchData()
            this.$message.success('排序成功')
          }
        })
      },
      // 表格操作
      handleCheckRow(index, row) {
        this.$refs.edit.isCheck = true
        this.$refs.edit.id = row.id
        this.$refs.edit.title = '查看活动'

        this.$refs.edit.showDialog = true
      },
      handleEditRow(index, row) {
        this.$refs.edit.isCheck = false
        this.$refs.edit.id = row.id

        this.$refs.edit.title = '修改活动'

        this.$refs.edit.showDialog = true
      },

      handleCopyRow(index, row) {
        copyRow({ id: row.id }).then((res) => {
          if (res.code == 200) {
            this.fetchData()
            this.$message.success('复制成功')
          }
        })
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [8, 9, 10]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                if (idx == 8) {
                  sums[n] = a
                } else {
                  sums[n] = a.toFixed(2) + ' 元'
                }
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      handleStopRow(index, row) {
        stopRow({ info_id: row.id }).then((res) => {
          if (res.code == 200) {
            this.$message.success('停止成功')
            this.fetchData()
          }
        })
      },
      // 分页
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
