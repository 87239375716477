<template>
  <div class="checkOrder">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="订单详情"
      top="5vh"
      :visible.sync="isshowDialog"
      fullscreen
      width="1160px"
      @close="close"
    >
      <div v-if="showForm" v-loading="loading" class="wrapper2">
        <el-alert
          :closable="false"
          :title="titleTip"
          type="success"
          style="width: 86%; margin-top: -70px; margin-left: 80px"
        >
          <span>{{ form.bill_type_text }}</span>
        </el-alert>
        <!-- <div class="orderStatus">{{ orderStatus }}</div> -->
        <order-status :status="orderStatusStr"></order-status>
        <el-form ref="form" :model="form" label-width="80px" :inline="true">
          <el-form-item label="购货方" prop="cust_name">
            <el-input v-model.trim="form.cust_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="boss">
            <el-input v-model.trim="form.boss" disabled></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address" class="dz">
            <el-input
              v-model.trim="form.address"
              disabled
              style="width: 380px"
            ></el-input>
          </el-form-item>

          <el-form-item label="出货仓库" prop="chck">
            <el-input v-model.trim="chck" disabled></el-input>
          </el-form-item>

          <el-form-item label="送货人" prop="deliver_name">
            <el-input v-model.trim="form.deliver_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="业务员" prop="staff_name" v-if="allSelectList.ckzx_falg==1">
            <el-select
              v-model="form.staff_id"
              clearable
              style="width: 200px"
              placeholder=""
              :disabled="form.ckzx_falg != '1' && edit"
            >
              <el-option
                v-for="(i, idx) in staffList"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
            <!--            <el-input v-model.trim="form.staff_name" disabled></el-input>-->
          </el-form-item>

          <el-form-item label="调拨单" prop="allot_code">
            <el-input v-model.trim="form.allot_code" disabled></el-input>
          </el-form-item>
          <el-form-item label="派单" prop="delivery_time">
            <el-input v-model.trim="form.delivery_time" disabled></el-input>
          </el-form-item>
          <el-form-item label="到货" prop="arrive_time">
            <el-input v-model.trim="form.arrive_time" disabled></el-input>
          </el-form-item>
        </el-form>
        <!-- <div class="setFilter">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </div> -->
        <el-table
          ref="middleTable"
          stripe
          :data="list"
          height="360px"
          :row-class-name="tableRowClassName"
          @selection-change="setSelectRows"
          @cell-click="handlercellclick"
        >
          <!-- 序号 -->
          <el-table-column align="center" type="index" width="50">
            <template slot="header">
              <!-- <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="index"
                    :label="item.label"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover> -->
            </template>
          </el-table-column>
          <!-- 序号 -->
          <!-- <el-table-column prop="" align="center" label="" min-width="50px">
            <template #default="{ $index }">
              <span>{{ $index + 1 }}</span>
            </template>
          </el-table-column> -->

          <!-- 类型 -->
          <el-table-column
            prop="type"
            align="center"
            label="类型"
            min-width="120px"
          >
            <template #default="{ row }">
              <!-- <el-input v-model="row.type" style="width: 100px" /> -->
              <!-- 不能修改 4 8 12 14 15 16-->
              <div
                v-if="row.index === tabClickIndex && tabClickLabel === '类型'"
              >
                <el-select
                  v-model="row.goods_type"
                  placeholder="选择类型"
                  style="width: 100px"
                  :disabled="
                    (row.goods_type != 1 &&
                      row.goods_type != 2 &&
                      row.goods_type != 3 &&
                      row.goods_type != 18 &&
                      row.goods_type != 19 &&
                      row.goods_type != 10 &&
                      row.goods_type != 11 &&
                      row.goods_type != 6 &&
                      row.goods_type != 9 &&
                      row.goods_type != 17) ||
                    row.activity_type != 0 ||
                    (row.goods_type == 5 && row.activity_id != 0)
                  "
                  @change="utilsF(), handleTypeChange($event, row)"
                >
                  <el-option
                    v-for="item_ in row.arr_goods_type"
                    :key="item_.id"
                    :label="item_.name"
                    :value="item_.id"
                    :disabled="item_.disabled"
                  ></el-option>
                </el-select>
              </div>
              <div v-else>{{ row.goods_type_text }}</div>
              <!-- <span v-else>{{ row.type }}</span> -->
            </template>
          </el-table-column>
          <!-- 商品名称 -->
          <el-table-column
            prop="goods_name"
            align="center"
            label="商品名称"
            min-width="260px"
          >
            <template #default="{ $index, row }">
              <!-- row.activity_type == 0 && -->
              <template
                v-if="
                  (row.goods_type == 1 ||
                    row.goods_type == 2 ||
                    row.goods_type == 10 ||
                    row.goods_type == 11 ||
                    row.goods_type == 3 ||
                    row.goods_type == 6 ||
                    row.goods_type == 9 ||
                    row.goods_type == 17 ||
                    (row.goods_type == 5 && row.activity_type != 0)) &&
                  row.index === tabClickIndex &&
                  tabClickLabel === '商品名称'
                "
              >
                <goods-search
                  :f-key="row.goods_name_print"
                  :kouwei="row.child"
                  :rowf="row"
                  :cust-id="form.cust_id"
                  @add-rows="addRows"
                  @change-kouwei="changeKouwei($event, row)"
                  @select-goods-all="selectGoods($event, row, $index)"
                ></goods-search>
              </template>
              <span v-else>{{ row.goods_name_print }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="specs"
            align="center"
            label="规格"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="unit_code"
            align="center"
            label="条码"
            min-width="110px"
          >
            <template #default="{ row }">
              {{ currUnitCode(row) }}
            </template>
          </el-table-column>
          <!-- 单位 -->
          <el-table-column
            prop="type"
            align="center"
            label="单位"
            width="140px"
          >
            <template #default="{ row }">
              <!-- row.activity_type == 0 && -->
              <template
                v-if="
                  (row.goods_type == 1 ||
                    row.goods_type == 2 ||
                    row.goods_type == 10 ||
                    row.goods_type == 18 ||
                    row.goods_type == 19 ||
                    row.goods_type == 11 ||
                    row.goods_type == 9 ||
                    row.goods_type == 3 ||
                    row.goods_type == 6 ||
                    row.goods_type == 17 ||
                    (row.goods_type == 5 && row.activity_type != 0)) &&
                  row.index === tabClickIndex &&
                  tabClickLabel === '单位'
                "
              >
                <!-- <el-input v-model="row.type" style="width: 100px" /> -->
                <el-select
                  v-model="row.unit_id"
                  placeholder="选择单位"
                  style="width: 70px"
                  :disabled="row.goods_name == ''"
                  @change="unitChange($event, row), utilsF()"
                >
                  <el-option
                    v-for="item in row.arr_unit"
                    :key="item.id"
                    :label="item.unit_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
              <span v-else>{{ row.unit_name }}</span>
            </template>
            <!-- <template #default="{ row }">
            {{ row.units }}
          </template> -->
          </el-table-column>
          <!-- 单价 -->
          <el-table-column
            prop="goods_price"
            align="center"
            label="单价"
            min-width="110px"
          >
            <template #default="{ row, $index }">
              <!-- row.activity_type == 0 && -->
              <template
                v-if="
                  (row.goods_type == 1 ||
                    row.goods_type == 2 ||
                    row.goods_type == 10 ||
                    row.goods_type == 11 ||
                    row.goods_type == 18 ||
                    row.goods_type == 19 ||
                    row.goods_type == 3 ||
                    row.goods_type == 6 ||
                    row.goods_type == 7 ||
                    row.goods_type == 8 ||
                    row.goods_type == 9 ||
                    row.goods_type == 12 ||
                    row.goods_type == 17 ||
                    (row.goods_type == 5 && row.activity_type != 0)) &&
                  row.index === tabClickIndex &&
                  tabClickLabel === '单价'
                "
              >
                <el-input
                  v-model="row.goods_price"
                  :class="'danjia' + $index"
                  style="width: 80px"
                  :disabled="row.goods_name == '' || isEditPrice == 0"
                  onkeyup="value=value.replace(/[^\d.]/g,'') "
                  @input="utilsF"
                  @keyup.native.stop.prevent="danjiaD($event, $index, 'danjia')"
                />
              </template>
              <span v-else>{{ row.goods_price }}</span>
            </template>
          </el-table-column>
          <!-- 数量 -->
          <el-table-column
            prop="quantity"
            align="center"
            label="数量"
            min-width="110px"
          >
            <template #default="{ row, $index }">
              <!-- row.activity_type == 0 && -->
              <template
                v-if="
                  (row.goods_type == 1 ||
                    row.goods_type == 2 ||
                    row.goods_type == 10 ||
                    row.goods_type == 11 ||
                    row.goods_type == 18 ||
                    row.goods_type == 19 ||
                    row.goods_type == 3 ||
                    row.goods_type == 6 ||
                    row.goods_type == 7 ||
                    row.goods_type == 8 ||
                    row.goods_type == 9 ||
                    row.goods_type == 12 ||
                    row.goods_type == 17 ||
                    (row.goods_type == 5 && row.activity_type != 0)) &&
                  row.index === tabClickIndex &&
                  tabClickLabel === '数量'
                "
              >
                <el-input
                  v-model="row.quantity"
                  :disabled="row.goods_name == ''"
                  style="width: 80px"
                  onkeyup="value=value.replace(/[^\d.]/g,'') "
                  :class="'shuliang' + $index"
                  @input="utilsF"
                  @focus="inputfocus(row)"
                  @keyup.native.stop.prevent="
                    danjiaD($event, $index, 'shuliang')
                  "
                />
                <!-- <el-select
                v-model="row.quantity"
                placeholder="选择单位"
                style="width: 70px"
              >
                <el-option
                  v-for="item in quantityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select> -->
                <!-- <div
                class="cell-btn-wrap"
                style="display: flex; flex-wrap: nowrap"
              >
                <el-button
                  class="cancel-btn"
                  type="success"
                  @click="saveCellEdit(row)"
                >
                  保存
                </el-button>
                <el-button
                  class="cancel-btn"
                  type="warning"
                  @click="cancelCellEdit(row)"
                >
                  取消
                </el-button>
              </div> -->
              </template>
              <span v-else>{{ row.quantity }}</span>
            </template>
            <!-- <template #default="{ row }">
            {{ row.quantity }}
          </template> -->
          </el-table-column>
          <!-- 库存 -->
          <el-table-column
            prop="balance_count_text"
            align="center"
            label="库存"
            min-width="110px"
          >
            <!-- <template #default="{ row }">
            </template> -->
          </el-table-column>
          <!-- 金额 -->
          <el-table-column
            prop="goods_money"
            align="center"
            label="金额"
            min-width="90px"
          >
            <template #default="{ row }">
              <div
                v-if="
                  row.goods_type == 2 ||
                  row.goods_type == 10 ||
                  row.goods_type == 11 ||
                  row.goods_type == 3 ||
                  row.goods_type == 12 ||
                  row.goods_type == 18 ||
                  row.goods_type == 19 ||
                  row.goods_type == 13 ||
                  row.goods_type == 16 ||
                  row.goods_type == 17 ||
                  (row.goods_type == 5 && row.activity_type != 0)
                "
              >
                0
              </div>
              <div v-else>
                {{ Number(row.quantity * row.goods_price).toFixed(2) }}
              </div>
              <!-- {{ Number(row.quantity * row.goods_price).toFixed(2) }} -->
            </template>
          </el-table-column>
          <!-- 生产日期 -->
          <el-table-column
            prop="goods_production_date"
            align="center"
            label="生产日期"
            min-width="140px"
          >
            <template #default="{ row }">
              <!-- <el-input v-model="row.goods_production_date"></el-input> -->
              <!-- row.activity_type == 0 && -->
              <template
                v-if="
                  (row.goods_type == 1 ||
                    row.goods_type == 2 ||
                    row.goods_type == 10 ||
                    row.goods_type == 11 ||
                    row.goods_type == 3 ||
                    row.goods_type == 18 ||
                    row.goods_type == 19 ||
                    row.goods_type == 9 ||
                    row.goods_type == 6 ||
                    row.goods_type == 17 ||
                    (row.goods_type == 5 && row.activity_type != 0)) &&
                  row.index === tabClickIndex &&
                  tabClickLabel === '生产日期'
                "
              >
                <el-date-picker
                  v-model="row.goods_production_date"
                  value-format="yyyy-MM-dd"
                  style="width: 130px"
                ></el-date-picker>
              </template>
              <template v-else>
                {{ row.goods_production_date }}
              </template>
            </template>
          </el-table-column>
          <!-- 备注 -->
          <el-table-column
            prop="info"
            align="center"
            label="备注"
            min-width="110px"
          >
            <template #default="{ row }">
              <!-- row.activity_type == 0 && -->
              <template
                v-if="
                  (row.goods_type == 1 ||
                    row.goods_type == 2 ||
                    row.goods_type == 10 ||
                    row.goods_type == 11 ||
                    row.goods_type == 3 ||
                    row.goods_type == 18 ||
                    row.goods_type == 19 ||
                    row.goods_type == 6 ||
                    row.goods_type == 9 ||
                    row.goods_type == 17 ||
                    (row.goods_type == 5 && row.activity_type != 0)) &&
                  row.index === tabClickIndex &&
                  tabClickLabel === '备注'
                "
              >
                <el-input v-model="row.info"></el-input>
              </template>
              <template v-else>
                {{ row.info }}
              </template>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="操作"
            fixed="right"
            width="160"
          >
            <template #default="{ $index, row }">
              <!-- <el-button type="text" @click.native.prevent="addRow($index,row)">
              添加
            </el-button> -->
              <!-- row.activity_type == 0 && -->
              <div
                v-if="
                  row.goods_type == 1 ||
                  row.goods_type == 2 ||
                  row.goods_type == 10 ||
                  row.goods_type == 11 ||
                  row.goods_type == 3 ||
                  row.goods_type == 6 ||
                  row.goods_type == 18 ||
                  row.goods_type == 19 ||
                  row.goods_type == 9 ||
                  row.goods_type == 17 ||
                  (row.goods_type == 5 && row.activity_id != 0)
                "
              >
                <el-button
                  type="text"
                  @click.native.prevent="copyRow($index, row)"
                >
                  复制
                </el-button>
                <el-button
                  type="text"
                  @click.native.prevent="deleteRow($index, row)"
                >
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 底部输入框 -->
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          :inline="true"
          style="margin-top: 15px"
        >
          <!--          <div v-if="style == 1">-->
          <el-form-item label="备注" prop="remark" style="width: 100%">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item label="订购总额" prop="total_amount">
            <el-input v-model.trim="form.total_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="退货金额" prop="refund_goods_amount">
            <el-input
              v-model.trim="form.refund_goods_amount"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="销售金额" prop="sale_amount">
            <el-input v-model.trim="form.sale_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="优惠" prop="discount_amount">
            <el-input
              v-model.trim="form.discount_amount"
              @input="yhInputF"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠后" prop="discounted_amount">
            <el-input
              v-model.trim="form.discounted_amount"
              :min="0"
              :max="1000000000"
              type="number"
              style="width: 186.25px"
              @input="yhhInputF"
            ></el-input>
          </el-form-item>
          <el-form-item label="退货退款" prop="refund_amount">
            <el-input v-model.trim="form.refund_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="退货方式" prop="refund_type_text">
            <!-- <el-input v-model="form.refund_type_text"></el-input> -->
            <el-select
              v-model="form.refund_type"
              placeholder="退货方式"
              style="width: 186px"
              @change="utilsF"
            >
              <el-option
                v-for="item in allSelectList.arr_refund_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.is_fun" label="费用抵扣" prop="thtk">
            <el-input
              v-model.trim="form.support_amount"
              @input="yshandler"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="form.is_fun">
            <el-select
              v-model="form.fun_ids"
              :placeholder="'请选费用' + `（合计${funds_zong}）`"
              style="width: 186px"
              multiple
              collapse-tags
              @change="handlerfydk"
            >
              <el-option
                v-for="item_ in funselect"
                :key="item_.id"
                :label="item_.funds_type + `(合计${item_.left_amount})`"
                :value="item_.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="style == 2" label="兑奖现金:" prop="prize_amount">
            <el-input
              v-model.trim="form.prize_amount"
              @input="rehandler"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="style == 3" label="兑付现金:" prop="cash_amount">
            <el-input
              v-model.trim="form.cash_amount"
              @input="rehandler"
            ></el-input>
          </el-form-item>
          <el-form-item label="应收款" prop="receiv_money">
            <el-input v-model.trim="form.receiv_money" disabled></el-input>
          </el-form-item>
          <el-form-item label="使用预存款" prop="deposit_amount">
            <el-input v-model.trim="form.deposit_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="还货金额" prop="return_amount">
            <el-input v-model.trim="form.return_amount" disabled></el-input>
          </el-form-item>

          <el-form-item label="结算方式" prop="account_type" style="width: 60%">
            <el-select
              v-model="form.account_type"
              placeholder="结算方式"
              style="width: 186px"
              @change="jsfsChange"
            >
              <el-option
                v-for="item in allSelectList.arr_account_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div></div>
          <!-- <el-form-item
              v-for="(item1, index) in xssk"
              :key="index"
              label="销售收款"
            >
              <div style="display: flex">
                <el-input
                  v-model.trim="item1.money"
                  style="width: 90px; margin-right: 20px"
                ></el-input>
                <el-select v-model="item1.pay_type" style="width: 90px">
                  <el-option
                    v-for="item2 in allSelectList.arr_pay_type"
                    :key="item2.id"
                    :label="item2.name"
                    :value="item2.id"
                  ></el-option>
                </el-select>
              </div>
              <i
                v-if="index == 0"
                class="el-icon-circle-plus addbtn"
                @click="addInput"
              ></i>
            </el-form-item> -->
          <!-- <div></div>
            <el-form-item label="下单收款" prop="">
              <el-input
                :disabled="form.bill_status_text == '已对账'"
                style="width: 90px; margin-right: 20px"
              ></el-input>
              <el-select
                style="width: 90px"
                :disabled="form.bill_status_text == '已对账'"
              >
                <el-option
                  v-for="item2 in allSelectList.arr_pay_type"
                  :key="item2.id"
                  :label="item2.name"
                  :value="item2.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <div></div>

            <el-form-item
              v-if="
                form.bill_type_text == '网销' || form.bill_type_text == '访销'
              "
              label="派送收款"
              prop=""
            >
              <el-input style="width: 90px; margin-right: 20px"></el-input>
              <el-select style="width: 90px">
                <el-option
                  v-for="item2 in allSelectList.arr_pay_type"
                  :key="item2.id"
                  :label="item2.name"
                  :value="item2.id"
                ></el-option>
              </el-select>
            </el-form-item> -->
          <!--          </div>
          <div v-else-if="style == 2">
            <el-form-item label="兑奖现金:" prop="prize_amount">
              <el-input v-model.trim="form.prize_amount"></el-input>
            </el-form-item>
          </div>
          <div v-else-if="style == 3">
            <el-form-item label="兑付现金:" prop="cash_amount">
              <el-input v-model.trim="form.cash_amount"></el-input>
            </el-form-item>
          </div>-->
        </el-form>
        <!-- 小表格 -->
        <!-- v-show="xTableData.length" -->

        <el-table
          ref="xTable"
          stripe
          :data="xTableData"
          show-summary
          :summary-method="summaryMethod2"
        >
          <el-table-column
            align="center"
            label="类型"
            width="auto"
            prop="type_id"
          >
            <template #default="{ row }">
              <el-select
                v-model="row.type_id"
                disabled
                style="width: 100%"
                placeholder="类型"
              >
                <el-option
                  v-for="(i, idx) in typeSelect"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="收款方式"
            width="auto"
            prop="id"
          >
            <template #default="{ row }">
              <el-select
                v-model="row.id"
                clearable
                style="width: 100%"
                placeholder="收款方式"
              >
                <el-option
                  v-for="(i, idx) in allSelectList.arr_pay_type"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="金额"
            width="auto"
            prop="amount"
          >
            <template #default="{ row }">
              <el-input
                v-model="row.amount"
                type="number"
                placeholder="金额"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="收款人"
            width="auto"
            prop="user_id"
          >
            {{ form.staff_name }}
            <!-- <el-input
                v-model="row.user_name"
                :disabled="!row.edit"
                placeholder="收款人"
              ></el-input> -->
            <!-- <el-select
                v-model="row.user_id"
                clearable
                :disabled="!row.edit"
                style="width: 100%"
                placeholder="收款人"
              >
                <el-option
                  v-for="(i, idx) in allSelectList.staff"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select> -->
          </el-table-column>
          <el-table-column
            align="center"
            label="时间"
            width="auto"
            prop="create_at"
          >
            <template #default="{ row }">
              {{ row.create_at }}
              <!-- <el-date-picker
                v-model="row.create_at"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker> -->
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            align="center"
            label="操作"
            fixed="right"
            width="130px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleAdd($index, row)"
              >
                添加
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDelete($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="bottom-form">
          <div class="tit">相关单据：</div>
          <template v-if="bottomData">
            <div v-show="bottomData.bill_code" class="b-item">
              预订单:
              {{ bottomData.bill_code == '' ? '' : bottomData.bill_code }}
            </div>
            <div v-show="bottomData.allot_code" class="b-item">
              调拨单:
              {{ bottomData.allot_code == '' ? '' : bottomData.allot_code }}
            </div>
            <div
              v-show="
                bottomData.sale_code &&
                bottomData.delivery_code &&
                bottomData.clearing_code
              "
              class="b-item"
            >
              收款单:
              {{ bottomData.sale_code == '' ? '' : bottomData.sale_code }}
              、{{
                bottomData.delivery_code == '' ? '' : bottomData.delivery_code
              }}
              、{{
                bottomData.clearing_code == '' ? '' : bottomData.clearing_code
              }}
            </div>
          </template>
          <el-button class="checkAll" @click="checkAll">查看全部单据</el-button>
        </div>
        <!-- 底部按钮 -->
        <div
          slot="footer"
          class="dialog-footer"
          style="margin-top: 5px; text-align: right"
        >
          <el-button v-if="!isCopy" type="primary" @click="handleSave">
            保存
          </el-button>
          <el-button v-else type="primary" @click="handleCopy">复制</el-button>
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { getDetail, saveSale } from '@/api/saleOrder'
  import OrderStatus from '@/baseComponents/orderStatus'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { yhhInput, utils, yhInput, receiv } from '@/utils/AOform.js'
  import { getAllSelect } from '@/api/advanceOrder'
  import { dateFormat } from '@/utils/Time'
  import { getStaffList } from '@/api/setPrice'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'CheckOrder',
    components: {
      OrderStatus,
      GoodsSearch,
    },
    data() {
      return {
        funselect: [],
        funds_zong: '',
        timeout: null,
        isshowDialog: false,
        loading: false,
        style: 1,
        isCopy: false,
        id: 0,
        // 单号
        orderCode: '',
        // 下单时间
        orderTime: '',
        form: {},
        title: '订单详情',
        orderStatus: '状态',
        bottomData: {},
        allSelectList: [],
        xssk: [],
        total_num: null,
        showForm: false,
        total_money: null,
        // 中间表格
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '条码',
          '单位',
          '单价',
          '数量',
          '金额',
          '毛利',
          '生产日期',
          '备注',
        ],
        columns: [
          {
            order: 2,
            label: '类型',
            width: 'auto',
            prop: 'goods_type',
            sortable: false,
          },
          {
            order: 3,
            label: '商品名称',
            width: '260px',
            prop: 'goods_name_print',
            sortable: false,
          },
          {
            order: 3.5,
            label: '规格',
            width: 'auto',
            prop: 'specs',
            sortable: false,
          },
          {
            order: 3.6,
            label: '条码',
            width: 'auto',
            prop: 'unit_code',
            sortable: false,
          },
          {
            order: 4,
            label: '单位',
            width: '110', //'100',
            prop: 'unit_name',
            sortable: false,
          },
          {
            order: 5,
            label: '单价',
            width: '100',
            prop: 'goods_price',
            sortable: false,
          },
          {
            order: 6,
            label: '数量',
            width: '100',
            prop: 'quantity',
            sortable: false,
          },
          {
            order: 8,
            label: '毛利',
            width: '110',
            prop: 'profit_amount',
            sortable: false,
          },
          {
            order: 7,
            label: '金额',
            width: '100',
            prop: 'goods_money',
            sortable: false,
          },
          {
            order: 9,
            label: '生产日期',
            width: '110',
            prop: 'goods_production_date',
            sortable: false,
          },
          {
            order: 10,
            label: '备注',
            width: '100',
            prop: 'info',
            sortable: false,
          },
        ],
        list: [],
        xTableData: [],
        typeSelect: [
          {
            id: 1,
            name: '销售收款',
          },
          {
            id: 2,
            name: '派送收款',
          },
        ],
        staffList: [],
        edit: true,
        tabClickLabel: '',
        tabClickIndex: null,
      }
    },

    computed: {
      currUnitCode() {
        return (row) => {
          if (row.arr_unit && row.arr_unit.length > 0) {
            let a = row.arr_unit.filter(
              (i) => (i.unit_id || i.id) == row.unit_id
            )[0].unit_code
            return a
          } else {
            return ''
          }
        }
      },
      isEditPrice() {
        return localStorage.getItem('isEditPrice')
      },
      chck() {
        return this.form.depot_name
      },
      titleTip() {
        return '单号:' + this.orderCode + ' 下单时间： ' + this.form.create_time
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
      orderStatusStr() {
        if (this.orderStatus == '待对账') {
          return 'ddz'
        } else if (this.orderStatus == '已对账') {
          return 'ydz'
        } else if (this.orderStatus == '已作废') {
          return 'yzf'
        } else {
          return ''
        }
      },
    },
    watch: {
      isshowDialog(val) {
        if (val == true) {
          this.getData()
          this.getAllSelectList()
        } else {
          this.showForm = false
          this.edit = true
        }
      },
    },
    mounted() {
      // 获取业务员下拉
      // getStaffList().then((res) => {
      //   if (res.code == 200) this.staffList = res.data
      // })
    },
    methods: {
      handlercellclick(row, column, cell, event) {
        // console.log(row, column, cell, event)
        this.tabClickIndex = row.index
        this.tabClickLabel = column.label
      },
      tableRowClassName({ row, rowIndex }) {
        // 把每一行的索引放进row
        row.index = rowIndex
      },
      rehandler() {
        receiv(this)
      },
      //获取费用抵扣列表
      handlerfydk(v) {
        let fydk = 0
        console.log(v)
        v.map((list) => {
          fydk += Number(
            this.funselect.filter((item) => item.id == list)[0].left_amount
          )
        })
        this.form.support_amount = fydk.toFixed(2)

        this.yshandler()
      },
      // 单价输入框按
      danjiaD(e, index, name) {
        let n = 0
        let l = this.list.length
        if (e.code == 'ArrowUp') {
          // 按键 上
          while (n <= l) {
            if (
              document.getElementsByClassName(name + (index - 1 - n)).length >
                1 &&
              document.getElementsByClassName(name + (index - 1 - n))[0]
                .children[0]
            ) {
              document
                .getElementsByClassName(name + (index - 1 - n))[0]
                .children[0].focus()
              n += l + 1
            } else {
              n++
            }
          }
        } else if (e.code == 'ArrowDown') {
          // 下
          while (n <= l) {
            if (
              document.getElementsByClassName(name + (index + 1 + n)).length >
                1 &&
              document.getElementsByClassName(name + (index + 1 + n))[0]
                .children[0]
            ) {
              document
                .getElementsByClassName(name + (index + 1 + n))[0]
                .children[0].focus()
              n += l + 1
            } else {
              n++
            }
          }
          // this.$refs['danjia' + (index + 1)]?.focus()
        }
      },
      inputfocus(row) {
        if (row.quantity == 0) {
          row.quantity = ''
        }
      },
      async getData() {
        this.loading = true
        let { data } = await getDetail({ id: this.id })
        this.form = data.order_info
        // this.form = Object.assign(data.order_info, this.form)
        if (data.order_info.is_fun) {
          postAction('/saleAdmin/order-pre/funds-option', {
            cust_id: data.order_info.cust_id,
            order_id: this.id,
          }).then((res) => {
            this.funselect = res.data.bill_list
            this.funds_zong = res.data.funds_zong
          })
        }
        this.form.staff_id = String(this.form.staff_id)
        console.log(typeof this.form.ckzx_falg)
        this.form.ckzx_falg = Number(this.form.ckzx_falg)
        this.orderCode = this.form.bill_code
        this.form.boss = this.form.boss + ' ' + this.form.mobile
        // 表格
        this.list = data.order_detail_info
        this.list.push({
          activity_code: '',
          activity_id: 0,
          activity_type: 0,
          balance_count: 0,
          depot_stock_num: 0,
          edit: true,
          goods_child_id: null,
          goods_id: '',
          goods_money: '',
          goods_name: '',
          goods_price: '',
          goods_production_date: '',
          goods_type: 1,
          info: '',
          money: '',
          quantity: '',
          specs: '',
          unit_id: '',
        })
        // bottom table
        this.xTableData = data.arr_collect
        // 当前时间
        let time = dateFormat(new Date())
        this.time = time
        if (this.xTableData.length == 0) {
          this.xTableData.push({
            id: null, // 收款方式
            amount: '', //金额
            create_at: this.time, //时间
            type: '', //
            type_id: 1, //paytypeid
            // bill_status: 1, //对账状态 1未对账 可编辑；其它不可编辑
            pay_type_name: '',
            user_name: '',
            user_id: '',
            edit: true,
          })
        }
        this.bottomData = data.bill_no || null
        // 合计
        this.total_num = this.form.total_num
        this.total_money = this.form.total_money
        // 经办人 + 手机
        this.form.jingbanren =
          this.form.agent_name + ' ' + this.form.agent_mobile
        this.orderStatus = this.form.bill_status_text
        // 处理下方表单显示的样式
        console.log(this.form.receiv_money)
        // style样式选择
        if (
          this.form.sale_type == '1' ||
          this.form.sale_type == '2' ||
          this.form.sale_type == '3'
        ) {
          console.log('1样式')
          this.style = 1
        } else if (this.form.sale_type == '5' || this.form.sale_type == '6') {
          console.log('兑奖样式')
          this.style = 2
        } else if (this.form.sale_type == '4') {
          console.log('兑付样式')
          this.style = 3
        } else {
          this.style = 1
        }
        if (data.order_info.fun_ids == '') {
          this.form.fun_ids = []
        } else {
          this.form.fun_ids = data.order_info.fun_ids.split(',')
        }
        this.showForm = true
        this.loading = false
      },
      showDialog() {
        this.isshowDialog = true
      },
      close() {
        this.isshowDialog = false
      },
      summaryMethod2({ columns, data }) {
        const sums = []
        columns.forEach((item) => {
          sums.push('')
        })
        let nums = 0
        sums[0] = '合计'
        data.forEach((item) => {
          console.log('ooi', item)
          if (Number(item.amount) != NaN) {
            nums += Number(item.amount)
          }
        })
        sums[2] = nums + '元'
        return sums
      },
      summaryMethod(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [8]
        let sums = []
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        return sums
      },
      // 查看全部单据
      checkAll() {
        console.log('查看全部单据')
      },
      // 复制
      handleCopy() {},
      // 保存
      handleSave() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          // let order_info = {
          //   id: this.id,
          //   remark: this.form.remark,
          //   discounted_amount: this.form.discounted_amount,
          //   refund_type: this.form.refund_type,
          //   account_type: this.form.account_type,
          // cash_amount:this.form.cash_amount //兑付金额
          // prize_amount:this.form.prize_amount // 兑奖现金
          // }
          let list = []
          this.list.forEach((i) => {
            if (i.goods_id) {
              list.push(i)
            }
          })
          let order_info = { ...this.form, ...{ id: this.id } }
          order_info.fun_ids = order_info.fun_ids.join(',')
          let goods_info = list
          let pd = []
          this.xTableData.forEach((i) => {
            if (i.amount != '') {
              pd.push(i)
            }
          })
          let endData = {
            order_info: JSON.stringify(order_info),
            goods_info: JSON.stringify(goods_info),
            pay_info: pd.length == 0 ? '' : JSON.stringify(pd),
          }

          console.log('endData', endData)
          saveSale(endData).then((res) => {
            console.log('rrr', res)
            this.close()
            this.$emit('refresh')
          })
        }, 1000)
      },

      yhhInputF(val) {
        yhhInput(val, this)
      },
      yhInputF(val) {
        yhInput(val, this)
      },
      yshandler(val) {
        this.form.receiv_money = Number(
          Number(this.form.sale_amount) -
            Number(this.form.discount_amount) -
            Number(this.form.refund_amount) -
            Number(this.form.support_amount) -
            Number(this.form.prize_amount) -
            Number(this.form.cash_amount)
        ).toFixed(2)
      },
      // 计算form2input
      calcForm2(data, o = true) {
        console.log(data)
        let total_amount = 0,
          xsje = 0,
          thje = 0,
          yhh = 0,
          thtk = 0,
          ysk = 0
        data.forEach((item) => {
          let num = Number(
            (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
          )
          // 订购总额
          // 销常
          if (item.goods_type == 1) {
            total_amount += num
            xsje += num
            yhh += num
          } else if (item.goods_type == 6) {
            // 退货金额
            console.log('退常品')
            thje += num
            // this.form2.thje =
          }
        })
        console.log('zzz')

        this.form.total_amount = total_amount.toFixed(2)
        this.form.sale_amount = xsje.toFixed(2)
        // this.form.refund_amount = thje
        if (o) {
          this.form.discounted_amount = yhh.toFixed(2)
        } else {
          this.form.discounted_amount = this.form.discounted_amount.toFixed(2)
        }
        // if (this.form.yhh == this.form.xsje || this.form.yhh == 0) {
        //   this.form.yhh = yhh
        // }

        // 应收款
        if (yhh == 0 || yhh == '') {
          console.log('this.form.sale_amount', this.form.sale_amount)
          console.log('this.form.refund_amount', this.form.refund_amount)
          this.form.receiv_money = (
            this.form.sale_amount - this.form.refund_amount
          ).toFixed(2)
        } else {
          this.form.receiv_money =
            Number(yhh).toFixed(2) - Number(this.form.refund_amount).toFixed(2)
        }
        // 退货退款
        if (this.form.refund_type == 1) {
          console.log('thje', thje)
          this.form.refund_amount = Number(thje).toFixed(2)
        } else if (this.form.refund_type != 1) {
          console.log('退货退款不是 1 ')
          this.form.refund_amount = 0
        }
        console.log('优惠后', yhh)
      },
      utilsF() {
        utils(this.list, this)
      },
      // 勾选表格触发事件
      setSelectRows(val) {
        console.log(val)
        this.selectRows = val
      },
      jsfsChange(val) {
        if (val == 1) {
          this.xssk[0].money = this.form.receiv_money
        } else {
          this.xssk[0].money = 0
        }
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let unit = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        row.goods_price = price
        row.unit_name = unit
      },
      handleTypeChange(v, row) {
        row.goods_type_text = row.arr_goods_type.filter(
          (i) => i.id == v
        )[0].name
        console.log('v', v, row)
        let unitId = row.arr_unit.filter((i) => i.id == row.unit_id)
        if (v == 6 || v == 10 || v == 11 || v == 13 || v == 14 || v==19) {
          if (unitId.length > 0) {
            row.goods_price = unitId[0].return_price
          }
        }else if(v==17){//24-12-23修改添加特价类型取值和退回类型取值
          row.goods_price = unitId[0].sell_price_te
        } else {
          row.goods_price = unitId[0].sell_price
        }
      },
      addRows(val) {
        console.log('添加rows')
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            item.id = ''
            // item.activity_type = 0
            this.$set(item, 'activity_type', 0)
            item.goods_type_text = item.arr_goods_type.filter(
              (i) => i.id == item.goods_type
            )[0].name
            item.balance_count_text = item.balance_count_read
            item.arr_unit.forEach((unit) => {
              if (unit.is_default == 2) {
                item.unit_id = unit.id
              }
              if ((item.unit_id = unit.unit_id)) {
                item.unit_name = unit.unit_name
              }
            })
            this.list.splice(this.list.length - 1, 0, item)
          })
          this.$message.success('添加成功')
        }
      },
      // 添加行
      addRow(index, row, $event) {
        if ($event == '') {
          return false
        }
        if (this.list.length == index + 1) {
          this.list.push({
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            depot_stock_num: 0,
            edit: true,
            goods_child_id: null,
            goods_id: '',
            goods_money: '',
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: 1,
            info: '',
            money: '',
            quantity: '',
            specs: '',
            unit_id: '',
          })
        }
      },
      chooseGoodsKeyword(val, row) {
        console.log('chooseGoodsKeyword')
        row.goods_name = val
      },
      async getAllSelectList() {
        let { data, code, msg } = await getAllSelect()
        if (code == 200) {
          console.log('所有下拉列表')
          console.log(data)
          this.allSelectList = data
        }
      },
      selectGoods(val, row, index) {
        if (val.goods_id !== -1) {
          console.log(val)
          console.log(row)
          Object.assign(row, val, { goods_type: 1 })
          row.id = ''
          console.log('asdasd')
          if (val.arr_unit.length != 0) {
            console.log(
              val.arr_unit.filter((item) => item.id == val.unit_id)[0].unit_name
            )
            row.unit_name = row.arr_unit.filter(
              (item) => item.id == val.unit_id
            )[0].unit_name
          }
          row.goods_type_text = row.arr_goods_type.filter(
            (item) => item.id == row.goods_type
          )[0].name
          if (row.child.length > 0) {
            row.goods_name_print = row.goods_name
          }
          // row.arr_unit.forEach((unit) => {
          //   if (unit.is_default == 2) {
          //     row.unit_id = unit.id
          //   }
          // })
          if (this.list.length == index + 1) {
            this.list.push({
              activity_code: '',
              activity_id: 0,
              activity_type: 0,
              balance_count: 0,
              depot_stock_num: 0,
              edit: true,
              goods_child_id: null,
              goods_id: '',
              goods_money: '',
              goods_name: '',
              goods_price: '',
              goods_production_date: '',
              goods_type: 1,
              info: '',
              money: '',
              quantity: '',
              specs: '',
              unit_id: '',
              unit_name: '',
            })
          }
          console.log('222')

          // this.addRow(this.list.length - 1, row, '1')
        }
      },
      addInput() {
        this.xssk.push({ money: '', pay_type: '' })
        console.log(this.form)
      },
      copyRow(index, row) {
        let rowS = JSON.parse(JSON.stringify(row))
        let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.list.splice(index, 0, rowa)
        this.utilsF()
      },
      handleAdd() {
        this.xTableData.push({
          id: null, // 收款方式
          amount: '', //金额
          create_at: this.time, //时间
          type: '', //
          type_id: 1, //paytypeid
          // bill_status: 1, //对账状态 1未对账 可编辑；其它不可编辑
          pay_type_name: '',
          user_name: '',
          user_id: null,
          edit: true,
        })
      },
      handleDelete(index) {
        if (this.xTableData.length == 1) {
          this.$message.error('不能删除最后一条数据1')
          this.xTableData[0].id = null
          this.xTableData[0].amount = ''
          this.xTableData[0].type = ''
          this.xTableData[0].type_id = 1
          this.xTableData[0].user_id = ''
        } else {
          this.xTableData.splice(index, 1)
        }
      },
      deleteRow(index, row) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一条数据')
        } else {
          this.list.splice(index, 1)
          this.utilsF()
        }
      },
      changeKouwei(val, row) {
        console.log('改变口味', val)
        row.goods_child_id = val.id
        // row.goods_name_print = val.goods_name_print + ` (${val.attr})`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wrapper2 {
    min-height: 800px;
  }
  .setFilter {
    top: 41px;
    left: 7px;
  }
  .bottom-form {
    position: relative;
    padding: 10px;
    border: 1px solid #ccc;
    .b-item {
      margin: 5px 0;
    }
    .checkAll {
      position: absolute;
      top: 50%;
      right: 45px;
      transform: translateY(-50%);
    }
  }
  .orderStatus {
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 8;
    width: 105px;
    height: 105px;
    font-size: 16px;
    line-height: 105px;
    color: #f60;
    text-align: center;
    border: 2px solid #f60;
    border-radius: 50%;
  }
  .addbtn {
    position: absolute;
    top: 0;
    right: -50px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
</style>
