<template>
  <div class="wrapper">
    <el-autocomplete
      v-model="goods_keyword"
      :style="{ width: auwidth + 'px' }"
      class="inline-input"
      :popper-class="popperClass"
      popper-append-to-body
      :fetch-suggestions="querySearch"
      value-key="goods_name"
      placeholder="请输入商品名称、简拼"
      :disabled="isB"
      :trigger-on-focus="false"
      :debounce="1000"
      @select="handleSelect"
      @input="handleInput"
    >
      <i
        v-if="search"
        slot="suffix"
        class="el-input__icon el-icon-search"
        style="padding-right: -10px"
        @click="iconClick"
      ></i>
      <el-dropdown
        v-if="isTable && rowf.child && rowf.child.length > 0"
        slot="suffix"
        trigger="click"
        @command="handleKouweiChange"
      >
        <span class="el-dropdown-link">
          <i class="el-input__icon el-icon-caret-right asd"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in rowf.child"
            :key="index"
            :command="item"
          >
            {{ item.attr }}
          </el-dropdown-item>
          <!-- <el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>
          <el-dropdown-item icon="el-icon-circle-plus">狮子头</el-dropdown-item>
          <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <i
        v-if="isTable"
        slot="suffix"
        class="el-input__icon el-icon-caret-right asd"
      ></i> -->

      <template slot-scope="{ item }">
        <div class="wrap" style="display: flex; justify-content: space-between">
          <div class="name">
            {{ item.goods_name }}
          </div>
          <span class="addr">{{ item.specs }}</span>
        </div>
      </template>
    </el-autocomplete>

    <!-- <el-button
      icon="el-icon-search"
      circle
      class="icon-btn"
      @click="iconClick"
    ></el-button> -->
    <goods-search-dialog
      ref="goodsSearchDialog"
      :brand-ids="brandId"
      :is-table="isTable"
      :is-old="isOld"
      :new-obj="goodsListObj"
      :dbj="dbj"
      :onlyshow-kucun="onlyshowKucun"
      @add-rows="addRows"
      @change-goods="setInput"
    ></goods-search-dialog>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { getInputList } from '@/api/goodsSearchInput'
  import GoodsSearchDialog from '@/baseComponents/goodsChooseDialog'
  export default {
    name: 'GoodsSearch',
    components: {
      GoodsSearchDialog,
    },
    props: {
      auwidth: {
        type: Number,
        default: () => 180,
      },
      // 选择后显示输入框的文字?
      selectgoodstext: {
        type: Boolean,
        default: true,
      },
      // 只显示库存
      onlyshowKucun: {
        type: Boolean,
        default: () => true,
      },
      // 付费陈列 old_sell_price
      isOld: {
        type: Boolean,
        default: () => false,
      },
      fKey: {
        type: String,
        default: () => '',
      },
      goodsId: {
        type: [String, Number],
        default: () => null,
      },
      rowf: {
        type: Object,
        default: () => {
          return {}
        },
      },
      // 口味
      kouwei: {
        type: [String, Array],
        default: () => null,
      },
      // 是否判断brandid为空就警告
      isBrand: {
        type: Boolean,
        default: () => false,
      },
      search: {
        type: Boolean,
        default: () => true,
      },
      isTable: {
        type: Boolean,
        default: () => true,
      },
      disab: {
        type: Boolean,
        default: () => false,
      },
      // 是否试用调拨价
      dbj: {
        type: Boolean,
        default: () => false,
      },
      // 是否显示口味
      showKouwei: {
        type: Boolean,
        default: () => true,
      },
      depotId: {
        type: [String, Number],
        default: () => '',
      },
      //品牌id
      brandId: {
        type: String,
        default: () => '',
      },
      //品类id
      classId: {
        type: String,
        default: () => '',
      },
      //客户id
      custId: {
        type: [Number, String],
        default: () => '',
      },
      popperClass: {
        type: String,
        default: () => 'selectDC',
      },
      // 商品下拉添加参数
      goodsListObj: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        // res: [],
        goods_keyword: '',
      }
    },
    computed: {
      isB() {
        if ((this.brandId == '' && this.isBrand) || this.disab) {
          return true
        } else {
          return false
        }
      },
    },
    watch: {
      selectgoodstext(v) {
        console.log('selectgoodstext变化了', v)
      },
      goods_keyword(val) {
        this.$emit('choose-goods-keyword', val)
      },
      fKey(val) {
        if (val) {
          this.goods_keyword = val
        } else {
          this.goods_keyword = ''
        }
      },
    },
    created() {
      this.goods_keyword = this.fKey

      if (this.rowf && this.rowf.goods_child_attr) {
        console.log('口味')
        this.goods_keyword += ` (${this.rowf.goods_child_attr})`
      }

      // this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData(ky = '') {
        console.log('custid', this.custId)
        let { data, code, msg } = await getInputList({
          ...{
            pageNo: 1,
            pageSize: -1,
            keyword: ky,
            is_close: 0,
            brand_id: this.brandId,
            depot_id: this.depotId,
            custom_id: this.custId,
            class_id: this.classId,
          },
          ...this.goodsListObj,
        })

        if (code == 200) {
          let datalist = JSON.parse(JSON.stringify(data))
          // this.res = datalist
          console.log('123')
          console.log(datalist, data)
          return datalist
        }
      },
      querySearch(queryString, cb) {
        this.clearHttpRequestingList()
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            if (val) cb(JSON.parse(JSON.stringify(val)))
          })
        }
      },
      addRows(val) {
        console.log(val)
        console.log('添加rows')
        let this_ = this
        val.forEach((item) => {
          item.arr_unit.forEach((unit) => {
            if (unit.is_default == 2) {
              item.unit_id = unit.id
            }
          })
          // if (item.unit_id == '') {
          //   item.arr_unit.forEach((unit) => {
          //     if (unit.unit_type == 1) {
          //       item.unit_id = unit.id
          //     }
          //   })
          // }
          if (this.isOld) {
            item.goods_price = this_.dbj
              ? item.arr_unit.filter((i) => i.unit_id == item.unit_id)[0]
                  .allot_scheme_price
              : item.arr_unit.filter((i) => i.unit_id == item.unit_id)[0]
                  .old_sell_price
          } else {
            item.goods_price = this_.dbj
              ? item.arr_unit.filter((i) => i.unit_id == item.unit_id)[0]
                  .allot_scheme_price
              : item.arr_unit.filter((i) => i.unit_id == item.unit_id)[0]
                  .sell_price
          }
          if (item.child && item.child.length > 0) {
            //库存变化表用的
            val.goods_name_changes = structuredClone(val).goods_name
            item.goods_name = item.goods_name += ` (${item.child[0].attr})`
            item.goods_child_id = item.child[0].id
          }
        })

        this.$emit('add-rows', val)
      },
      handleSelect(val) {
        console.log('val,,,selectgoodstext', val, this.selectgoodstext)
        if (this.selectgoodstext) {
          this.$emit('select-goods', val.goods_id)
          val.arr_unit.forEach((unit) => {
            if (unit.is_default == 2) {
              console.log('设置了默认单位', unit)
              val.unit_id = unit.id
              if (this.isOld) {
                val.goods_price = this.dbj
                  ? unit.allot_scheme_price
                  : unit.old_sell_price
              } else {
                val.goods_price = this.dbj
                  ? unit.allot_scheme_price
                  : unit.sell_price
              }
            }
          })
          // if (val.unit_id == '') {
          //   console.log('没有设置默认单位unit_id')
          //   val.arr_unit.forEach((unit) => {
          //     if (unit.unit_type == 1) {
          //       val.unit_id = unit.id
          //       if (this.isOld) {
          //         val.goods_price = this.dbj
          //           ? unit.allot_scheme_price
          //           : unit.old_sell_price
          //       } else {
          //         val.goods_price = this.dbj
          //           ? unit.allot_scheme_price
          //           : unit.sell_price
          //       }
          //     }
          //   })
          // }
          if (val.child.length > 0) {
            if (this.showKouwei) {
              //库存变化表用的
              val.goods_name_changes = structuredClone(val).goods_name
              val.goods_name = val.goods_name += ` (${val.child[0].attr})`
            } else {
              //库存变化表用的
              val.goods_name_changes = structuredClone(val).goods_name
              val.goods_name = val.goods_name
            }
            // val.goods_name_print = val.goods_name += ` (${val.child[0].attr})`
            val.goods_child_id = val.child[0].id
          }

          console.log('val', val)
          this.$emit('select-goods-all', val)
        } else {
          this.goods_keyword = ''
          this.$emit('select-goods-all', val)
        }
      },
      handleInput(val) {
        if (this.selectgoodstext) {
          console.log(val, '名称')
          this.$emit('input-goods', val)
          if (val == '') {
            this.$emit('select-goods', '')
            this.$emit('select-goods-all', { arr_unit: [] })
          } else {
            this.$emit('select-goods', -1)
            this.$emit('select-goods-all', {
              goods_id: -1,
              arr_unit: [],
              goods_name: val,
            })
          }
        }
      },
      iconClick() {
        if (!this.disab) {
          console.log('111')
          if (this.depotId) {
            this.$refs.goodsSearchDialog.depotId = this.depotId
          }
          console.log('this.cust_id', this.custId)
          this.$refs.goodsSearchDialog.custId = this.custId
          this.$refs.goodsSearchDialog.showDialog = true
        }
      },
      setInput(val) {
        console.log(val)
        if (this.selectgoodstext) {
          this.goods_keyword = val.goods_name
          this.$emit('select-search-goods', val.goods_name)

          this.$emit('select-goods', val.goods_name)
          this.$emit('select-id', val.id)
          val.arr_unit.forEach((unit) => {
            if (unit.is_default == 2) {
              val.unit_id = unit.id
              if (this.isOld) {
                val.goods_price = this.dbj
                  ? unit.allot_scheme_price
                  : unit.old_sell_price
              } else {
                val.goods_price = this.dbj
                  ? unit.allot_scheme_price
                  : unit.sell_price
              }
            }
          })
          // if (val.unit_id == '') {
          //   val.arr_unit.forEach((unit) => {
          //     if (unit.unit_type == 1) {
          //       val.unit_id = unit.id
          //       if (this.isOld) {
          //         val.goods_price = this.dbj
          //           ? unit.allot_scheme_price
          //           : unit.old_sell_price
          //       } else {
          //         val.goods_price = this.dbj
          //           ? unit.allot_scheme_price
          //           : unit.sell_price
          //       }
          //     }
          //   })
          // }
        }
        this.$emit('select-goods-all', val)
      },
      resetForm() {
        this.goods_keyword = ''
      },
      handleKouweiChange(com) {
        console.log('a', com)
        this.goods_keyword = com.goods_name + ` (${com.attr})`
        this.$emit('change-kouwei', com)
      },
      /**
       * 中断请求方法
       */
      clearHttpRequestingList() {
        // 判断是否有存储的cancle
        if (Vue.$httpRequestList.length > 0) {
          Vue.$httpRequestList.forEach((item) => {
            // 给个标志，中断请求
            item('interrupt')
          })
          Vue.$httpRequestList = []
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .selectDC {
    padding-right: 40px !important;
  }
  .icon-btn {
    position: absolute;
    top: 2px;
    right: 1px;
    border: 0px solid transparent;
  }
  .asd {
    transform: rotateZ(45deg);
  }
  ::v-deep {
    .el-input__icon {
      width: 20px;
    }
  }
</style>
