<template>
  <!-- NAME[epic=动销] 陈列协议查询 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="clientId"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          clearable
          placeholder="请选择业务员"
          style="width: 140px"
        >
          <el-option
            v-for="item in staffList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="model_id">
        <el-select
          v-model="form.model_id"
          clearable
          style="width: 140px"
          placeholder="选择陈列活动"
        >
          <el-option
            v-for="(i, idx) in activeSelect"
            :key="idx"
            :value="i.model_id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="step_status">
        <el-select
          v-model="form.step_status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="time">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      @selection-change="handlerSelection"
    >
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '陈列天数'">{{ row[item.prop] }}天</div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="操作" min-width="180px">
        <template #default="{ $index, row }">
          <el-button
            v-if="row.step_status != 20"
            v-show="row.step_status != 15"
            type="text"
            @click.native.prevent="handleEdit($index, row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handlePrint($index, row)"
          >
            打印
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleExport($index, row)"
          >
            导出
          </el-button>
          <el-button
            v-if="row.step_status != 20"
            v-show="row.step_status != 15"
            type="text"
            @click.native.prevent="handleEnd($index, row)"
          >
            终止
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <el-row type="flex" class="row-bg">
      <el-button type="primary" @click="handlerBatchChangeStaff">
        更换业务员
      </el-button>
    </el-row>
    <check ref="check"></check>
    <ChangeStaff ref="changeStaff" @refresh="fetchData"></ChangeStaff>

    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="CL"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getStaffList } from '@/api/setPrice'
  import clientSearch from '@/baseComponents/clientSearch'
  import { activeList, stopPayList } from '@/api/payListErr'
  import { statusList, getList } from '@/api/payListQuery'
  import Check from './components/check'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import ChangeStaff from '@/views/project/finance/client/arrears/components/changeStaff.vue'
  import { postAction } from '@/api/Employee'

  export default {
    name: 'ListQuery',
    components: { ChangeStaff, clientSearch, Check, RuilangDisplayDialog },
    data() {
      return {
        sum: [],
        loading: false,
        time: [],
        staffList: [],
        activeSelect: [],
        statusSelect: [],
        data_id: '',
        form: {
          pageNo: 1, //
          pageSize: 10, //
          sort: '', //
          order: '', //
          staff_id: '', //业务员ID
          model_id: '', //陈列活动ID
          step_status: '', //状态
          cust_id: '',
          keyword: '', //店铺名称
          start_time: '',
          end_time: '',
        },
        checkList: [
          '单号',
          '客户名称',
          '业务员',
          '陈列活动',
          '协议状态',
          '陈列开始时间',
          '陈列结束时间',
          '陈列天数',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'no',
            width: '',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '业务员',
            prop: 'staff_name',
            width: '',
          },
          {
            order: 0,
            label: '陈列活动',
            prop: 'display_name',
            width: '',
          },
          {
            order: 9,
            label: '协议状态',
            prop: 'step_status_text',
            width: '',
          },
          {
            order: 6,
            label: '陈列开始时间',
            prop: 'start_time_text',
            width: '',
          },
          {
            order: 7,
            label: '陈列结束时间',
            prop: 'end_time_text',
            width: '',
          },
          {
            order: 8,
            label: '陈列天数',
            prop: 'show_day',
            width: '',
          },
        ],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        list: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
      activeList().then((res) => {
        if (res.code == 200) this.activeSelect = res.data
      })
      statusList().then((res) => {
        if (res.code == 200) this.statusSelect = res.data.step_status
      })
    },
    mounted() {},
    methods: {
      handlerSelection(val) {
        console.log(val, '选完后的')
        this.sum = val
      },
      handlerBatchChangeStaff() {
        if (this.sum.length > 0) {
          let ids = []
          this.sum.forEach((i) => {
            if (i.d_cust_id) ids.push(i.d_cust_id)
          })
          this.$refs.changeStaff.type = 4
          this.$refs.changeStaff.ids = ids
          this.$refs.changeStaff.showDialog = true
        } else {
          this.$message({
            message: '请勾选单据',
            type: 'info',
          })
        }
      },
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await getList(this.form)
        console.log(data)
        this.list = data
        this.list.forEach((item) => {
          item.cust_name = item.customer_info.cust_name
        })
        this.total = totalCount
        this.loading = false
      },
      clientId(val) {
        this.form.cust_id = val
      },
      resetForm() {
        this.$refs.form.resetFields()
        this.form.cust_id = ''
        this.$refs.clientSearch.resetForm()
        this.time = []
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },

      handleEdit(index, row) {
        this.$refs.check.id = row.d_cust_id
        this.$refs.check.view = false
        this.$refs.check.title = '修改付费陈列协议'
        this.$refs.check.showDialog = true
      },
      handleCheck(index, row) {
        this.$refs.check.id = row.d_cust_id
        this.$refs.check.view = true
        this.$refs.check.title = '查看付费陈列协议'
        this.$refs.check.showDialog = true
      },
      handlePrint(index, row) {
        this.data_id = row.d_cust_id
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
      handleExport(index, row) {},
      handleEnd(index, row) {
        stopPayList({
          d_cust_id: row.d_cust_id,
        }).then((res) => {
          this.$message.success(res.msg)
          this.fetchData()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
