<template>
  <div class="change-log-container">
    <el-alert
      :closable="false"
      title="由于未购买用户未加入github团队无法访问github更新日志页面，故该页面使用录屏代替，不代表最新更新进度， 已购买用户请访问https://github.com/vue-admin-beautiful/vue-admin-beautiful-pro/releases获取更新日志"
      show-icon
      type="success"
    >
      <el-tag type="success">最近一次部署时间:{{ updateTime }}</el-tag>
    </el-alert>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="19">
        <vab-player-mp4 :config="config1" @player="Player1 = $event" />
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import { VabPlayerMp4 } from '@/extra/vabPlayer.js'
  import { dependencies, devDependencies } from '*/package.json'

  export default {
    components: {
      VabPlayerMp4,
    },
    data() {
      return {
        updateTime: process.env.VUE_APP_UPDATE_TIME,
        config1: {
          id: 'change-log',
          url: 'https://cdn.jsdelivr.net/gh/chuzhixin/videos@master/changeLog.mp4',
          volume: 1,
          autoplay: true,
          screenShot: false,
        },
        Player1: null,
      }
    },
  }
</script>
