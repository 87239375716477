<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :title="title"
    top="5vh"
    :visible.sync="showDialog"
    width="95%"
    @close="close"
  >
    <div v-loading="showLoading" class="wrapper">
      <el-tabs v-if="loadBaseOK" v-model="activeName">
        <el-tab-pane label="基础信息" name="first">
          <base-info
            ref="baseInfo"
            :showdialog.sync="showDialog"
            :alldata.sync="alldata"
            :is-edit="isEdit == 0 ? true : false"
            :tit="title"
          ></base-info>
        </el-tab-pane>
        <el-tab-pane label="尺寸重量产地" name="second">
          <size-place
            ref="sizePlace"
            :showdialog.sync="showDialog"
            :alldata.sync="alldata"
            :is-edit="isEdit == 0 ? true : false"
            @refresh="fetchData"
          ></size-place>
        </el-tab-pane>
        <el-tab-pane label="图文信息" name="third">
          <pic-info
            ref="picInfo"
            :showdialog.sync="showDialog"
            :alldata.sync="alldata"
            :is-edit="isEdit == 0 ? true : false"
          ></pic-info>
        </el-tab-pane>
        <el-tab-pane label="多口味" name="fourth">
          <more-info
            ref="moreInfo"
            :showdialog.sync="showDialog"
            :alldata.sync="alldata"
            :is-edit="isEdit == 0 ? true : false"
          ></more-info>
        </el-tab-pane>
      </el-tabs>
      <!-- 底部按钮 -->
      <div
        v-if="alldata.info && title == '修改商品'"
        slot="footer"
        class="dialog-footer"
        style="margin-top: 10px"
      >
        <div>
          <el-button type="warning" @click="getChangeTime">
            更新商品价格
          </el-button>
          更新时间:
          <span>{{ alldata.info.price_change_time }}</span>
        </div>
        <div>
          <el-button v-if="isEdit" type="primary" @click="save">保存</el-button>
          <el-button @click="close">关闭</el-button>
        </div>
      </div>
      <div v-else style="margin-top: 5px; text-align: right">
        <el-button v-if="isEdit" type="primary" @click="save">保存</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
      <div v-if="title == '修改商品'" style="margin-top: 5px">
        说明:商品调价后请点此按钮,开单时即可执行新价格.
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import BaseInfo from './baseInfo'
  import SizePlace from './sizePlace'
  import PicInfo from './picInfo'
  import MoreInfo from './moreInfo'
  import { getGoodsDetail, editGoods } from '@/api/goodsDetail'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'TableEdit',
    components: {
      BaseInfo,
      SizePlace,
      PicInfo,
      MoreInfo,
    },
    data() {
      return {
        isEdit: 1,
        changeTime: '',
        showLoading: false,
        id: 0,
        loadBaseOK: false,
        alldata: {},
        activeName: 'first',
        showDialog: false,
        title: '标题',
      }
    },
    computed: {
      // title() {
      //   if (this.isEdit == 1) {
      //     return '修改商品'
      //   } else {
      //     return '添加商品'
      //   }
      // },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.showLoading = true
          this.fetchData({ id: this.id })
        } else {
          this.activeName = 'first'
          this.loadBaseOK = false
        }
      },
    },
    methods: {
      getChangeTime() {
        postAction('/baseAdmin/goods/change-price', {
          goods_id: this.id,
        }).then((res) => {
          this.alldata.info.price_change_time = res.data
          this.$message.success('更新成功')
        })
      },
      async fetchData() {
        console.log('id', this.id)
        let { data } = await getGoodsDetail({ id: this.id })
        console.log('edit')
        console.log(data.info.brand_id)
        if (data.info) {
          this.alldata = data
          if (this.alldata.info.brand_id == 0) {
            this.alldata.info.brand_id = ''
          }
          if (this.alldata.info.class_id == 0) {
            this.alldata.info.class_id = ''
          }
          if (this.alldata.info.expire_day_stock == 0) {
            this.alldata.info.expire_day_stock = ''
          }
          if (this.alldata.info.expire_day == 0) {
            this.alldata.info.expire_day = ''
          }
          this.loadBaseOK = true
          this.showLoading = false
        } else {
          this.showLoading = false
          console.log('data不是对象')
        }
        console.log(data.info.brand_id)
      },
      close() {
        this.showDialog = false
      },
      // 处理baseInfo 的通路
      pathR(pathArr) {
        if (pathArr.length == 0) {
          return 0
        }
        if (pathArr.length == 2) {
          return 1
        } else {
          return pathArr[0] == '网销' ? 3 : 2
        }
      },
      // 处理中间多选框
      middleCheckR(show) {
        if (!show) {
          return 1
        } else {
          return 2
        }
      },
      save() {
        console.log('baocun')
        this.showLoading = true
        let baseRef = this.$refs['baseInfo']
        console.log(baseRef, 'baseRef')
        baseRef.mineData.info.expire_day_text =
          baseRef.expire_day +
          '年' +
          baseRef.expire_day1 +
          '月' +
          baseRef.expire_day2 +
          '天'
        let sizeRef = this.$refs['sizeInfo']
        let picRef = this.$refs['picInfo']
        let moreRef = this.$refs['moreInfo']

        // 处理通路
        this.alldata.info.sale_type = this.pathR(baseRef.path)
        console.log(this.alldata.info.sale_type)

        // 处理中间多选框
        this.alldata.info.is_max_price = baseRef.middleCheck.a
        this.alldata.info.must_choose_date = baseRef.middleCheck.b

        this.alldata.info.sell_price_change = baseRef.middleCheck.c
        this.alldata.info.return_price_change = baseRef.middleCheck.d

        let endInfo = this.alldata.info
        // 获取表格数据
        let baseTableData = baseRef.tableData
        console.log('表格数据')
        console.log(baseTableData)
        // this.alldata.info.units = baseTableData
        if (baseTableData[0])
          Object.assign(this.alldata.info.units.unit1, baseTableData[0])
        if (baseTableData[2])
          Object.assign(this.alldata.info.units.unit2, baseTableData[2])
        if (baseTableData[1])
          Object.assign(this.alldata.info.units.unit3, baseTableData[1])
        console.log(this.alldata.info.units)
        let endUnit = JSON.parse(
          JSON.stringify([
            this.alldata.info.units.unit1,
            this.alldata.info.units.unit2,
            this.alldata.info.units.unit3,
          ])
        )
        // endUnit.forEach((item) => {
        //   return item.is_default == true
        //     ? (item.is_default = 2)
        //     : (item.is_default = 1)
        // })
        // endUnit[0].isDefault =
        //   this.alldata.info.units.unit1.is_default == true ? 2 : 1
        // endUnit[1].isDefault =
        //   this.alldata.info.units.unit2.is_default == true ? 2 : 1
        // endUnit[2].isDefault =
        //   this.alldata.info.units.unit3.is_default == true ? 2 : 1
        // this.alldata.info.units.unit1.is_default =
        //   this.alldata.info.units.unit1.is_default == true ? 2 : 1
        // this.alldata.info.units.unit2.is_default =
        //   this.alldata.info.units.unit2.is_default == true ? 2 : 1
        // this.alldata.info.units.unit3.is_default =
        //   this.alldata.info.units.unit3.is_default == true ? 2 : 1
        console.log('unit1默认', this.alldata.info.units.unit1.is_default)
        console.log('unit2默认', this.alldata.info.units.unit2.is_default)
        console.log('unit3默认', this.alldata.info.units.unit3.is_default)

        // 获取editor内容
        picRef.getEditorData()

        // 获取picData
        let endImg = picRef.fileList
        if (endImg.length !== 0) {
          endImg.forEach((item) => {
            if (item.type.indexOf('image/') == 0) {
              item.type = 'img'
            } else {
            }
          })
        }

        // 获取more 表格数据
        let moreTableData = moreRef.tableData

        // 更多口味单选框
        let moreChecked = moreRef.checked == true ? 2 : 1
        let attrbooler = true
        console.log('多口味的选项', moreChecked, moreRef.checked, moreTableData)
        if (moreChecked == 2) {
          console.log('多口味的选项2', moreTableData)
          if (moreTableData.length < 1) {
            console.log('多口味的选项2')
            this.$message.error('请取消多口味选项或按规范补充完整多口味选项')
            return
          } else {
            moreTableData.forEach((list) => {
              if (list.attr != '') {
              } else {
                this.$message.error(
                  '请取消多口味选项或按规范补充完整多口味选项'
                )
                attrbooler = false
                return
              }
            })
          }
        }
        if (!attrbooler) {
          this.showLoading = false
          return
        }
        let endData = {
          info: endInfo,
          unit: endUnit,
          imgs: endImg,
          child: moreTableData,
        }
        console.log('endUnitendUnitendUnit', endData.unit)

        endData.info.is_child_Indep = moreChecked
        console.log('助记码')
        console.log(endData.info.easy_code)

        console.log('编码')
        console.log(endData.info.goods_code)

        endData.info = JSON.stringify(endData.info)
        endData.unit = JSON.stringify(endData.unit)
        endData.imgs = JSON.stringify(endData.imgs)
        endData.child = JSON.stringify(endData.child)
        // 验证表单
        baseRef.checkForm()
        if (baseRef.formgo) {
          console.log(endData,"提交的数据")
          // this.showLoading = true

          // 提交修改接口
          editGoods(endData)
            .then((res) => {
              console.log(res)
              this.showLoading = false
              if (res.code !== 200) {
                this.$message.error(res.msg)
              } else {
                this.$message.success(res.msg)
                this.showDialog = false
                // this.fetchData()
                this.$emit('refresh')
              }
            })
            .catch((err) => {
              console.log(err)
              this.showLoading = false
            })
        } else {
          console.log('baseInfo form err')
          this.$message.error('请输入表单必填内容')
          this.showLoading = false
        }
        // 富文本编辑器的内容
        // console.log(this.$refs['picInfo'].editor.txt.html())
      },
      refreshPrice() {},
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-height: 500px;
  }
  .nav {
    line-height: 30px;
    text-align: center;

    div {
      cursor: pointer;
    }

    .active {
      color: #fff;
      background-color: #409eff;
    }
  }
  .nav-item {
    box-sizing: border-box;
    border: 1px solid #409eff;
    border-radius: 4px;
  }
  .dialog-footer {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: space-between;
  }
</style>
