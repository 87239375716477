<!--
 * @Author: 候怀烨
 * @Date: 2020-12-25 15:02:58
 * @LastEditTime: 2021-05-14 14:57:14
 * @LastEditors: Please set LastEditors
 * @Description: 下单
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositreceipt\components\components\PlaceAnOrder.vue
-->
<template>
  <div>
    <el-dialog
      fullscreen
      :modal="false"
      title="预存款下单"
      center
      :visible.sync="innerVisible"
    >
      <div>
        <el-form ref="form" inline :model="form" label-width="100px">
          <el-form-item label="购货方：">
            <el-input
              v-model="form.cust_name"
              disabled
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人：">
            <el-input
              v-model="form.boss"
              disabled
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址：">
            <el-input
              v-model="form.address"
              style="width: 470px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="出货仓库：">
            <el-select
              v-model="orderData.order_info.depot_id"
              style="width: 180px"
              placeholder="请选择出货仓库"
              @change="handlerchange"
            >
              <el-option
                v-for="(item, index) in depot.depot"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务员：">
            <el-select
              v-model="orderData.order_info.staff_id"
              style="width: 180px"
              placeholder="请选择业务员"
            >
              <el-option
                v-for="(lists, index) in depot.staff"
                :key="index"
                :label="lists.name"
                :value="lists.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="送货人：">
            <el-select
              v-model="orderData.order_info.deliver_id"
              style="width: 180px"
              placeholder="请选择送货人"
            >
              <el-option
                v-for="(listed, index) in DeliveryMan"
                :key="index"
                :label="listed.name"
                :value="listed.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="退货方式 ：">
            <el-select
              v-model="orderData.order_info.refund_type"
              style="width: 180px"
              placeholder="请选择退货方式"
            >
              <el-option
                v-for="(listed, index) in refund"
                :key="index"
                :label="listed.name"
                :value="listed.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="商品条码：">
            <el-input style="width: 470px"></el-input>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <el-table
          ref="tableSort"
          v-loading="listLoading"
          stripe
          :data="tadlelist"
          height="360px"
        >
          <!-- 序号 -->
          <el-table-column type="index" align="center">
            <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="index"
                    :label="item.label"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <!-- 类型 -->
          <el-table-column
            v-for="(item, index) in columns"
            :key="index"
            :prop="item.prop"
            :align="item.align"
            :label="item.label"
            :width="item.width"
          >
            <template v-if="item.label == '类型'" #default="{ row }">
              <div v-if="row.edit == false">协议赠品</div>
              <div v-else>
                <el-select v-model="row.goods_type" placeholder="选择类型">
                  <el-option
                    v-for="item_ in goodsTypeSelect"
                    :key="item_.id"
                    :label="item_.name"
                    :value="item_.id"
                  ></el-option>
                </el-select>
              </div>
            </template>
            <template
              v-else-if="item.label == '商品名称'"
              #default="{ row, $index }"
            >
              <div v-if="row.edit == false">
                {{ row.goods_name }}
              </div>
              <div v-else>
                <goods-search
                  :depot-id="String(orderData.order_info.depot_id)"
                  :f-key="row.goods_name"
                  :cust-id="form.cust_id"
                  :goods-id="row.goods_id"
                  :kouwei="row.child"
                  :rowf="row"
                  :goods-list-obj="{ promote_type: 1, order_id: order_id }"
                  @change-kouwei="changeKouwei($event, row)"
                  @add-rows="addRows"
                  @select-goods-all="selectGoods($event, row, $index)"
                ></goods-search>
              </div>
            </template>
            <template v-else-if="item.label == '单位'" #default="{ row }">
              <el-select
                v-model="row.unit_id"
                placeholder="选择单位"
                @change="unitChange($event, row)"
              >
                <el-option
                  v-for="item_ in row.arr_unit"
                  :key="item_.unit_id"
                  :label="item_.unit_name"
                  :value="item_.id"
                ></el-option>
              </el-select>
            </template>
            <template v-else-if="item.label == '单价'" #default="{ row }">
              <div v-if="row.edit == false">0</div>
              <div v-else>
                <el-input
                  v-model="row.goods_price"
                  placeholder="单价"
                ></el-input>
              </div>
            </template>
            <template v-else-if="item.label == '下单数量'" #default="{ row }">
              <el-input
                v-model="row.goods_num"
                placeholder="下单数量"
              ></el-input>
            </template>
            <template v-else-if="item.label == '金额'" #default="{ row }">
              <div
                v-if="
                  row.goods_type == 132 ||
                  row.goods_type == 2 ||
                  row.goods_type == 3
                "
              >
                0
              </div>
              <div v-else>
                {{
                  row.edit == false
                    ? 0
                    : Number(row.goods_num) * Number(row.goods_price)
                }}
              </div>
            </template>
            <template v-else-if="item.label == '生产日期'" #default="{ row }">
              <el-date-picker
                v-model="row.goods_production_date"
                value-format="yyyy-MM-dd"
                style="width: 130px"
              ></el-date-picker>
            </template>
            <template v-else-if="item.label == '备注'" #default="{ row }">
              <el-input
                v-model="row.remark"
                placeholder="请输入备注"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="110">
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="copyRow($index, row)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="deleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 表格结束 -->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" :disabled="isCreating" @click="handleraddtus(3)">
          创建仓库直销单
        </el-button>
        <el-button type="warning" :disabled="isCreating" @click="handleraddtus(2)">
          创建访销单并调拨
        </el-button>
        <!-- TODO[epic=预存款] 控制显示隐藏 -->
        <el-button
          v-if="form.allot_button == 1"
          type="primary"
          @click="handleraddtus(1)"
          :disabled="isCreating"
        >
          仅创建访销单
        </el-button>
        <el-button @click="innerVisible = !innerVisible">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import _ from 'lodash'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      GoodsSearch,
    },
    data() {
      return {
        isCreating: false,
        innerVisible: false,
        url: {
          select: '/saleAdmin/order-pre/create-option',
          songhuoren: '/baseAdmin/common/deliver-option',
          data: '/promoteAdmin/deposit-order/return-info',
          shman: '/baseAdmin/common/deliver-option',
          submit: '/deposit-order/return-submit',
          xiadan: '/promoteAdmin/deposit-order/return-submit',
        },
        depot: [],
        form: {},
        orderData: {
          order_id: '',
          cust_id: '',
          order_info: {
            depot_id: '',
            deliver_id: '',
            discounted_amount: '',
            remark: '',
            refund_type: '',
          },
          goods_info: [],
          submit_type: '',
        },
        refund: [
          {
            id: 1,
            name: '现金',
          },
          {
            id: 2,
            name: '转存货',
          },
          {
            id: 3,
            name: '抵欠款',
          },
        ],
        DeliveryMan: [],
        goodsTypeSelect: [
          // 1主品2协议赠品131退预存尝品132退预存赠品
          {
            id: 1,
            name: '预存款',
          },
          {
            id: 3,
            name: '赠品',
          },
          {
            id: 2,
            name: '协议赠品',
          },
          {
            id: 131,
            name: '退预存常品',
          },
          {
            id: 132,
            name: '退预存赠品',
          },
        ],
        tadlelist: [
          {
            activity_count: 0,
            activity_id: 0,
            arr_goods_type: [],
            arr_unit: [],
            balance_count: 0,
            brand_id: '',
            brand_name: '',
            class_name: '',
            depot_stock_num: '',
            depot_stock_num_read: '',
            goods_child_id: '',
            goods_id: '',
            goods_money: '',
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            goods_num: '',
            remark: '',
            id: '',
            info: '',
            is_close_text: '',
            is_img: '',
            quantity: '',
            sale_type_text: '',
            specs: '',
            unit_cv: '',
            unit_id: '',
            unit_name: '',
          },
        ],
        listLoading: false,
        // 表格
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '单位',
          '单价',
          '下单数量',
          '金额',
          '生产日期',
          '备注',
          '剩余可用',
          '库存',
        ],
        columns: [
          {
            order: 1,
            label: '类型',
            width: '120',
            prop: '',
            align: 'center',
            sortable: false,
          },
          {
            order: 2,
            label: '商品名称',
            width: '220',
            prop: 'goods_name',
            align: 'center',
            sortable: false,
          },
          {
            order: 2.1,
            label: '规格',
            width: '110',
            prop: 'goods_specs',
            align: 'center',
            sortable: false,
          },
          {
            order: 3,
            label: '单位',
            width: '110', //'110',
            prop: 'units',
            align: 'center',
            sortable: false,
          },
          {
            order: 4,
            label: '单价',
            width: '110',
            prop: 'goods_price',
            align: 'center',
            sortable: false,
          },
          {
            order: 5,
            label: '下单数量',
            width: '110',
            prop: 'quantity',
            align: 'center',
            sortable: false,
          },
          // {
          //   order: 6,
          //   label: '库存',
          //   width: '100',
          //   prop: 'depot_stock_num',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            order: 6,
            label: '金额',
            width: '90',
            prop: 'sale_amount',
            align: 'center',
            sortable: false,
          },
          {
            order: 7,
            label: '生产日期',
            width: '180',
            prop: 'production_date',
            align: 'center',
            sortable: false,
          },
          {
            order: 8,
            label: '备注',
            width: '210',
            prop: 'remark',
            align: 'center',
            sortable: false,
          },
          {
            order: 9,
            label: '剩余可用',
            width: '100',
            prop: 'goods_num_left',
            align: 'center',
            sortable: false,
          },
          {
            order: 10,
            label: '库存',
            width: '100',
            prop: 'balance_count_read',
            align: 'center',
            sortable: false,
          },
        ],
        order_id: '',
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      innerVisible(val) {
        if (!val) {
          this.orderData = {
            order_id: '',
            cust_id: '',
            order_info: {
              depot_id: '',
              deliver_id: '',
              discounted_amount: '',
              remark: '',
              refund_type: '',
            },
            goods_info: [],
            submit_type: '',
          }
          this.tadlelist = [
            {
              activity_count: 0,
              activity_id: 0,
              arr_goods_type: [],
              arr_unit: [],
              balance_count: 0,
              brand_id: '',
              brand_name: '',
              class_name: '',
              depot_stock_num: '',
              depot_stock_num_read: '',
              goods_child_id: '',
              goods_id: '',
              goods_money: '',
              goods_name: '',
              goods_price: '',
              goods_production_date: '',
              goods_type: '',
              goods_num: '',
              remark: '',
              id: '',
              info: '',
              is_close_text: '',
              is_img: '',
              quantity: '',
              sale_type_text: '',
              specs: '',
              unit_cv: '',
              unit_id: '',
              unit_name: '',
            },
          ]
        }
      },
    },
    mounted() {
      this.handlerselect()
    },
    methods: {
      //仅添加
      handleradd() {
        var data = new Object()
        data = JSON.parse(JSON.stringify(this.orderData))
        data.submit_type = 1
        this.tadlelist.forEach((list) => {
          var goods_info = {
            goods_id: list.goods_id,
            unit_id: list.unit_id,
            goods_num: list.goods_num,
            price: list.goods_price,
            goods_money: Number(list.goods_price) * Number(list.goods_num),
            type: list.goods_type,
            remark: list.remark,
          }
          data.goods_info.push(goods_info)
        })
        let goods = []
        goods = data.goods_info.filter((item) => item.goods_id != '')
        data.goods_info = goods
        data.order_id = this.order_id
        postAction(this.url.xiadan, data)
          .then((res) => {
            console.log(res, '')
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.$emit('getlist')
            this.innerVisible = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //添加生成调拨单
     async handleraddtus(type_) {
        if(this.isCreating) return;
        this.isCreating = true;
        try {


          var data = new Object()
          data = JSON.parse(JSON.stringify(this.orderData))
          data.submit_type = type_
          this.tadlelist.forEach((list) => {
            var goods_info = {
              goods_id: list.goods_id,
              goods_child_id: list.goods_child_id,
              goods_name: list.goods_name,
              unit_id: list.unit_id,
              goods_num: list.goods_num,
              price: list.goods_price,
              goods_money: Number(list.goods_price) * Number(list.goods_num),
              type: list.goods_type,
              remark: list.remark,
            }
            data.goods_info.push(goods_info)
          })
          let goods = []
          goods = data.goods_info.filter((item) => item.goods_id != '')
          data.goods_info = goods
          console.log('data数据', data.goods_info)
          data.order_id = this.order_id
          data.staff_id = this.orderData.order_info.staff_id
          await postAction(this.url.xiadan, data)
            .then((res) => {
              console.log(res, '')
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.$emit('getlist')
              this.innerVisible = false
            })
            .catch((err) => {
              console.log(err)
            })
        } catch (error) {
          console.log(error);
        }finally {
          this.isCreating = false;
        }
      },
      //仓库改变
      handlerchange(id) {
        postAction(this.url.shman, { depot_id: id })
          .then((res) => {
            console.log(res, '仓库')
            this.DeliveryMan = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //数据
      handlerData(id) {
        this.order_id = id
        postAction(this.url.data, { id: id })
          .then((res) => {
            console.log(res, '下单详情')
            this.form = res.data
            let giveGoods = res.data.give_data
            giveGoods.forEach((i) => {
              this.tadlelist.unshift({
                ...i,
                ...{
                  goods_type: 2,
                  unit_id: i.unit_list[0].id,
                  arr_unit: i.unit_list,
                  goods_price: 0,
                  edit: false,
                },
              })
            })
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //下拉框
      handlerselect() {
        postAction(this.url.select, {})
          .then((res) => {
            console.log(res, 'select')
            this.depot = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      selectGoods(event, row, index) {
        if (event.goods_id > 0) {
          console.log(event, row, '1')
          Object.assign(row, event)
          // row.goods_num = ''
          // row.remark = ''
          row.goods_type = 1
          this.addRow()
        }
      },
      addRow() {
        this.tadlelist.push({
          activity_count: 0,
          activity_id: 0,
          arr_goods_type: [],
          arr_unit: [],
          balance_count: 0,
          brand_id: '',
          brand_name: '',
          class_name: '',
          depot_stock_num: '',
          depot_stock_num_read: '',
          goods_child_id: '',
          goods_id: '',
          goods_money: '',
          goods_name: '',
          goods_price: '',
          goods_production_date: '',
          goods_type: '',
          goods_num: '',
          remark: '',
          id: '',
          info: '',
          is_close_text: '',
          is_img: '',
          quantity: '',
          sale_type_text: '',
          specs: '',
          unit_cv: '',
          unit_id: '',
          unit_name: '',
        })
      },
      addRows(val) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length != 0) {
          val.forEach((item) => {
            // item.goods_num = ''
            // item.remark = ''
            this.$set(item, 'goods_num', '')
            this.$set(item, 'remark', '')
            this.$set(item, 'goods_type', item.arr_goods_type[0].id)
            // item.goods_type = item.arr_goods_type[0].id
            // this.tadlelist.splice(this.tadlelist.length - 1, 0, item)
            this.tadlelist.unshift(item)
          })
          console.log(this.tadlelist, '数组')
          this.$message.success('添加成功')
        }
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        let id = row.arr_unit.filter((item) => item.id == e)[0].id
        row.goods_price = price
        row.unit_name = name
        row.unit_id = id
      },

      copyRow(index, row) {
        let rowS = JSON.parse(JSON.stringify(row))
        this.tadlelist.splice(index, 0, rowS)
      },
      deleteRow(index, row) {
        if (this.tadlelist.length == 1) {
          this.$message.error('不能删除最后一条数据')
        } else {
          this.tadlelist.splice(index, 1)
        }
      },
      handleAddDepot() {},
      changeKouwei(val, row) {
        console.log('改变口味', val)
        row.goods_child_id = val.id
        row.goods_name = val.goods_name + ` (${val.attr})`
      },
    },
  }
</script>

<style></style>
