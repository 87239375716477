var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "div",
        { staticClass: "topInput" },
        [
          _c(
            "el-select",
            {
              attrs: {
                "popper-class": "select-idx",
                filterable: "",
                clearable: "",
                placeholder: "选择出货仓库",
              },
              model: {
                value: _vm.queryForm.depot_id,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "depot_id", $$v)
                },
                expression: "queryForm.depot_id",
              },
            },
            _vm._l(_vm.depotList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: "",
                multiple: "",
                "collapse-tags": true,
                "popper-class": "select-idx",
                placeholder: "选择业务员",
              },
              model: {
                value: _vm.queryForm.staff_id,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "staff_id", $$v)
                },
                expression: "queryForm.staff_id",
              },
            },
            _vm._l(_vm.saleManList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: "",
                "popper-class": "select-idx",
                placeholder: "选择订单状态",
              },
              model: {
                value: _vm.queryForm.bill_status,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "bill_status", $$v)
                },
                expression: "queryForm.bill_status",
              },
            },
            _vm._l(_vm.bill_status, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: "",
                "popper-class": "select-idx",
                placeholder: "调拨状态",
              },
              model: {
                value: _vm.queryForm.order_type,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "order_type", $$v)
                },
                expression: "queryForm.order_type",
              },
            },
            _vm._l(_vm.order_type, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                clearable: "",
                "popper-class": "select-idx",
                placeholder: "下单时间",
              },
              model: {
                value: _vm.queryForm.time_type,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "time_type", $$v)
                },
                expression: "queryForm.time_type",
              },
            },
            _vm._l(_vm.orderTimeList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content:
                  "默认显示6个月内的订单，可点击时间框修改起止时间，查询其他时段订单",
                placement: "top-start",
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "popper-class": "select-idx",
                  type: "datetimerange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"],
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.queryForm.time,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "time", $$v)
                  },
                  expression: "queryForm.time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "请选择线路" },
              model: {
                value: _vm.queryForm.line_id,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "line_id", $$v)
                },
                expression: "queryForm.line_id",
              },
            },
            _vm._l(_vm.lineSelect, function (i, idx) {
              return _c("el-option", {
                key: idx,
                attrs: { value: i.id, label: i.line_name },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "more" },
        [
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "更多筛选条件", name: "1" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "140px", margin: "5px" },
                    attrs: {
                      options: _vm.areaList,
                      placeholder: "选择区域",
                      props: {
                        checkStrictly: true,
                        value: "id",
                        label: "name",
                        emitPath: false,
                      },
                      clearable: "",
                    },
                    model: {
                      value: _vm.queryForm.cust_area_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "cust_area_id", $$v)
                      },
                      expression: "queryForm.cust_area_id",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "选择渠道类型",
                        "popper-class": "select-idx",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.cust_channel_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "cust_channel_id", $$v)
                        },
                        expression: "queryForm.cust_channel_id",
                      },
                    },
                    _vm._l(_vm.cust_channel, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "客户等级",
                        "popper-class": "select-idx",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.cust_class_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "cust_class_id", $$v)
                        },
                        expression: "queryForm.cust_class_id",
                      },
                    },
                    _vm._l(_vm.cust_class, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "司机",
                        filterable: "",
                        "popper-class": "select-idx",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.deliver_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "deliver_id", $$v)
                        },
                        expression: "queryForm.deliver_id",
                      },
                    },
                    _vm._l(_vm.deliver, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "来源",
                        "popper-class": "select-idx",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.bill_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "bill_type", $$v)
                        },
                        expression: "queryForm.bill_type",
                      },
                    },
                    _vm._l(_vm.bill_type, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c("span", { staticStyle: { margin: "0 5px" } }, [
                    _vm._v("生产日期:"),
                  ]),
                  _c("el-date-picker", {
                    staticStyle: { width: "340px", margin: "5px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.time2,
                      callback: function ($$v) {
                        _vm.time2 = $$v
                      },
                      expression: "time2",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "是否结清",
                        "popper-class": "select-idx",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.pay_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "pay_status", $$v)
                        },
                        expression: "queryForm.pay_status",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "未结清", value: "0" },
                      }),
                      _c("el-option", {
                        attrs: { label: "已结清", value: "1" },
                      }),
                      _c("el-option", { attrs: { label: "坏账", value: "2" } }),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px", margin: "5px" },
                      attrs: {
                        placeholder: "客户签名",
                        "popper-class": "select-idx",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.has_sign,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "has_sign", $$v)
                        },
                        expression: "queryForm.has_sign",
                      },
                    },
                    _vm._l(_vm.hasSignSelect, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "table-wrap" }, [
        _c(
          "div",
          { staticClass: "table-container" },
          [
            _c(
              "vab-query-form",
              [
                _c(
                  "vab-query-form-top-panel",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          inline: true,
                          model: _vm.queryForm,
                          "label-width": "80px",
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("client-search", {
                              attrs: { "popper-class": "select-idx selectDC" },
                              on: {
                                "select-id": _vm.selectId,
                                "select-val-input": _vm.custInput,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "brand" } },
                          [
                            _c("brand-select", {
                              ref: "brandSelect",
                              staticStyle: { width: "220px" },
                              attrs: {
                                duoxuan: true,
                                tags: true,
                                "popper-class": "select-idx",
                              },
                              on: { "brand-select-change": _vm.chooseBrand },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "商品名称", clearable: "" },
                              model: {
                                value: _vm.queryForm.keyword_goods,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "keyword_goods", $$v)
                                },
                                expression: "queryForm.keyword_goods",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "单号或单据备注",
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "remark", $$v)
                                },
                                expression: "queryForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "140px" },
                                attrs: {
                                  placeholder: "结算方式",
                                  "popper-class": "select-idx",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryForm.account_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "account_type", $$v)
                                  },
                                  expression: "queryForm.account_type",
                                },
                              },
                              _vm._l(_vm.account_type, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "140px" },
                                attrs: {
                                  placeholder: "销售类型",
                                  "popper-class": "select-idx",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryForm.sale_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "sale_type", $$v)
                                  },
                                  expression: "queryForm.sale_type",
                                },
                              },
                              _vm._l(_vm.sale_type, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "140px" },
                                attrs: {
                                  placeholder: "打印状态",
                                  "popper-class": "select-idx",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryForm.print,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "print", $$v)
                                  },
                                  expression: "queryForm.print",
                                },
                              },
                              _vm._l(_vm.print, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-search",
                                  "native-type": "submit",
                                  type: "primary",
                                },
                                on: { click: _vm.btnQuery },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "warning" },
                                on: { click: _vm.btnSummaryOrder },
                              },
                              [_vm._v(" 汇总派单 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success" },
                                on: { click: _vm.handleBatchArrival },
                              },
                              [_vm._v(" 批量到货 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "40px" },
                                attrs: { type: "primary" },
                                on: { click: _vm.btnAddOrder },
                              },
                              [_vm._v(" 添加订单 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "40px" },
                                attrs: { type: "warning" },
                                on: { click: _vm.handleAddDuijiang },
                              },
                              [_vm._v(" 添加兑奖 ")]
                            ),
                            _c("el-button", { on: { click: _vm.btnExpotr } }, [
                              _vm._v("导出"),
                            ]),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function () {
                                    ;(_vm.$refs.upload.showDialog = true),
                                      (_vm.$refs.upload.data.type = 18)
                                  },
                                },
                              },
                              [_vm._v(" 模板导入 ")]
                            ),
                            _c("upload-exel", { ref: "upload" }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                ref: "tableSort",
                attrs: {
                  stripe: "",
                  "max-height": "800",
                  border: "",
                  "show-summary": "",
                  "summary-method": _vm.getSummaries,
                  "row-key": _vm.getRowKey,
                  data: _vm.list,
                },
                on: { "selection-change": _vm.setSelectRows },
              },
              [
                _c(
                  "el-table-column",
                  { attrs: { align: "center", type: "index", width: "50" } },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              "popper-class": "custom-table-checkbox",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.checkList,
                                  callback: function ($$v) {
                                    _vm.checkList = $$v
                                  },
                                  expression: "checkList",
                                },
                              },
                              _vm._l(_vm.columns, function (item, index) {
                                return _c("el-checkbox", {
                                  key: index,
                                  attrs: { label: item.label },
                                  on: { change: _vm.filterChcekboxChange },
                                })
                              }),
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [
                                _c("vab-remix-icon", {
                                  attrs: { icon: "settings-line" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    "reserve-selection": true,
                    type: "selection",
                    width: "50",
                  },
                }),
                _vm._l(_vm.finallyColumns, function (item, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      align: "center",
                      label: item.label,
                      sortable: item.sortable,
                      width: item.width,
                    },
                    on: { "header-cell-style": _vm.handleHeaderCellStyle },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              item.label == "状态"
                                ? _c("div", [
                                    row.bill_status == 4
                                      ? _c("div", [_vm._v("已作废")])
                                      : _c("div", [
                                          row.check_status == 1
                                            ? _c("div", [_vm._v("待审核")])
                                            : _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.bill_status_text
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]),
                                  ])
                                : item.label == "单号"
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "under-line",
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkOrder(row)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            class: /[-]/.test(row[item.prop])
                                              ? "red"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row[item.prop]) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "btnwrap" },
                                      [
                                        _vm._l(
                                          row.is_abnormal_type_text,
                                          function (tag) {
                                            return _c("el-tag", { key: tag }, [
                                              _vm._v(" " + _vm._s(tag) + " "),
                                            ])
                                          }
                                        ),
                                        _vm._l(
                                          row.sale_type_txt,
                                          function (tag) {
                                            return _c("el-tag", { key: tag }, [
                                              _vm._v(" " + _vm._s(tag) + " "),
                                            ])
                                          }
                                        ),
                                        Number(row.discount_amount) != 0
                                          ? _c("el-tag", [_vm._v("优惠")])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ])
                                : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    align: "center",
                    label: "操作",
                    width: "210",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.account_type == 3
                            ? [
                                row.pay_status == 0 || row.bill_status == 3
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.checkOrder(row)
                                              },
                                            },
                                          },
                                          [_vm._v(" 查看 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlePrint(row)
                                              },
                                            },
                                          },
                                          [_vm._v(" 打印 ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : row.pay_status == 1
                                  ? _c("div", [
                                      row.bill_status == 1
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleSendOrder(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 派单 ")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handlePrint(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 打印 ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      row.bill_status == 2
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    slot: "reference",
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleUnSendOrder(
                                                        row
                                                      )
                                                    },
                                                  },
                                                  slot: "reference",
                                                },
                                                [_vm._v(" 取消派单 ")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showdaohuo(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 到货 ")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handlePrint(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 打印 ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]
                            : [
                                row.check_status == 1 &&
                                (row.bill_status == 1 || row.bill_status == 2)
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleReview(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 审核 ")]
                                    )
                                  : _vm._e(),
                                row.check_status == 1
                                  ? [
                                      row.bill_status == 1 ||
                                      row.bill_status == 2
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEdit(row)
                                                },
                                              },
                                            },
                                            [_vm._v(" 编辑 ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                row.check_status == 0
                                  ? [
                                      row.bill_status == 1
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEdit(row)
                                                },
                                              },
                                            },
                                            [_vm._v(" 编辑 ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                row.bill_status == 3 || row.bill_status == 4
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkOrder(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 查看 ")]
                                    )
                                  : _vm._e(),
                                row.bill_status == 3 &&
                                _vm.authbutton[0].is_check == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelArrival(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 取消到货 ")]
                                    )
                                  : _vm._e(),
                                row.check_status == 0 && row.bill_status == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSendOrder(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 派单 ")]
                                    )
                                  : _vm._e(),
                                row.check_status == 0 && row.bill_status == 2
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleUnSendOrder(row)
                                          },
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v(" 取消派单 ")]
                                    )
                                  : _vm._e(),
                                row.check_status == 0 && row.bill_status == 2
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showdaohuo(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 到货 ")]
                                    )
                                  : _vm._e(),
                                (row.check_status == 1 &&
                                  (row.bill_status == 1 ||
                                    row.bill_status == 2)) ||
                                (row.check_status == 0 && row.bill_status == 1)
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 作废 ")]
                                    )
                                  : _vm._e(),
                                row.check_status == 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlePrint(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 打印 ")]
                                    )
                                  : _vm._e(),
                              ],
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.queryForm.pageNo,
                layout: _vm.layout,
                "page-size": _vm.queryForm.pageSize,
                total: _vm.total,
                background: "",
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            }),
            _c("el-button", { on: { click: _vm.btnPrint } }, [
              _vm._v("批量打印"),
            ]),
            _c(
              "el-button",
              { attrs: { type: "warning" }, on: { click: _vm.handlerSummary } },
              [_vm._v("批量汇总")]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "提示",
                  visible: _vm.showDialog2,
                  "append-to-body": "",
                  width: "400px",
                  top: "20vh",
                  "close-on-click-modal": false,
                  modal: true,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.showDialog2 = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "orderTest-container",
                  },
                  [
                    _c("p", [
                      _vm._v("该订单已关联调拨单:" + _vm._s(_vm.orderCode)),
                    ]),
                    _c("p", [_vm._v("是否取消派单并同时撤销调拨？")]),
                    _c(
                      "div",
                      { staticStyle: { margin: "0", "text-align": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.cancelOrderAnd },
                          },
                          [_vm._v(" 取消派单并撤销调拨 ")]
                        ),
                        _vm.showSendBtn
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.onlyCancelOrder },
                              },
                              [_vm._v(" 仅取消派单 ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            0
              ? _c("table-edit", {
                  ref: "edit",
                  on: { "fetch-data": _vm.fetchData },
                })
              : _vm._e(),
            _c("order-table-edit", {
              ref: "orderTableEdit",
              attrs: { "btn-show": _vm.btnShow },
              on: { refresh: _vm.fetchData },
            }),
            _c("summary-order", {
              ref: "summaryOrder",
              attrs: { "btn-show": _vm.btnShow },
              on: { "fetch-data": _vm.fetchData, refresh: _vm.fetchData },
            }),
            _c("add-order", {
              ref: "addOrder",
              attrs: { "btn-show": _vm.btnShow },
              on: { refresh: _vm.settimefetchData },
            }),
            _c("send-order", {
              ref: "sendOrder",
              attrs: { "btn-show": _vm.btnShow },
              on: { refresh: _vm.fetchData },
            }),
            _c("check-order", { ref: "checkOrder" }),
            _c("arrival", { ref: "arrival", on: { refresh: _vm.fetchData } }),
            _c("arrival-pay", {
              ref: "arrivalPay",
              on: { refresh: _vm.fetchData },
            }),
            _c("review", { ref: "review", on: { refresh: _vm.fetchData } }),
          ],
          1
        ),
      ]),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "XD", data_id: _vm.data_id, type: 1 },
      }),
      _c("import-tpl", { ref: "importTpl" }),
      _c("dui-jiang", {
        ref: "duiJiang",
        attrs: { "sale-type": 2 },
        on: { refresh: _vm.fetchData },
      }),
      _c("ordersum", { ref: "order" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }