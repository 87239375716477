<template>
  <el-dialog
    title="自定义客户组"
    :visible.sync="showDialog"
    width="1100px"
    top="10vh"
    :close-on-click-modal="false"
    destroy-on-close
    :modal="false"
  >
    <div class="orderTest-container">
      <div class="wrap">
        <div class="left">
          <el-button
            style="float: right; margin-bottom: 10px"
            icon="el-icon-edit"
            type="primary"
          >
            添加分组
          </el-button>
          <el-table
            v-loading="loading1"
            border
            stripe
            :data="list1"
            @cell-click="cellClick"
          >
            <el-table-column
              v-for="(item, tableIndex) in columns1"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            ></el-table-column>
            <el-table-column
              prop=""
              align="center"
              label="操作"
              fixed="right"
              width="60px"
            >
              <template #default="{ row, $index }">
                <el-button
                  type="text"
                  @click.native.stop.prevent="tableDelete(row, list1, $index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <div class="r-top">
            <div>
              <span style="margin-right: 10px">客户组名称:</span>
              <el-input style="width: 200px"></el-input>
            </div>
            <div>
              <el-button icon="el-icon-edit" type="primary">添加客户</el-button>
            </div>
          </div>
          <el-table v-loading="loading2" border stripe :data="list2">
            <el-table-column
              v-for="(item, tableIndex) in columns2"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            ></el-table-column>
            <el-table-column
              prop=""
              align="center"
              label="操作"
              fixed="right"
              width="60px"
            >
              <template #default="{ row, $index }">
                <el-button
                  type="text"
                  @click.native.prevent="tableDelete(row, list2, $index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'

  export default {
    name: 'CustomClientGroup',
    components: {},
    data() {
      return {
        showDialog: true,
        loading1: false,
        loading2: false,
        list1: [],
        list2: [],
        columns1: [
          {
            label: '已创建客户组',
            prop: 'name',
            width: '',
          },
        ],
        columns2: [
          {
            order: 1,
            label: '排序',
            prop: '',
            width: '80',
          },
          {
            order: 2,
            label: '客户名称',
            prop: '',
            width: '',
          },
          {
            order: 3,
            label: '销售区域',
            prop: '',
            width: '',
          },
          {
            order: 4,
            label: '渠道',
            prop: '',
            width: '90',
          },
          {
            order: 5,
            label: '等级',
            prop: '',
            width: '90',
          },
        ],
      }
    },
    computed: {},
    watch: {},
    created() {
      this.initSelect()
    },
    mounted() {},
    methods: {
      async initSelect() {
        // 左边表格数据
        let { data } = await postAction('baseAdmin/customer-group/index', {
          pageSize: -1,
        })
        this.list1 = data
      },
      cellClick(row, col, cell) {
        console.log('row,col,cell', row, col, cell)
        this.getDetail(row.id)
      },
      async getDetail(id) {
        let { data } = await postAction('/baseAdmin/customer-group/detail', {
          id,
        })
        console.log(data)
      },
      tableDelete(row, list, index) {
        console.log(list)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrap {
    display: flex;
    box-sizing: border-box;
    .left {
      width: 30%;
      padding: 10px;
      max-height: 600px;
      overflow-y: auto;
    }
    .right {
      flex: 1;
      padding: 10px;
      max-height: 600px;
      overflow-y: auto;
      .r-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  }
</style>
