<template>
  <!-- NAME[epic=name] 销售单 -->
  <div v-loading="bigLoading" class="orderTest-container">
    <div class="topInput">
      <el-select
        v-model="queryForm.depot_id"
        filterable
        clearable
        placeholder="选择出货仓库"
        :popper-class="'select-idx'"
      >
        <el-option
          v-for="item in depotList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="queryForm.staff_id"
        filterable
        clearable
        multiple
        :collapse-tags="true"
        placeholder="选择业务员"
        :popper-class="'select-idx'"
      >
        <el-option
          v-for="item in saleManList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="queryForm.pay_status"
        filterable
        clearable
        placeholder="是否结清"
        :popper-class="'select-idx'"
      >
        <el-option
          v-for="item in orderStatusList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="queryForm.bill_status"
        filterable
        clearable
        placeholder="状态"
        :popper-class="'select-idx'"
      >
        <el-option
          v-for="item in allotStatusList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="queryForm.time_type"
        filterable
        clearable
        placeholder="到货时间"
        :popper-class="'select-idx'"
      >
        <el-option
          v-for="item in orderTimeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-tooltip
        class="item"
        effect="dark"
        content="默认显示6个月内的订单，可点击时间框修改起止时间，查询其他时段订单"
        placement="top-start"
      >
        <el-date-picker
          v-model="queryForm.time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          :popper-class="'select-idx'"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-tooltip>

      <el-select v-model="queryForm.line_id" clearable placeholder="请选择线路">
        <el-option
          v-for="(i, idx) in lineSelect"
          :key="idx"
          :value="i.id"
          :label="i.line_name"
        ></el-option>
      </el-select>
    </div>
    <!-- 更多筛选 -->
    <div class="more">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="更多筛选条件" name="1">
          <el-cascader
            v-model="queryForm.cust_area_id"
            :options="areaList"
            placeholder="选择区域"
            :props="{
              checkStrictly: true,
              value: 'id',
              label: 'name',
              emitPath: false,
            }"
            clearable
            style="width: 140px; margin: 5px"
          ></el-cascader>
          <el-select
            v-model="queryForm.cust_channel_id"
            placeholder="选择渠道类型"
            :popper-class="'select-idx'"
            filterable
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option
              v-for="item in cust_channel"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="queryForm.cust_class_id"
            placeholder="客户等级"
            :popper-class="'select-idx'"
            filterable
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option
              v-for="item in cust_class"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="queryForm.deliver_id"
            placeholder="司机"
            :popper-class="'select-idx'"
            filterable
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option
              v-for="item in deliver"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <!-- <div style="display: flex; align-items: center"> -->
          <span style="margin-left: 10px">生产日期:</span>
          <el-date-picker
            v-model="time2"
            style="width: 290px; margin: 5px"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <!-- </div> -->

          <el-select
            v-model="queryForm.print"
            placeholder="打印状态"
            :popper-class="'select-idx'"
            filterable
            clearable
            style="width: 140px; margin: 5px"
          >
            <el-option
              v-for="item in print"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 表格 -->
    <div class="table-wrap">
      <div class="table-container">
        <vab-query-form>
          <vab-query-form-top-panel>
            <el-form
              ref="form"
              :inline="true"
              :model="queryForm"
              label-width="80px"
              @submit.native.prevent
            >
              <el-form-item>
                <client-search
                  :popper-class="'select-idx selectDC'"
                  @select-id="selectId"
                  @select-val-input="custInput"
                ></client-search>
              </el-form-item>
              <el-form-item prop="brand">
                <brand-select
                  ref="brandSelect"
                  style="width: 160px"
                  :tags="true"
                  :duoxuan="true"
                  :popper-class="'select-idx'"
                  @brand-select-change="chooseBrand"
                ></brand-select>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-input
                  v-model="queryForm.keyword_goods"
                  placeholder="商品名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-input
                  v-model="queryForm.remark"
                  placeholder="单号或单据备注"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-select
                  v-model="queryForm.bill_type"
                  placeholder="来源"
                  :popper-class="'select-idx'"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in bill_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-select
                  v-model="queryForm.account_type"
                  placeholder="结算方式"
                  :popper-class="'select-idx'"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in account_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-select
                  v-model="queryForm.sale_type"
                  placeholder="销售类型"
                  :popper-class="'select-idx'"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in sale_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  type="primary"
                  @click="btnQuery"
                >
                  查询
                </el-button>
                <el-button @click="btnExpotr">导出</el-button>
              </el-form-item>
            </el-form>
          </vab-query-form-top-panel>
          <!-- <vab-query-form-left-panel>
            <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
              添加
            </el-button>
            <el-button
              icon="el-icon-delete"
              type="danger"
              @click="handleDelete"
            >
              删除
            </el-button>
          </vab-query-form-left-panel> -->
        </vab-query-form>
        <!-- <div class="setFilter">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </div> -->
        <el-table
          ref="tableSort"
          v-loading="listLoading"
          stripe
          :data="list"
          :row-key="'id'"
          max-height="800"
          border
          show-summary
          :summary-method="getSummaries"
          @selection-change="setSelectRows"
        >
          <!-- 序号 -->
          <el-table-column align="center" type="index" width="50">
            <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="index"
                    :label="item.label"
                    @change="filterChcekboxChange"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <!-- 选择框 -->
          <el-table-column
            align="center"
            type="selection"
            :reserve-selection="true"
            width="50"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in finallyColumns"
            :key="index"
            align="center"
            :label="item.label"
            :sortable="item.sortable"
            width=""
          >
            <template v-if="item.label == '毛利率'" slot="header">
              <span>毛利率</span>
              <el-popover
                placement="bottom"
                title=""
                width="200"
                trigger="click"
                content="毛利=（商品售价-成本价）X数量 毛利率=利润/销售金额X100%"
              >
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <!-- <span #default="{ row }" v-else-if="item.label == '单号'" class="under-line">
              {{ row[item.prop] }}
            </span> -->
            <template #default="{ row }">
              <div v-if="item.label == '单号'">
                <div class="under-line" @click="checkOrder(row)">
                  <p :class="/[-]/.test(row[item.prop]) ? 'red' : ''">
                    {{ row[item.prop] }}
                  </p>
                </div>
                <div v-if="row.bill_type !== 2">
                  <div class="btnwrap">
                    <el-tag v-for="tag in row.is_abnormal_type_text" :key="tag">
                      {{ tag }}
                    </el-tag>
                    <el-tag v-for="tag in row.sale_type_txt" :key="tag">
                      {{ tag }}
                    </el-tag>
                    <el-tag v-if="Number(row.discount_amount) != 0">
                      优惠
                    </el-tag>
                  </div>
                </div>

                <div
                  v-if="row.bill_type == 2"
                  style="color: #f60; cursor: pointer"
                  @click="checkOrder2(row)"
                >
                  {{ row.order_bill_code }}
                </div>
              </div>
              <div v-else-if="item.label == '优惠'">
                <span v-if="row[item.prop] != 0" style="color: red">
                  {{ row[item.prop] }}
                </span>
                <span v-else>{{ row[item.prop] }}</span>
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="操作"
            fixed="right"
            show-overflow-tooltip
            width="190"
          >
            <template #default="{ $index, row }">
              <el-button type="text" @click="checkOrder(row)">查看</el-button>
              <template v-if="row.bill_status_text == '待对账'">
                <el-button
                  v-if="
                    row.bill_type_text == '车销' ||
                    row.bill_type_text == '仓库直销'
                  "
                  v-show="row.order_bill_code == ''"
                  type="text"
                  @click="editOrder(row)"
                >
                  编辑
                </el-button>
                <el-button v-else type="text" @click="cancelArrival(row)">
                  取消到货
                </el-button>
              </template>

              <!-- <el-button
                v-show="row.bill_status == 2"
                type="text"
                @click="handleEdit($index, row)"
              >
                冲改
              </el-button> -->
              <el-button
                v-show="
                  row.bill_status == 1 &&
                  row.bill_type_text !== '访销' &&
                  authbutton[1].is_check == 1
                "
                type="text"
                @click="handleDelete($index, row)"
              >
                作废
              </el-button>
              <!-- 状态是订单来源是车销、仓库直销的，在打印按钮前   加复制按钮 -->
              <el-button
                v-show="0"
                v-if="row.bill_type == 4 || row.bill_type == 1"
                type="text"
                @click="handleCopy(row)"
              >
                复制
              </el-button>
              <el-button type="text" @click="handlePrint($index, row)">
                打印
              </el-button>
              <!-- bill_status_text -->
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          :current-page="queryForm.pageNo"
          :layout="layout"
          :page-size="queryForm.pageSize"
          :total="total"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
        <el-button @click="btnPrint">批量打印</el-button>
        <!-- tpl -->
        <check-order ref="checkOrder"></check-order>
        <edit ref="edit" @refresh="fetchData"></edit>
      </div>
    </div>
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="XS"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <advance-order ref="advanceOrder"></advance-order>
  </div>
</template>

<script>
  import _ from 'lodash'
  import {
    getList,
    getSelect2,
    getSelect,
    getClientSelect,
    deleteSaleOrder,
  } from '@/api/saleOrder'
  // 组件
  import CheckOrder from './components/checkOrder'
  import edit from './components/edit'
  import ClientSearch from '@/baseComponents/clientSearch'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import { saleCancelArrival } from '@/api/saleOrder'
  import { downloadFile } from '@/api/Employee'
  import BrandSelect from '@/baseComponents/brandSelect'
  import { postAction } from '@/api/Employee'
  import { clientLineList } from '@/api/ClientDetail' // 线路下拉

  import { day_n } from '@/utils/Time'
  import { mapGetters } from 'vuex'
  export default {
    name: 'SaleOrder',
    components: {
      RuilangDisplayDialog,
      CheckOrder,
      ClientSearch,
      advanceOrder,
      edit,
      BrandSelect,
    },
    data() {
      return {
        authbutton: [],
        time2: [],
        totalAll: {},
        bigLoading: false,
        data_id: 0,
        restaurants: [],
        areaList: [],
        saleManList: [],
        depotList: [],
        orderStatusList: [],
        allotStatusList: [],
        orderTimeList: [],
        cust_channel: [],
        cust_class: [],
        deliver: [],
        bill_type: [],
        account_type: [],
        print: [],
        sale_type: [],
        depot: '',
        saleMan: '',
        orderStatus: '',
        allotStatus: '',
        orderTime: '',
        time: '',
        searchValue: '',
        indexTableData: '',
        selectRes: [],
        // 筛选条件
        activeNames: ['0'],
        // 表格
        checkList: [
          '单号',
          '客户名称',
          '来源',
          '出货仓库',
          '业务员',
          '送货人',
          '订购金额',
          '优惠',
          '应收金额',
          '实收',
          '毛利',
          '毛利率',
          '结清',
          '结清时间',
          '对账',
          '到货时间',
          '备注',
          '打印数',
        ],
        columns: [
          {
            order: 2,
            label: '单号',
            width: '200',
            prop: 'bill_code',
            sortable: false,
          },
          {
            order: 3,
            label: '客户名称',
            width: '110',
            prop: 'cust_name',
            sortable: false,
          },
          {
            order: 4,
            label: '来源',
            width: '100',
            prop: 'bill_type_text',
            sortable: false,
          },
          {
            order: 5,
            label: '出货仓库',
            width: '100',
            prop: 'depot_name',
            sortable: false,
          },
          {
            order: 6,
            label: '业务员',
            width: '90',
            prop: 'staff_name',
            sortable: false,
          },
          {
            order: 7,
            label: '送货人',
            width: '90',
            prop: 'deliver_name',
            sortable: false,
          },
          {
            order: 8,
            label: '订购金额',
            width: '100',
            prop: 'total_amount',
            sortable: false,
          },
          {
            order: 8.1,
            label: '优惠',
            width: '100',
            prop: 'discount_amount',
            sortable: false,
          },
          {
            order: 9,
            label: '应收金额',
            width: '100',
            prop: 'receiv_money',
            sortable: false,
          },
          {
            order: 9.5,
            label: '实收',
            width: '100',
            prop: 'pay_amount',
            sortable: false,
          },
          {
            order: 10,
            label: '毛利',
            width: '100',
            prop: 'gross_profit_amount',
            sortable: false,
          },
          {
            order: 10.5,
            label: '毛利率',
            width: '100',
            prop: 'gross_profit',
            sortable: false,
          },
          {
            order: 11,
            label: '到货时间',
            width: '110px',
            prop: 'arrive_at',
            sortable: false,
          },
          {
            order: 11.5,
            label: '结清',
            width: '90',
            prop: 'pay_status_text',
          },
          {
            order: 11.6,
            label: '结清时间',
            width: '120',
            prop: 'pay_status_time',
          },
          {
            order: 12,
            label: '对账',
            width: '100',
            prop: 'bill_status_text',
          },
          {
            order: 12.5,
            label: '备注',
            width: '110',
            prop: 'remark',
          },
          {
            order: 13,
            label: '打印数',
            width: '90',
            prop: 'print_num',
          },
        ],
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          line_id: '',
          pageNo: 1,
          pageSize: 10,
          depot_id: '',
          staff_id: [],
          pay_status: '',
          bill_status: '',
          time_type: 'arrive_at',
          time: '',
          cust_channel_id: '',
          cust_class_id: '',
          cust_area_id: '',
          start_time: '',
          end_time: '',
          areaList: '',
          cust_channel: '',
          cust_class: '',
          deliver_id: '',
          bill_type: '',
          account_type: '',
          sale_type: '',
          print: '',

          // 客户简拼
          keyword: '',
          cust_id: '',
        },
        showCheck: false,
        lineSelect: [],
      }
    },
    computed: {
      ...mapGetters({
        dealername: 'user/dealername',
      }),
      env() {
        return process.env.NODE_ENV == 'development' ? true : false
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => Math.floor(item.order / 10))
      },
    },
    watch: {
      time2(val) {
        if (val) {
          this.queryForm.start_date = val[0]
          this.queryForm.end_date = val[1]
        } else {
          this.queryForm.start_date = ''
          this.queryForm.end_date = ''
        }
      },
    },
    async created() {
      this.initTime()

      getSelect().then((res) => {
        this.areaList = res.data.cust_area //区域
        this.authbutton = res.data.fun_list
      })
      // 客户下拉菜单数据获取
      getSelect2().then((res) => {
        // this.selectRes = res
        if (res.code == 200) {
          let data = res.data
          console.log(data)

          this.depotList = data.depot
          this.saleManList = data.staff // 业务员
          this.orderStatusList = data.pay_status // 是否结清
          this.allotStatusList = data.bill_status // 状态
          this.orderTimeList = data.time_type // 到货时间
          this.cust_channel = data.cust_channel //渠道类型
          this.cust_class = data.cust_class // 等级
          this.deliver = data.deliver //司机
          this.bill_type = data.bill_type //来源
          this.account_type = data.account_type //结算方式
          this.sale_type = data.sale_type //销售类型
          this.print = data.print //打印
        } else {
          this.$message.error(res.msg)
        }
      })

      let lineData = await clientLineList()
      this.lineSelect = lineData.data
      // // 获取表格数据
      // getAdvanceOrderTableList().then((res) => {
      //   console.log(res)
      // })

      // keyword 下拉
      getClientSelect({ pageNo: 1, pageSize: -1 }).then((res) => {
        if (res.code == 200) {
          this.restaurants = res.data
          console.log(this.restaurants)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    mounted() {
      // 获取url参数
      this.getTableWidth()
      console.log('获取url参数', this.$route.query.sale_type)
      if (this.$route.query.sale_type) {
        this.queryForm.sale_type = Number(this.$route.query.sale_type)
        this.queryForm.time = [day_n(3)[1], day_n(3)[1]]
        this.queryForm.start_time = this.queryForm.time[1]
        this.queryForm.end_time = this.queryForm.time[1]
        this.fetchData()
      } else {
        this.fetchData()
      }
    },
    activated() {
      this.fetchData()
    },
    methods: {
      toggleSelection() {
        this.$refs.tableSort.clearSelection()
        this.selectRows = ''
      },
      initTime() {
        this.queryForm.time = day_n(180)
        this.queryForm.start_time = this.queryForm.time[0]
        this.queryForm.end_time = this.queryForm.time[1]
        // console.log('day_n(90)', day_n(90))
      },
      summaryFunction(param) {
        console.log('1111122')
        const { columns, data } = param
        // 需要合计的下标
        let i = [8, 9, 10, 11]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      // keyword 下拉
      // querySearch(queryString, cb) {
      //   var restaurants = this.restaurants
      //   var results = queryString
      //     ? restaurants.filter(this.createFilter(queryString))
      //     : restaurants
      //   // 调用 callback 返回建议列表的数据
      //   console.log(results)

      //   cb(results)
      // },
      // createFilter(queryString) {
      //   return (state) => {
      //     return (
      //       state.cust_name.toLowerCase().indexOf(queryString.toLowerCase()) ===
      //       0
      //     )
      //   }
      // },
      // loadAll() {
      //   return
      // },
      // loadAll() {
      //   // let res = await getList({ pageNo: 1, pageSize: 50 })
      //   getList({ pageNo: 1, pageSize: 50 }).then((res) => {
      //     console.log(res)
      //     if (res.code == 200) {
      //       this.restaurants = res.data
      //     } else {
      //       this.$message.error(res.msg)
      //     }
      //   })
      //   // this.restaurants = data
      // },
      // async querySearch(val, cb) {
      //   let selectData = this.selectRes.data.client
      //   // let clientRes = await request({
      //   //   url: '/advance/clientSelect',
      //   //   method: 'get',
      //   // })
      //   // this.selectRes = clientRes.data.client
      //   console.log(selectData)
      //   var results = val
      //     ? selectData.filter(this.createStateFilter(val))
      //     : selectData
      //   cb(results)
      // },
      // 表格内查看
      checkOrder(row) {
        console.log('row', row)
        this.$refs['checkOrder'].isshowDialog = true
        // this.$refs['checkOrder'].orderStatus = row.bill_status_text
        this.$refs['checkOrder'].id = row.id
      },
      // 复制
      handleCopy(row) {
        this.$refs.edit.isshowDialog = true
        this.$refs.edit.isCopy = true
        this.$refs.edit.id = row.id
      },
      editOrder(row) {
        console.log('row', row)
        this.$refs.edit.isshowDialog = true
        this.$refs.edit.isCopy = false
        this.$refs.edit.id = row.id
        this.$refs.edit.edit = false
      },
      custInput(v) {
        this.queryForm.keyword = v
      },
      chooseBrand(val) {
        this.queryForm.brand_id = val
      },
      // 客户输入框下拉选择事件
      selectId(val) {
        console.log('selectId')
        console.log(val)
        if (val.cust_id) {
          this.queryForm.cust_id = val
          this.queryForm.keyword = ''
        } else {
          this.queryForm.cust_id = val
          this.queryForm.keyword = ''
        }
      },
      clearInput() {
        this.queryForm.cust_id = ''
      },

      mlvclick() {},
      // top 按钮事件
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      btnExpotr() {
        this.bigLoading = true
        downloadFile(
          '/saleAdmin/order/export',
          '销售单.xlsx',
          this.queryForm
        ).then(() => {
          this.bigLoading = false
        })
      },
      btnPrint() {
        if (!this.selectRows) {
          this.$message.error('请勾选需要打印的选项')
        } else {
          let ids = []
          this.selectRows.forEach((i) => ids.push(i.id))
          this.data_id = ids.join(',')
          this.$refs['RuilangDisplayDialog'].batch = true
          this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
          this.toggleSelection()
        }
      },
      // 取消到货
      cancelArrival(row) {
        this.$confirm(
          '取消到货后，现销售单作废，车存复原（退货如已退库，需手动作废退库调拨单），原预订单需重新做到货',
          '确定要取消到货？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '再想想',
          }
        ).then((r) => {
          saleCancelArrival({ id: row.id }).then((res) => {
            this.$message.success(res.msg)
            this.fetchData()
          })
        })
      },
      btnQuery() {
        this.toggleSelection()
        console.log('chaxun')
        if (this.queryForm.time) {
          Object.assign(this.queryForm, {
            start_time: this.queryForm.time[0],
            end_time: this.queryForm.time[1],
          })
        } else {
          Object.assign(this.queryForm, {
            start_time: '',
            end_time: '',
          })
        }

        console.log(this.queryForm)

        this.queryForm.pageNo = 1
        this.fetchData()
      },
      // handleSelect(val, e) {
      //   console.log(val)
      // },
      // 勾选表格触发事件
      setSelectRows(val) {
        console.log(val)
        this.selectRows = val
      },

      // 弹窗到货
      showdaohuo(row) {
        console.log(row)
        this.$refs['arrival'].isshowDialog = true
        this.$refs['arrival'].id = row.id
        this.$refs['arrival'].orderStatus = row.bill_status_text
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleDelete(index, row) {
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteSaleOrder({ id: row.id }).then((res) => {
              this.$message.success(res.msg)
              this.fetchData()
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleEdit(index, row) {
        console.log('冲改')
        console.log(index, row)
      },
      // handleSendOrder(row) {
      //   console.log('查看', row)
      //   this.$refs['checkOrder'].showDialog(row)
      //   this.$refs['checkOrder'].id = row.id
      //   this.$refs['checkOrder'].orderStatus = row.bill_status_text
      // },
      handlePrint(index, row) {
        console.log('打印', row)
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.batch = false
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },

      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },

      async fetchData() {
        console.log('fetchData()')
        this.listLoading = true
        let quform = JSON.parse(JSON.stringify(this.queryForm))
        quform.staff_id = quform.staff_id.join(',')
        const { data, totalCount, msg } = await getList(quform)
        console.log(123123)
        console.log(data, '全部数据')
        this.total = Number(totalCount)
        this.totalAll = msg
        this.list = data
        this.listLoading = false
      },
      // 合计
      getSummaries({ columns, data }) {
        let sums = []
        /**
           * 金额总计 total_amount
  应收总计 receiv_money
  实收总计 pay_amount

  优惠总计 sale_amount discount_amount
  毛利总计 gross_profit_amount

           */

        sums[0] = '总计'
        columns.forEach((list, index) => {
          if (list.label == '订购金额') {
            sums[index] = this.totalAll.total_amount
          }
          if (list.label == '优惠') {
            sums[index] = this.totalAll.discount_amount
          }
          if (list.label == '应收金额') {
            sums[index] = this.totalAll.receiv_money
          }
          if (list.label == '实收') {
            sums[index] = this.totalAll.pay_amount
          }
          if (list.label == '毛利') {
            sums[index] = this.totalAll.gross_profit_amount
          }
          if (list.label == '毛利率') {
            sums[index] = this.totalAll.gross_profit
          }
          if (list.label == '操作') {
            sums[index] = this.dealername
          }
        })

        // sums[0] = '总计'
        // sums[8] = this.totalAll.total_amount //订购
        // sums[9] = this.totalAll.discount_amount //优惠
        // sums[10] = this.totalAll.receiv_money //应收
        // sums[11] = this.totalAll.pay_amount //实收
        // sums[12] = this.totalAll.gross_profit_amount //毛利
        // sums[13] = this.totalAll.gross_profit //毛利率

        return sums
      },
      // 查看预订单
      checkOrder2(row) {
        console.log('22')
        this.$refs.advanceOrder.id = row.order_id
        this.$refs.advanceOrder.isshowDialog = true
      },
      // 获取一开始表格cloumns数据
      async getTableWidth() {
        let { data } = await postAction('/setAdmin/config/form-set-list', {
          form_type: 2,
        })
        console.log('data', data)
        let checkList = []
        data.forEach((i) => {
          if (i.width == 0) {
            i.width = ''
          }
          if (!i.hidden) {
            checkList.push(i.label)
          }
        })
        if (checkList.length == 0) {
          this.checkList = [
            '单号',
            '客户名称',
            '来源',
            '出货仓库',
            '业务员',
            '送货人',
            '订购金额',
            '优惠',
            '应收金额',
            '实收',
            '毛利',
            '毛利率',
            '结清',
            '结清时间',
            '对账',
            '到货时间',
            '备注',
            '打印数',
          ]
        } else {
          this.checkList = checkList
        }
        if (data.length == 0) {
          this.columns = [
            {
              order: 2,
              label: '单号',
              width: '200',
              prop: 'bill_code',
              sortable: false,
            },
            {
              order: 3,
              label: '客户名称',
              width: '110',
              prop: 'cust_name',
              sortable: false,
            },
            {
              order: 4,
              label: '来源',
              width: '100',
              prop: 'bill_type_text',
              sortable: false,
            },
            {
              order: 5,
              label: '出货仓库',
              width: '100',
              prop: 'depot_name',
              sortable: false,
            },
            {
              order: 6,
              label: '业务员',
              width: '90',
              prop: 'staff_name',
              sortable: false,
            },
            {
              order: 7,
              label: '送货人',
              width: '90',
              prop: 'deliver_name',
              sortable: false,
            },
            {
              order: 8,
              label: '订购金额',
              width: '100',
              prop: 'total_amount',
              sortable: false,
            },
            {
              order: 8.1,
              label: '优惠',
              width: '100',
              prop: 'discount_amount',
              sortable: false,
            },
            {
              order: 9,
              label: '应收金额',
              width: '100',
              prop: 'receiv_money',
              sortable: false,
            },
            {
              order: 9.5,
              label: '实收',
              width: '100',
              prop: 'pay_amount',
              sortable: false,
            },
            {
              order: 10,
              label: '毛利',
              width: '100',
              prop: 'gross_profit_amount',
              sortable: false,
            },
            {
              order: 10.5,
              label: '毛利率',
              width: '100',
              prop: 'gross_profit',
              sortable: false,
            },
            {
              order: 11,
              label: '到货时间',
              width: '110px',
              prop: 'arrive_at',
              sortable: false,
            },
            {
              order: 11.5,
              label: '结清',
              width: '90',
              prop: 'pay_status_text',
            },
            {
              order: 11.6,
              label: '结清时间',
              width: '120',
              prop: 'pay_status_time',
            },
            {
              order: 12,
              label: '对账',
              width: '100',
              prop: 'bill_status_text',
            },
            {
              order: 12.5,
              label: '备注',
              width: '110',
              prop: 'remark',
            },
            {
              order: 13,
              label: '打印数',
              width: '90',
              prop: 'print_num',
            },
          ]
        } else {
          this.columns = data
        }
      },
      // 表格宽度发生变化
      async widthChange(newWidth, oldWidth, column, event) {
        console.log(newWidth, oldWidth, column, event)
        let a = this.columns.filter((i) => i.label == column.label)
        a[0].width = newWidth
        console.log('表格宽度发生变化', a)
        this.columns.forEach((i) => {
          if (i.label == column.label) {
            i.width = newWidth
          }
        })
        console.log('this.columns', this.columns)
        let { data } = await postAction('/setAdmin/config/form-set', {
          form_type: 2,
          form_data: JSON.stringify(this.columns),
        })
      },
      async filterChcekboxChange(v, io) {
        console.log('v', v)
        console.log('io', io)
        this.columns.forEach((i) => {
          if (i.label == io.target.value) {
            i.hidden = !v
          }
        })
        let { data } = await postAction('/setAdmin/config/form-set', {
          form_type: 2,
          form_data: JSON.stringify(this.columns),
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .table-wrap {
    ::v-deep {
      i {
        cursor: pointer;
      }
    }
  }
  .topInput {
    display: flex;
    justify-content: flex-start;
    div {
      margin-right: 15px;
    }
  }
  .table-wrap {
    margin-top: 10px;
  }
  .setFilter {
    top: 39px;
  }
</style>
<style lang="scss">
  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 0 0 $base-padding/4 0;
    }
  }
  .red {
    color: red;
  }
</style>
