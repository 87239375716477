var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkOrder" },
    [
      _vm.isshowDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                modal: false,
                "close-on-click-modal": false,
                title: "订单详情",
                top: "5vh",
                visible: _vm.isshowDialog,
                width: "1300px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isshowDialog = $event
                },
                close: _vm.close,
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "wrap",
                },
                [
                  _c("el-alert", {
                    staticStyle: {
                      width: "86%",
                      "margin-top": "-70px",
                      "margin-left": "80px",
                    },
                    attrs: {
                      closable: false,
                      title: _vm.titleTip,
                      type: "success",
                    },
                  }),
                  _c("div", { staticClass: "orderStatus" }, [
                    _vm._v(_vm._s(_vm.orderStatus)),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": "80px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "购货方", prop: "cust_name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.cust_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "cust_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.cust_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "boss" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.boss,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "boss",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.boss",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "dz",
                          attrs: { label: "地址", prop: "address" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "380px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "address",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "出货仓库", prop: "depot_name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.depot_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "depot_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.depot_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "送货人", prop: "deliver_name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.deliver_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "deliver_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.deliver_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员", prop: "staff_name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.staff_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "staff_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.staff_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "调拨单", prop: "allot_code" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.allot_code,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "allot_code",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.allot_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "派单", prop: "delivery_time" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.delivery_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "delivery_time",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.delivery_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.followuser.length != 0
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "随车人" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "随车人员",
                                    clearable: "",
                                    multiple: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.form.follow_users,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "follow_users", $$v)
                                    },
                                    expression: "form.follow_users",
                                  },
                                },
                                _vm._l(_vm.followuser, function (item_) {
                                  return _c("el-option", {
                                    key: item_.id,
                                    attrs: {
                                      label: item_.user_name,
                                      value: item_.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "middleTable",
                      attrs: {
                        stripe: "",
                        data: _vm.middleTableData,
                        "show-summary": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { align: "center", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                return [
                                  _c("span", [_vm._v(_vm._s($index + 1))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1065934640
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          type: "selection",
                          width: "45",
                        },
                      }),
                      _vm._l(_vm.finallyColumns, function (item, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            align: "center",
                            label: item.label,
                            sortable: item.sortable,
                            width: item.width,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  var row = ref.row
                                  return [
                                    !row.edit
                                      ? [
                                          item.label == "到货数量"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: "",
                                                      onkeyup:
                                                        "value=value.replace(/[^\\d.]/g,'') ",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _vm.moneyN(row, $index),
                                                          _vm.utilsF()
                                                      },
                                                    },
                                                    model: {
                                                      value: row.quantity,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "quantity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.quantity",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : item.label == "类型"
                                            ? _c(
                                                "span",
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value: row.goods_type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "goods_type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.goods_type",
                                                      },
                                                    },
                                                    _vm._l(
                                                      row.arr_goods_type,
                                                      function (item__) {
                                                        return _c("el-option", {
                                                          key: item__.id,
                                                          attrs: {
                                                            label: item__.name,
                                                            value: item__.id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : item.label == "单位"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(row.unit_name) +
                                                    " "
                                                ),
                                              ])
                                            : item.label == "生产日期"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "130px",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                    },
                                                    model: {
                                                      value:
                                                        row.goods_production_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "goods_production_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.goods_production_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : item.label == "备注"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("el-input", {
                                                    model: {
                                                      value: row.info,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "info",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.info",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : item.label == "车存"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.balance_count_text
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : item.label == "单价"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("el-input", {
                                                    attrs: { disabled: "" },
                                                    on: {
                                                      input: function ($event) {
                                                        _vm.moneyN(row, $index),
                                                          _vm.utilsF()
                                                      },
                                                    },
                                                    model: {
                                                      value: row.goods_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "goods_price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.goods_price",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(row[item.prop]) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      : [
                                          item.label == "类型"
                                            ? _c(
                                                "span",
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder: "选择类型",
                                                        disabled: "",
                                                      },
                                                      on: {
                                                        change: _vm.utilsF,
                                                      },
                                                      model: {
                                                        value: row.goods_type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "goods_type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.goods_type",
                                                      },
                                                    },
                                                    _vm._l(
                                                      row.arr_goods_type,
                                                      function (item__) {
                                                        return _c("el-option", {
                                                          key: item__.id,
                                                          attrs: {
                                                            label: item__.name,
                                                            value: item__.id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : item.label == "单位"
                                            ? _c(
                                                "span",
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder: "选择单位",
                                                        disabled: "",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          _vm.unitChange(
                                                            $event,
                                                            row
                                                          ),
                                                            _vm.utilsF()
                                                        },
                                                      },
                                                      model: {
                                                        value: row.unit_id,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "unit_id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.unit_id",
                                                      },
                                                    },
                                                    _vm._l(
                                                      row.arr_unit,
                                                      function (item1_) {
                                                        return _c("el-option", {
                                                          key: item1_.id,
                                                          attrs: {
                                                            label:
                                                              item1_.unit_name,
                                                            value: item1_.id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : item.label == "规格"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " + _vm._s(row.specs) + " "
                                                ),
                                              ])
                                            : item.label == "单价"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("el-input", {
                                                    attrs: { disabled: "" },
                                                    on: {
                                                      input: function ($event) {
                                                        _vm.moneyN(row, $index),
                                                          _vm.utilsF()
                                                      },
                                                    },
                                                    model: {
                                                      value: row.goods_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "goods_price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.goods_price",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : item.label == "订购数量"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(row.pre_quantity) +
                                                    " "
                                                ),
                                              ])
                                            : item.label == "到货数量"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("el-input", {
                                                    attrs: { disabled: "" },
                                                    on: {
                                                      input: function ($event) {
                                                        _vm.moneyN(row, $index),
                                                          _vm.utilsF()
                                                      },
                                                    },
                                                    model: {
                                                      value: row.quantity,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "quantity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.quantity",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : item.label == "车存"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.balance_count_text
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : item.label == "金额"
                                            ? _c("span", [
                                                row.goods_type == 2 ||
                                                row.goods_type == 10 ||
                                                row.goods_type == 11 ||
                                                row.goods_type == 3 ||
                                                row.goods_type == 12 ||
                                                row.goods_type == 13 ||
                                                row.goods_type == 16
                                                  ? _c("div", [_vm._v(" 0 ")])
                                                  : _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.goods_money
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ])
                                            : item.label == "生产日期"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "130px",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                    },
                                                    model: {
                                                      value:
                                                        row.goods_production_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "goods_production_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.goods_production_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : item.label == "备注"
                                            ? _c(
                                                "span",
                                                [
                                                  _c("el-input", {
                                                    model: {
                                                      value: row.info,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "info",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.info",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      onkeyup:
                                                        "value=value.replace(/[^\\d.]/g,'') ",
                                                    },
                                                    model: {
                                                      value: row[item.prop],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          item.prop,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row[item.prop]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                        ],
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "form2",
                      staticStyle: { "margin-top": "15px" },
                      attrs: {
                        model: _vm.form,
                        "label-width": "120px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "备注", prop: "remark" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "530px" },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订购总额", prop: "total_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.total_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "total_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.total_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售金额", prop: "sale_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.sale_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "sale_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.sale_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "使用预存款",
                            prop: "deposit_amount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.deposit_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "deposit_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.deposit_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "还货金额", prop: "return_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.return_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "return_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.return_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠", prop: "discounted_amount" } },
                        [
                          _c("el-input", {
                            on: { input: _vm.yhInputF },
                            model: {
                              value: _vm.form.discount_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "discount_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.discount_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "优惠后", prop: "discounted_amount" },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.yhhInputF },
                            model: {
                              value: _vm.form.discounted_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "discounted_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.discounted_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "退货退款", prop: "refund_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.refund_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "refund_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.refund_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退货金额",
                            prop: "refund_goods_amount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.refund_goods_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "refund_goods_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.refund_goods_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "退货方式", prop: "refund_type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "186px" },
                              attrs: { placeholder: "退货方式" },
                              on: { change: _vm.utilsF },
                              model: {
                                value: _vm.form.refund_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "refund_type", $$v)
                                },
                                expression: "form.refund_type",
                              },
                            },
                            _vm._l(_vm.data.arr_refund_type, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.style == 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "兑奖现金:",
                                prop: "prize_amount",
                              },
                            },
                            [
                              _c("el-input", {
                                on: { input: _vm.rehandler },
                                model: {
                                  value: _vm.form.prize_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "prize_amount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.prize_amount",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.style == 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "兑付现金:",
                                prop: "cash_amount",
                              },
                            },
                            [
                              _c("el-input", {
                                on: { input: _vm.rehandler },
                                model: {
                                  value: _vm.form.cash_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "cash_amount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.cash_amount",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.is_fun
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "费用抵扣", prop: "thtk" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.support_amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "support_amount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.support_amount",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.is_fun
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "186px" },
                                  attrs: {
                                    placeholder:
                                      "请选费用" +
                                      "（合计" +
                                      _vm.funds_zong +
                                      "）",
                                    multiple: "",
                                    "collapse-tags": "",
                                  },
                                  on: { change: _vm.handlerfydk },
                                  model: {
                                    value: _vm.form.fun_ids,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "fun_ids", $$v)
                                    },
                                    expression: "form.fun_ids",
                                  },
                                },
                                _vm._l(_vm.funselect, function (item_) {
                                  return _c("el-option", {
                                    key: item_.id,
                                    attrs: {
                                      label:
                                        item_.funds_type +
                                        "(金额" +
                                        item_.left_amount +
                                        ")",
                                      value: item_.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "应收款", prop: "receiv_money" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.receiv_money,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "receiv_money",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.receiv_money",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "结算方式",
                            prop: "account_type_text",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.account_type_text,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "account_type_text",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.account_type_text",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "销售收款" } }, [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.staff_collect,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "staff_collect",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.staff_collect",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "派送收款" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.deliver_collect,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "deliver_collect",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.deliver_collect",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.style == 1
                    ? _c(
                        "el-table",
                        {
                          ref: "xTable",
                          attrs: {
                            stripe: "",
                            data: _vm.xTableData,
                            "show-summary": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "收款方式",
                              width: "auto",
                              prop: "pay_type_name",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "金额",
                              width: "auto",
                              prop: "amount",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "收款人",
                              width: "auto",
                              prop: "user_name",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "时间",
                              width: "auto",
                              prop: "create_at",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: {
                        "margin-top": "15px",
                        "text-align": "right",
                      },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.showSun },
                        },
                        [_vm._v("派单到货")]
                      ),
                      _c("el-button", [_vm._v("打印")]),
                      _c("el-button", { on: { click: _vm.close } }, [
                        _vm._v("取 消"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("arrival-sun", {
                ref: "arrivalSun",
                attrs: {
                  data: { a: _vm.form.receiv_money, b: _vm.form.staff_collect },
                },
                on: { "save-end": _vm.handleSendOrder },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }