<!--
 * @Author: your name
 * @Date: 2021-02-07 15:14:17
 * @LastEditTime: 2021-04-22 14:15:08
 * @LastEditors: Please set LastEditors
 * @Description: 应付款
 * @FilePath: \sd-vue-admin\src\views\project\finance\supplier\payable\index.vue
-->
<template>
  <!-- NAME[epic=财务] 供应商应付款 -->
  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="supp_id">
          <el-select
            v-model="form.supp_id"
            placeholder="请选择供应商"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in supplier"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="Time">
          <el-date-picker
            v-model="form.Time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="print_status">
          <el-select
            v-model="form.bill_status"
            placeholder="请选择状态"
            style="width: 120px"
            clearable
          >
            <el-option
              v-for="(item, index) in Typesof"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="bill_code">
          <el-input v-model="form.bill_code" placeholder="单号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlersver">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <el-button @click="handlerExport">导出</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        ref="multipleTable"
        stripe
        :data="tableData"
        :row-key="getRowKey"
        show-summary
        :summary-method="summaryFunction"
        @selection-change="handlerSelection"
      >
        <el-table-column
          type="selection"
          width="70"
          align="center"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column align="center" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in colemd"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
          <template #default="{ row, $index }">
            <div v-if="row.bill_code == '合计'"></div>
            <div v-else>{{ $index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template #default="{ row }">
            <el-button type="text" @click="handlerlink(row)">查看</el-button>
            <el-button
              v-show="row.bill_status == '2' && row.scrap_time == 0"
              type="text"
              @click="handlerPay(row)"
            >
              付款
            </el-button>
            <el-button
              style="margin-left: 10px"
              type="text"
              @click="handlePrint(row)"
            >
              打印
            </el-button>
            <!-- <el-popconfirm
              title="您真的要作废这个单据吗？"
              @confirm="handlerstorp(row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                作废
              </el-button>
            </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <el-row type="flex" class="row-bg">
      <el-button type="primary" @click="batchPay">批量付款</el-button>
    </el-row>
    <jiePay ref="add" @getlist="getlist"></jiePay>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="YF"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <batch-edit ref="batchEdit" @refresh="handlersver"></batch-edit>
  </div>
</template>

<script>
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import jiePay from './components/jiePay.vue'
  import timefort from '@/utils/timestamp'
  import { downloadFile, postAction } from '@/api/Employee'
  import BatchEdit from './components/batchEdit'
  export default {
    components: {
      jiePay,
      RuilangDisplayDialog,
      BatchEdit,
    },
    data() {
      return {
        data_id: 0,
        form: {
          supp_id: '', //供应商ID
          Time: [],
          start_time: '', //开始时间，时间戳
          end_time: '', //结束时间，时间戳
          bill_status: '', //打印状态，0未打印，1已打印
          bill_code: '', //采购单号
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        Typesof: [
          {
            id: '1',
            name: '已结清',
          },
          {
            id: '2',
            name: '未结清',
          },
          {
            id: '3',
            name: '已作废',
          },
        ],
        supplier: [],
        url: {
          list: '/cashAdmin/supp/payment-arrears-list',
          supplier: '/purchaseAdmin/base/supp-list',
          stop: '/cashAdmin/supp/base-scrap',
        },
        tableData: [],
        checkList: [
          '单号',
          '供应商',
          '付款人',
          '付款时间',
          '付款金额',
          '付款账户',
          '备注',
        ],
        colemd: [
          {
            order: 1,
            label: '单号',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 2,
            label: '供应商',
            align: 'center',
            prop: 'supp_name',
            width: '',
          },
          {
            order: 3,
            label: '下单时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
          {
            order: 4,
            label: '采购金额',
            align: 'center',
            prop: 'bill_amount',
            width: '',
          },
          {
            order: 5,
            label: '剩余应付款',
            align: 'center',
            prop: 'arrears_amount',
            width: '',
          },
          {
            order: 6,
            label: '状态',
            align: 'center',
            prop: 'bill_status_text',
            width: '',
          },

          {
            order: 7,
            label: '备注',
            align: 'center',
            prop: 'remark',
            width: '',
          },
        ],
        footer: {},
        selectRow: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      'form.Time'(val) {
        console.log(val)
        try {
          this.form.start_time = timefort.timestamp(val[0])
          this.form.end_time = timefort.timestamp(val[1])
          console.log(this.form.start_time)
        } catch (error) {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      'form.goodsname'(val) {
        if (val == '') {
          this.form.goods_id = ''
        }
      },
    },
    mounted() {
      this.handlerlist()
      this.handlersupplier()
    },
    methods: {
      handlerlink(row) {
        this.$refs.add.receipt = true
        this.$refs.add.showpay = '2'
        this.$refs.add.handlerData(row)
      },
      handlerPay(row) {
        this.$refs.add.receipt = true
        this.$refs.add.showpay = '1'
        this.$refs.add.handlerData(row)
      },
      handlePrint(row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      getlist() {
        this.handlerlist()
      },
      handlerSelection(val) {
        console.log(val, '选完后的')
        this.selectRow = val
      },
      getRowKey(row) {
        return row.id
      },
      //供应商
      handlersupplier() {
        postAction(this.url.supplier, {})
          .then((res) => {
            console.log(res, '供应商')
            this.supplier = res.data.rows
          })
          .catch((err) => {
            console.log(err, '')
          })
      },

      handlerstorp(row) {
        postAction(this.url.stop, { id: row.id }).then((res) => {
          console.log(res, '作废')
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.handlerlist()
        })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlersver() {
        this.form.pageNo = 1
        this.$refs.multipleTable.clearSelection()
        this.handlerlist()
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.form.bill_code = ''
        this.form.bill_status = ''
        this.form.end_time = null
        this.form.pageNo = 1
        this.form.pageSize = 10
        this.form.start_time = null
        this.form.supp_id = ''
        this.handlerlist()
      },
      handlerlist() {
        var data = JSON.parse(JSON.stringify(this.form))
        delete data.Time
        delete data.goodsname
        postAction(this.url.list, data)
          .then((res) => {
            console.log(res)
            this.tableData = res.data.rows

            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      batchPay() {
        console.log('批量付款')
        if (this.selectRow.length == 0) {
          this.$message.error('请勾选供应商')
        } else {
          let ids = []
          let sum = 0
          this.selectRow.forEach((i) => {
            ids.push(i.id)
            sum += Number(i.arrears_amount)
          })

          this.$refs.batchEdit.arrears_ids = ids.join()
          this.$refs.batchEdit.sum = sum
          this.$refs.batchEdit.showDialog = true
        }
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [5, 6]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      handlerExport() {
        downloadFile(
          '/cashAdmin/supp/payment-arrears-list-export',
          '供应商应付款.xlsx',
          this.form
        )
      },
    },
  }
</script>

<style></style>
