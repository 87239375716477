const { mock } = require('mockjs')
const List = [],
  List2 = []
const count = 8
// 主页表格数据
for (let i = 0; i < count; i++) {
  List.push(
    mock({
      id: '@id',
      'bill_code|9': /[a-z][A-Z]/, // 单号
      'cust_name|1': ['@cname 超市', '@cname 烟酒'], // 客户名称
      'bill_source_text|1': ['仿销', '网销'], // 来源
      'depot_id|1': ['总仓', 'A仓库', 'B仓库', 'C仓库'], // 出货仓库
      staff_id: '@cname', // 业务员
      deliver_id: '@cname', // 送货人
      'pay_amount|0-900.2': 0, // 订单金额
      'sale_amount|0-900.2': 0, // 销售金额
      'total_amount|0-900.2': 0, // 已收款
      'check_status|1': ['待派单', '已派单', '已到货', '已作废'], // 状态
      sale_time: '@date(yyyy-MM-dd)', // 下单时间
      'print_num|0-9': 0, // 打印数
      description: '@csentence',
      'status|1': ['published', 'draft', 'deleted'],
      author: '@cname',
      datetime: '@datetime',
      pageViews: '@integer(300, 5000)',
      switch: '@boolean',
      percent: '@integer(80,99)',
      'rate|1': [1, 2, 3, 4, 5],
    })
  )
}

// 编辑订单表格数据
for (let i = 0; i < 6; i++) {
  List2.push(
    mock({
      id: '@id',
      'type|1': ['销售', '赠品', '换货', '兑付', '尝品'], // 类型
      'goods_name|1': ['伊利240ml', '伊利240ml', '奥利奥50g', '伊利酸奶100g'], // 商品名称
      'units|1': ['箱', '袋', '瓶', '杯'], // 单位
      'price|10-30.2': 0, // 单价
      'order_num|5-25': 0, // 订购数量
      'inventory|': '@natural(1, 20) 箱', // 库存
      'money|0-900.2': 0, // 金额
      'remark|0-900.2': 0, // 备注
      production_date: '@date(yyyy-MM-dd)', // 生产日期
    })
  )
}
module.exports = [
  {
    url: '/advance/clientSelect',
    type: 'get',
    response(config) {
      let data = mock({
        'client|7': [
          {
            'id|+1': 0,
            'value|1': ['@cname 超市', '@cname 烟酒'],
          },
        ],
      })
      return {
        code: 200,
        msg: 'success',
        data,
      }
    },
  },
  {
    url: '/advance/indexTableList',
    type: 'get',
    response(config) {
      const { title, pageNo = 1, pageSize = 10 } = config.query
      let mockList = List.filter((item) => {
        return !(title && item.cust_name.indexOf(title) < 0)
      })
      const pageList = mockList.filter(
        (item, index) =>
          index < pageSize * pageNo && index >= pageSize * (pageNo - 1)
      )
      return {
        code: 200,
        msg: 'success',
        totalCount: mockList.length,
        data: pageList,
      }
    },
  },
  {
    url: '/advance/editOrderTable',
    type: 'get',
    response(config) {
      let { title, pageNo = 1, pageSize = 10 } = config.query
      let mockList = List2.filter((item) => {
        return !(title && item.cust_name.indexOf(title) < 0)
      })
      let pageList = mockList.filter(
        (item, index) =>
          index < pageSize * pageNo && index >= pageSize * (pageNo - 1)
      )
      return {
        code: 200,
        msg: 'success',
        totalCount: mockList.length,
        data: pageList,
      }
    },
  },
  {
    url: '/advance/summaryTableList',
    type: 'get',
    response(config) {
      let mockList = [
        {
          depot: '总仓',
          delivery: '当日送达',
          code: 'XD5SA58WE54E56QW',
          client_name: '老牛烧烤',
          order_price: '161.1',
          sale_man: '张三',
          order_time: '2020.11.02',
        },
        {
          depot: 'A仓',
          delivery: '当日送达',
          code: 'XD5SA58WE54E56QW',
          client_name: '老牛烧烤',
          order_price: '161.1',
          sale_man: '张三',
          order_time: '2020.11.02',
        },
        {
          depot: 'C仓',
          delivery: '次日送达',
          code: 'XD5SA58WE54E56QW',
          client_name: '老牛烧烤',
          order_price: '161.1',
          sale_man: '张三',
          order_time: '2020.11.02',
        },
        {
          depot: 'A仓',
          delivery: '次日送达',
          code: 'XD5SA58WE54E56QW',
          client_name: '老牛烧烤',
          order_price: '161.1',
          sale_man: '张三',
          order_time: '2020.11.02',
        },
        {
          depot: 'C仓',
          delivery: '无要求',
          code: 'XD5SA58WE54E56QW',
          client_name: '老牛烧烤',
          order_price: '161.1',
          sale_man: '张三',
          order_time: '2020.11.02',
        },
        {
          depot: 'B仓',
          delivery: '无要求',
          code: 'XD5SA58WE54E56QW',
          client_name: '老牛烧烤',
          order_price: '161.1',
          sale_man: '张三',
          order_time: '2020.11.02',
        },
      ]
      return {
        code: 200,
        msg: 'success',
        totalCount: mockList.length,
        data: mockList,
      }
    },
  },
]
