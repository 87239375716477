<template>
  <div class="register-container">
    <el-row>
      <el-col :lg="14" :md="11" :sm="24" :xl="14" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
      <el-col :lg="9" :md="12" :sm="24" :xl="9" :xs="24">
        <el-form
          ref="registerForm"
          :model="form"
          :rules="registerRules"
          class="register-form"
          size="mini"
        >
          <div class="title-tips">{{ translateTitle('注册') }}</div>
          <el-form-item prop="username">
            <el-input
              v-model.trim="form.username"
              v-focus
              :placeholder="translateTitle('请输入姓名')"
              auto-complete="off"
              type="text"
            >
              <vab-remix-icon slot="prefix" icon="user-line" />
            </el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input
              v-model.trim="form.phone"
              :placeholder="translateTitle('请输入电话')"
              maxlength="11"
              show-word-limit
              type="text"
            >
              <vab-remix-icon slot="prefix" icon="smartphone-line" />
            </el-input>
          </el-form-item>
          <el-form-item prop="province_id" class="select">
            <el-select
              v-model="form.province_id"
              clearable
              style=""
              placeholder="省"
              @change="shengChange"
            >
              <el-option
                v-for="(i, idx) in shengSelect"
                :key="idx"
                :value="i.id"
                :label="i.title"
              ></el-option>
              <!-- el-icon-location-information -->
            </el-select>
          </el-form-item>
          <el-form-item prop="province_id" class="select">
            <el-select
              v-model="form.city_id"
              style=""
              clearable
              placeholder="市"
            >
              <el-option
                v-for="(i, idx) in shiSelect"
                :key="idx"
                :value="i.id"
                :label="i.title"
              ></el-option>
              <!-- el-icon-location-outline -->
            </el-select>
          </el-form-item>

          <el-form-item prop="comapny">
            <el-input
              v-model.trim="form.comapny"
              :placeholder="translateTitle('请输入公司')"
              type="text"
            >
              <vab-remix-icon slot="prefix" icon="building-line" />
            </el-input>
          </el-form-item>

          <el-form-item label="" prop="indust_id" class="select">
            <el-select
              v-model="form.indust_id"
              clearable
              style="width: 180px"
              placeholder="请选择行业"
            >
              <vab-remix-icon slot="prefix" icon="briefcase-2-line" />

              <el-option
                v-for="(i, idx) in hangeyeSelect"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="post_id" class="select">
            <!-- <el-input
              v-model.trim="form.worker"
              :placeholder="translateTitle('请输入职位')"
              type="text"
            >
              <vab-remix-icon slot="prefix" icon="briefcase-2-line" />
            </el-input> -->
            <el-select
              v-model="form.post_id"
              style="width: 180px"
              clearable
              placeholder="请选择职务"
            >
              <vab-remix-icon slot="prefix" icon="briefcase-2-line" />
              <el-option
                v-for="(i, idx) in zhiwuSelect"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 验证码 -->
          <el-form-item label="" prop="code">
            <div style="display: flex; align-items: center">
              <el-input
                v-model="form.code"
                style="width: 180px"
                placeholder="请输入验证码"
              >
                <vab-remix-icon slot="prefix" icon="chat-private-line" />
              </el-input>
              <div
                style="margin-left: 10px; display: flex; align-items: center"
              >
                <img v-if="codeImg" :src="codeImg" alt="" style="width: 100%" />
              </div>
            </div>
            <el-link type="primary" @click="getCodeData">
              看不清，换一张
            </el-link>
          </el-form-item>
          <!-- <el-form-item prop="phoneCode" style="position: relative">
            <el-input
              v-model.trim="form.phoneCode"
              :placeholder="translateTitle('请输入手机验证码')"
              type="text"
            >
              <vab-remix-icon slot="prefix" icon="barcode-box-line" />
            </el-input>
            <el-button
              :disabled="isGetPhone"
              class="phone-code"
              type="primary"
              @click="getPhoneCode"
            >
              {{ phoneCode }}
            </el-button>
          </el-form-item> -->
          <!-- <el-form-item prop="password">
            <el-input
              v-model.trim="form.password"
              :placeholder="translateTitle('请输入密码')"
              autocomplete="new-password"
              type="password"
            >
              <vab-remix-icon slot="prefix" icon="lock-line" />
            </el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button
              class="register-btn"
              type="primary"
              @click.native.prevent="handleRegister"
            >
              {{ translateTitle('注册') }}
            </el-button>
            <router-link to="/login">
              <div style="margin-top: 20px">
                {{ translateTitle('登录') }}
              </div>
            </router-link>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :lg="1" :md="1" :sm="24" :xl="1" :xs="24">
        <div style="color: transparent">占位符</div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import { translateTitle } from '@/utils/i18n'
  import { isPassword, isPhone } from '@/utils/validate'
  import { register } from '@/api/user'
  import { areaSelectList2 } from '@/api/admin/userManagement'
  import { getCode } from '@/api/Employee'
  export default {
    name: 'Register',
    directives: {
      focus: {
        inserted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    data() {
      const validateusername = (rule, value, callback) => {
        if ('' === value) {
          callback(new Error(this.translateTitle('用户名不能为空')))
        } else {
          callback()
        }
      }
      const validatePassword = (rule, value, callback) => {
        if (!isPassword(value)) {
          callback(new Error(this.translateTitle('密码不能少于6位')))
        } else {
          callback()
        }
      }
      const validatePhone = (rule, value, callback) => {
        if (!isPhone(value)) {
          callback(new Error(this.translateTitle('请输入正确的手机号')))
        } else {
          callback()
        }
      }
      return {
        isGetPhone: false,
        getPhoneInterval: null,
        phoneCode: this.translateTitle('获取验证码'),
        showRegister: false,
        nodeEnv: process.env.NODE_ENV,
        form: {
          city_id: '',
        },
        codeImg: null,
        shengSelect: [],
        shiSelect: [],
        registerRules: {
          username: [
            {
              required: true,
              trigger: 'blur',
              message: this.translateTitle('请输入用户名'),
            },
            { validator: validateusername, trigger: 'blur' },
          ],
          code: [
            {
              required: true,
              trigger: 'blur',
              message: this.translateTitle('请输入验证码'),
            },
          ],
          phone: [
            {
              required: true,
              trigger: 'blur',
              message: this.translateTitle('请输入手机号'),
            },
            { validator: validatePhone, trigger: 'blur' },
          ],
          comapny: [
            {
              required: true,
              trigger: 'blur',
              message: this.translateTitle('请输入公司'),
            },
            // { validator: validatePhone, trigger: 'blur' },
          ],
          post_id: [
            {
              required: true,
              trigger: 'blur',
              message: this.translateTitle('请输入职位'),
            },
            // { validator: validatePhone, trigger: 'blur' },
          ],
          indust_id: [
            {
              required: true,
              trigger: 'blur',
              message: this.translateTitle('请输入行业'),
            },
            // { validator: validatePhone, trigger: 'blur' },
          ],
          province_id: [
            {
              required: true,
              trigger: 'blur',
              message: this.translateTitle('请输入地址'),
            },
            // { validator: validatePhone, trigger: 'blur' },
          ],
          password: [
            {
              required: true,
              trigger: 'blur',
              message: this.translateTitle('请输入密码'),
            },
            // { validator: validatePassword, trigger: 'blur' },
          ],
          phoneCode: [
            {
              required: true,
              trigger: 'blur',
              message: this.translateTitle('请输入手机验证码'),
            },
          ],
        },
        loading: false,
        passwordType: 'password',
        // 行业下拉
        hangeyeSelect: [
          {
            id: 1,
            name: '酒类',
          },
          {
            id: 2,
            name: '食品',
          },
          {
            id: 3,
            name: '饮品',
          },
          {
            id: 4,
            name: '乳品',
          },
          {
            id: 5,
            name: '调味品',
          },
          {
            id: 6,
            name: '进口食品',
          },
          {
            id: 7,
            name: '冻品',
          },
          {
            id: 8,
            name: '茶叶',
          },
          {
            id: 9,
            name: '食材',
          },
          {
            id: 10,
            name: '农业',
          },
          {
            id: 11,
            name: '其他',
          },
        ],
        // 职务下拉
        zhiwuSelect: [
          {
            name: '老板',
            id: 1,
          },
          {
            name: '经理',
            id: 2,
          },
          {
            name: '内勤',
            id: 3,
          },
          {
            name: '财务',
            id: 4,
          },
          {
            name: '库管',
            id: 5,
          },
          {
            name: '业务',
            id: 6,
          },
          {
            name: '司机',
            id: 7,
          },
          {
            name: '其它',
            id: 8,
          },
        ],
      }
    },
    created() {
      document.body.style.overflow = 'hidden'
      this.getArea()
    },
    beforeDestroy() {
      document.body.style.overflow = 'auto'
      this.getPhoneInterval = null
      clearInterval(this.getPhoneInterval)
    },
    mounted() {
      // 获取验证码
      this.getCodeData()
    },
    methods: {
      translateTitle,
      getArea(id = null) {
        areaSelectList2({ parent_id: id }).then((r) => {
          this.shengSelect = r.data
        })
      },
      getArea2(id = null) {
        areaSelectList2({ parent_id: id }).then((r) => {
          this.shiSelect = r.data
        })
      },
      getPhoneCode() {
        this.isGetPhone = true
        let n = 60
        this.getPhoneInterval = setInterval(() => {
          if (n > 0) {
            n--
            this.phoneCode = this.translateTitle('获取验证码 ') + n + 's'
          } else {
            this.getPhoneInterval = null
            clearInterval(this.getPhoneInterval)
            this.phoneCode = this.translateTitle('获取验证码')
            this.isGetPhone = false
          }
        }, 1000)
      },
      // 省 改变
      shengChange(v) {
        console.log('', v)
        // if (v != '') {

        // } else {
        this.form.city_id = ''
        this.shiSelect = []
        this.getArea2(v)
        // }
      },
      handleRegister() {
        this.$refs['registerForm'].validate(async (valid) => {
          if (valid) {
            const param = {
              dealer_name: this.form.username,
              phone: this.form.phone,
              // 省id
              province_id: this.form.province_id,
              // 市id
              city_id: this.form.city_id,
              // 职位
              // worker: this.form.worker,
              // 公司
              comapny: this.form.comapny,
              password: this.form.password,
              phoneCode: this.form.phoneCode,
              post_id: this.form.post_id,
              indust_id: this.form.indust_id,
              code: this.form.code,
            }
            const { msg } = await register(param)
            this.getCodeData()
            this.form.code = ''
            this.$baseMessage(msg, 'success')
          }
        })
      },
      // 获取验证码
      getCodeData() {
        getCode()
          .then((r) => {
            console.log('r', r)
            let src = window.URL.createObjectURL(r)
            this.codeImg = src
          })
          .catch((r) => {
            this.$message.error('获取验证码失败')
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .register-container {
    height: 100vh;
    min-height: 700px;
    background: url('~@/assets/login_images/background.jpg') center center fixed
      no-repeat;
    background-size: cover;

    .register-form {
      position: relative;
      max-width: 100%;
      padding: 2vh;
      margin: calc((100vh - 740px) / 2) 5vw 5vw;
      overflow: hidden;
      background: url('~@/assets/login_images/login_form.png');
      background-size: 100% 100%;

      .title-tips {
        margin-top: 29px;
        font-size: 26px;
        font-weight: 400;
        color: $base-color-white;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .register-btn {
        display: inherit;
        width: 220px;
        height: 50px;
        margin-top: 5px;
        border: 0;

        &:hover {
          opacity: 0.9;
        }
      }

      .phone-code {
        position: absolute;
        top: 8px;
        right: 10px;
        width: 120px;
        height: 32px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        user-select: none;
        border-radius: 3px;
      }
    }

    .tips {
      margin-bottom: 10px;
      font-size: $base-font-size-default;
      color: $base-color-white;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    ::v-deep {
      .el-form-item {
        padding-right: 0;
        margin: 20px 0;
        color: #454545;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 2px;

        i {
          position: absolute;
          top: 8px;
          left: 5px;
          z-index: $base-z-index;
          font-size: 16px;
          color: #d7dee3;
          cursor: pointer;
          user-select: none;
        }

        &__content {
          min-height: $base-input-height;
          line-height: $base-input-height;
        }

        &__error {
          position: absolute;
          top: 100%;
          left: 18px;
          font-size: $base-font-size-small;
          line-height: 18px;
          color: $base-color-red;
        }
      }

      .el-input {
        box-sizing: border-box;

        input {
          height: 48px;
          padding-left: 35px;
          font-size: $base-font-size-default;
          line-height: 58px;
          color: $base-font-color;
          background: #f6f4fc;
          border: 0;
          caret-color: $base-font-color;
        }
      }
    }
  }
  .select {
    display: flex;
    flex: 1;
    ::v-deep {
      .el-form-item,
      .el-select__caret,
      .el-input__icon,
      .el-icon-arrow-up {
        top: 0 !important;
      }
    }
  }
</style>
