var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { modal: false, visible: _vm.receipt, title: _vm.title },
          on: {
            "update:visible": function ($event) {
              _vm.receipt = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showtit == 1 && _vm.form.bill_status == 3,
                      expression: "showtit == 1 && form.bill_status == 3",
                    },
                  ],
                  staticStyle: {
                    position: "absolute",
                    top: "23px",
                    left: "160px",
                    color: "#0aaafa",
                  },
                },
                [
                  _vm._v(
                    " 作废人：" +
                      _vm._s(_vm.form.scrap_name) +
                      " 作废时间：" +
                      _vm._s(_vm.form.scrap_time) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-row",
                { staticClass: "dingwei" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            model: _vm.form,
                            inline: "",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户名称" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  disabled: "",
                                  placeholder: "客户名称",
                                },
                                model: {
                                  value: _vm.form.cust_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cust_name", $$v)
                                  },
                                  expression: "form.cust_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系人" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "220px" },
                                attrs: { disabled: "", placeholder: "联系人" },
                                model: {
                                  value: _vm.form.boss,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "boss", $$v)
                                  },
                                  expression: "form.boss",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款类型" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  disabled: "",
                                  placeholder: "收款类型",
                                },
                                model: {
                                  value: _vm.form.bill_type_text,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bill_type_text", $$v)
                                  },
                                  expression: "form.bill_type_text",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款时间" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  disabled: "",
                                  placeholder: "收款时间",
                                },
                                model: {
                                  value: _vm.form.add_time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "add_time", $$v)
                                  },
                                  expression: "form.add_time",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "原始单据" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCheckOrderori(
                                            _vm.form
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.order_ori_code) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.bill_type == 7,
                                  expression: "form.bill_type == 7",
                                },
                              ],
                              attrs: { label: "抵欠款单据" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckOrder(_vm.form)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.form.order_return_code) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div"),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款人", prop: "user_id" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "220px" },
                                attrs: { disabled: "", placeholder: "收款人" },
                                model: {
                                  value: _vm.form.add_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "add_name", $$v)
                                  },
                                  expression: "form.add_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "收款金额", prop: "clear_type" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  disabled: "",
                                  placeholder: "收款金额",
                                },
                                model: {
                                  value: _vm.form.bill_amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bill_amount", $$v)
                                  },
                                  expression: "form.bill_amount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "420px" },
                                attrs: {
                                  type: "textarea",
                                  disabled: _vm.showtit == 1,
                                  placeholder: "备注",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        { attrs: { stripe: "", data: _vm.tableData } },
                        [
                          _vm._l(_vm.celund, function (item, index) {
                            return _c("el-table-column", {
                              key: index,
                              attrs: {
                                label: item.label,
                                align: item.align,
                                prop: item.prop,
                              },
                              scopedSlots: _vm._u(
                                [
                                  item.label == "收款方式"
                                    ? {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择付款方式",
                                                  disabled: _vm.showtit == 1,
                                                },
                                                model: {
                                                  value: row.pay_type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "pay_type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.pay_type",
                                                },
                                              },
                                              _vm._l(
                                                _vm.account,
                                                function (list) {
                                                  return _c("el-option", {
                                                    key: list.id,
                                                    attrs: {
                                                      label: list.name,
                                                      value: list.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      }
                                    : item.label == "金额"
                                    ? {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.showtit == 1,
                                                placeholder: "金额",
                                              },
                                              model: {
                                                value: row.amount,
                                                callback: function ($$v) {
                                                  _vm.$set(row, "amount", $$v)
                                                },
                                                expression: "row.amount",
                                              },
                                            }),
                                          ]
                                        },
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                          _vm.showtit != 1
                            ? _c("el-table-column", {
                                attrs: { label: "操作", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var $index = ref.$index
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handlerAdd(
                                                    $index,
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 添加 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handlerDelete(
                                                    $index,
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 删除 ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  937840803
                                ),
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-row", { staticClass: "ziding" }, [
                _vm._v(" " + _vm._s(_vm.form.bill_status_text) + " "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showtit == 2,
                      expression: "showtit == 2",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handlersave },
                },
                [_vm._v(" 保 存 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.receipt = !_vm.receipt
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
              _c("sale-order", { ref: "saleOrder" }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }