import request from '@/utils/request'

// 获取商品列表
export function getGoodsList(data) {
  return request({
    url: '/baseAdmin/goods/index',
    method: 'post',
    data,
  })
}

// 获取商品详情
export function getGoodsDetail(data) {
  return request({
    url: '/baseAdmin/goods/view',
    method: 'post',
    data,
  })
}

// 添加商品
export function creatGoods(data) {
  return request({
    url: '/baseAdmin/goods/create',
    method: 'post',
    data,
  })
}

// 修改商品
export function editGoods(data) {
  return request({
    url: '/baseAdmin/goods/update',
    method: 'post',
    data,
  })
}

// 删除
export function deleteGoods(data) {
  return request({
    url: '/baseAdmin/goods/delete',
    method: 'post',
    data,
  })
}

// 排序
export function someF(data) {
  return request({
    url: '/baseAdmin/goods/change-field',
    method: 'post',
    data,
  })
}

// 复制一行
export function copyRow(data) {
  return request({
    url: '/baseAdmin/goods/copy',
    method: 'post',
    data,
  })
}

// 封存商品
export function saveGoods(data) {
  return request({
    url: '/baseAdmin/goods/is-close',
    method: 'post',
    data,
  })
}

// 后台商品导出
export function exportGoods(data) {
  return request({
    responseType: 'blob',
    url: '/baseAdmin/goods/export-goods',
    method: 'post',
    data,
  })
}
// 实时库存导出
export function exportDepot(data) {
  return request({
    responseType: 'blob',
    url: '/depotAdmin/depot-stock/export',
    method: 'post',
    data,
  })
}
// 实时库存2
// 实时库存导出2
export function exportDepot2(data) {
  return request({
    responseType: 'blob',
    url: '/depotAdmin/depot-stock/export2',
    method: 'post',
    data,
  })
}
/**
 * @批量修改商品
 */
export function batchEdit(data) {
  return request({
    url: '/baseAdmin/goods/batch-update',
    method: 'post',
    data,
  })
}
