<template>
  <el-dialog :title="title" :visible.sync="shouNewAdd" center fullscreen>
    <div>
      <el-form ref="form" :model="form" inline>
        <el-form-item label="购货方:" prop="cust_name">
          <client-search
            ref="clientSearch"
            :popper-class="'selectDC'"
            style="width: 192.6px"
            @select-val-alldata="clientSelect"
          ></client-search>
        </el-form-item>
        <el-form-item label="仓库:" prop="depot_name">
          <el-select
            v-model="form.depot_id"
            clearable
            placeholder="请选择"
            style="width: 180px"
            size="mini"
          >
            <el-option
              v-for="(i, idx) in depotSelect"
              :key="idx"
              :value="i.id"
              :label="i.depot_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="allSelectList.ckzx_falg == 1"
          label="业务员:"
          prop="staff_id"
        >
          <el-select
            v-model="form.staff_id"
            clearable
            style="width: 120px"
            size="mini"
          >
            <el-option
              v-for="(i, idx) in staffList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快捷输入:">
          <el-autocomplete
            v-model="remark"
            style="width: 220px"
            size="mini"
            :disabled="tableEdit"
            class="inline-input"
            value-key="goods_name"
            :fetch-suggestions="handlerremark"
            placeholder="请输入内容"
            :trigger-on-focus="false"
            @select="selectremark"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="联系人:" prop="boss">
          <el-input
            v-model="form.boss"
            disabled
            style="width: 120px"
            size="mini"
          />
        </el-form-item>
        <el-form-item label="电话:" prop="mobile">
          <el-input
            v-model="form.mobile"
            disabled
            style="width: 120px"
            size="mini"
          />
        </el-form-item>

        <el-form-item label="地址:" prop="address">
          <el-input
            v-model="form.address"
            disabled
            style="width: 620px"
            size="mini"
          ></el-input>
        </el-form-item>

        <el-table
          ref="tableSort"
          :data="tablelist"
          height="560"
          border
          size="mini"
          :summary-method="getSummaries"
          show-summary
          style="width: 100%"
          @header-dragend="hanlerdragend"
        >
          <el-table-column type="index" width="40px">
            <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div>
                    <el-checkbox-group v-model="checkList">
                      <el-checkbox
                        v-for="(item, index) in columns"
                        :key="index"
                        :label="item.label"
                      ></el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <!--                <el-divider direction="vertical"/>
                <div>
                  <el-checkbox-group v-model="editForm">
                    <el-checkbox
                      v-for="(list, index) in columns"
                      :key="index"
                      :disabled="!list.Tabkey"
                      :label="list.label"
                    ></el-checkbox>
                  </el-checkbox-group>
                </div>-->
                </div>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, tableIndex) in fluterList"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row, $index }">
              <template
                v-if="
                  currentEditingField == item.prop &&
                  editingItem &&
                  editingRowIndex === $index
                "
              >
                <template v-if="item.prop === 'type'">
                  <el-select
                    v-model="row.goods_type"
                    v-focus
                    placeholder="请选择"
                    style="width: 100%"
                    @change="
                      typeChange($event, row), utilsF(), moneyN(row, $index)
                    "
                    @keydown.tab.native="
                      handleTabKey($event, row, item.prop, $index)
                    "
                  >
                    <el-option
                      v-for="item_ in row.arr_goods_type"
                      :key="item_.id"
                      :label="item_.name"
                      :value="item_.id"
                    ></el-option>
                  </el-select>
                </template>
                <template v-else-if="item.prop === 'goods_name'">
                  <goods-search
                    ref="goodsSearch"
                    v-focus
                    :f-key="row.goods_name"
                    :kouwei="row.child"
                    :rowf="row"
                    :cust-id="cust_id"
                    :depot-id="form.depot_id"
                    @add-rows="addRows"
                    @change-kouwei="changeKouwei($event, row)"
                    @select-goods-all="handleSelect($event, row, $index)"
                    @keydown.tab.native="
                      handleTabKey($event, row, item.prop, $index)
                    "
                  ></goods-search>
                </template>
                <template v-else-if="item.prop === 'unit_name'">
                  <el-select
                    v-model="row['unit_id']"
                    v-focus
                    placeholder="请选择"
                    style="width: 100%"
                    @keydown.tab.native="
                      handleTabKey($event, row, item.prop, $index)
                    "
                    @change="unitChange($event, row)"
                  >
                    <el-option
                      v-for="item_ in row.arr_unit"
                      :key="item_.id"
                      :label="item_.unit_name"
                      :value="item_.id"
                    />
                  </el-select>
                </template>
                <template v-else-if="item.prop === 'goods_price'">
                  <el-input
                    v-model="row[item.prop]"
                    v-focus
                    placeholder="商品单价"
                    onkeyup="value=value.replace(/[^\-?\d.]/g,'')"
                    @input="moneyN(row, $index), utilsF()"
                    @keydown.tab.native="
                      handleTabKey($event, row, item.prop, $index)
                    "
                  ></el-input>
                </template>
                <template v-else-if="item.prop === 'quantity'">
                  <el-input
                    v-model="row[item.prop]"
                    v-focus
                    onkeyup="value=value.replace(/[^\-?\d.]/g,'')"
                    placeholder="数量"
                    @input="moneyN(row, $index), utilsF()"
                    @keydown.tab.native="
                      handleTabKey($event, row, item.prop, $index)
                    "
                  ></el-input>
                </template>
                <template v-else-if="item.prop === 'goods_money'">
                  <el-input
                    v-model="row[item.prop]"
                    v-focus
                    placeholder="金额"
                    @input="summaryInput($event, row), utilsF()"
                    @keydown.tab.native="
                      handleTabKey($event, row, item.prop, $index)
                    "
                  ></el-input>
                </template>
                <template v-else-if="item.prop === 'goods_production_date'">
                  <el-date-picker
                    v-model="row.goods_production_date"
                    v-focus
                    type="date"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    style="width: 100%"
                    @keydown.tab.native="
                      handleTabKey($event, row, item.prop, $index)
                    "
                  ></el-date-picker>
                </template>
                <template v-else-if="item.prop === 'info'">
                  <el-input
                    v-model="row.info"
                    v-focus
                    placeholder="商品备注"
                    @keydown.tab.native="
                      handleTabKey($event, row, item.prop, $index)
                    "
                  ></el-input>
                </template>
                <template v-else>
                  {{ row[item.prop] }}
                </template>
              </template>
              <template v-else>
                <div
                  class="cell-content"
                  @click="
                    editItem(row, $index)
                    currentEditingField = item.prop
                  "
                >
                  <template
                    v-if="
                      item.prop === 'type' ||
                      item.prop === 'goods_name' ||
                      item.prop === 'unit_name' ||
                      item.prop === 'goods_price' ||
                      item.prop === 'quantity' ||
                      item.prop === 'goods_money' ||
                      item.prop === 'goods_production_date' ||
                      item.prop === 'info'
                    "
                  >
                    <div>
                      {{ row[item.prop] || '  ' }}
                      <i
                        v-show="!row[item.prop]"
                        class="el-icon-caret-right trsher"
                      />
                    </div>
                  </template>
                  <template v-else>
                    {{ row[item.prop] || '  ' }}
                  </template>
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作">
            <template #default="{ row, $index }">
              <el-button
                type="text"
                @click.native.prevent="copyRow($index, row)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="deleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px"></div>
        <el-form-item label="订单金额">
          <el-input v-model="form.total_amount" disabled />
        </el-form-item>
        <el-form-item label="结算方式">
          <el-select
            v-model="form.account_type"
            clearable
            style="width: 192.6px"
            placeholder="结算方式"
          >
            <el-option
              v-for="(i, idx) in jsfs"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" style="width: 800px" />
        </el-form-item>
        <div></div>
        <el-form-item label="销售金额">
          <el-input v-model.trim="form.sale_amount" disabled />
        </el-form-item>
        <el-form-item label="退货金额">
          <el-input v-model.trim="form.refund_goods_amount" disabled />
        </el-form-item>
        <el-form-item label="退货方式">
          <el-select
            v-model="form.refund_type"
            placeholder="退货方式"
            style="width: 186px"
            @change="utilsF"
          >
            <el-option
              v-for="item_ in allSelectList.arr_refund_type"
              :key="item_.id"
              :label="item_.name"
              :value="item_.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退货退款">
          <el-input v-model.trim="form.refund_amount" disabled />
        </el-form-item>
        <el-form-item label="优惠">
          <el-input v-model.trim="form.discount_amount" @input="utilsF()" />
        </el-form-item>
        <el-form-item label="应收款">
          <el-input v-model="form.receiv_money" />
        </el-form-item>
        <el-form-item
          v-for="(item1, index) in pay_info"
          :key="index"
          label="实收款:"
        >
          <label style="display: flex">
            <div>
              <el-input
                v-model.trim="item1.money"
                type="number"
                style="width: 90px; margin-right: 20px"
              ></el-input>
              <el-select v-model="item1.pay_type" style="width: 90px">
                <el-option
                  v-for="item_1 in allSelectList.arr_pay_type"
                  :key="item_1.id"
                  :label="item_1.name"
                  :value="item_1.id"
                ></el-option>
              </el-select>
            </div>
            <i
              v-if="index == 0"
              class="el-icon-circle-plus addbtn"
              @click="addInput"
            ></i>
          </label>
        </el-form-item>
      </el-form>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-button type="primary" @click="handleSave">保 存</el-button>
        <el-button @click="shouNewAdd = false">取 消</el-button>
      </div>
    </div>
    <!--    <div-->
    <!--      slot="footer"-->
    <!--      class="dialog-footer"-->
    <!--      style="margin-top: 5px; text-align: right"-->
    <!--    >-->
    <!--     -->
    <!--    </div>-->
  </el-dialog>
</template>
<script>
  // 添加自动聚焦指令
  import GoodsSearch from '@/baseComponents/goodsSearch/index.vue'
  import ClientSearch from '@/baseComponents/clientSearch/index.vue'
  import { getStaffList } from '@/api/setPrice'
  import { getAllSelect } from '@/api/advanceOrder'
  import { postAction } from '@/api/Employee'
  import { getInputList } from '@/api/goodsSearchInput'
  import { depotList, saveDepotSale } from '@/api/depotManagement'
  import Template from '@/views/project/admin/systemManagement/adminAccount/components/template.vue'
  import { utils } from '@/utils/AOform'

  const vFocus = {
    inserted: (el) => {
      // 对于 Element UI 的组件，需要聚焦其内部的输入元素
      const input = el.querySelector('input') || el
      input.focus()
    },
  }
  export default {
    name: 'NewAdd',
    components: { ClientSearch, GoodsSearch },
    directives: {
      focus: vFocus,
    },
    data() {
      return {
        shouNewAdd: false,
        title: '新增仓库直销单',
        currentEditingField: null,
        editingItem: null,
        editingRowIndex: null,
        form: {
          cust_name: '',
          staff_id: '',
          boss: '',
          mobile: '',
          address: '',
          depot_id: '',
          discounted_amount: 0,
          discount_amount: 0,
          support_amount: 0,
          fun_ids: [],
          refund_type: 1,
          account_type: '',
          remark: '',
          receiv_money: 0,
          refund_amount: 0,
          refund_goods_amount: 0,
          total_amount: 0,
          sale_amount: 0,
        },
        cust_id: '',
        remark: '',
        allSelectList: {},
        depotSelect: [],
        tablelist: [],
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '条码',
          '单位',
          '单价',
          '数量',
          '可用库存',
          '实际库存',
          '金额',
          '生产日期',
          '备注',
        ],
        editForm: [
          '类型',
          '商品名称',
          '单位',
          '单价',
          '数量',
          '金额',
          '生产日期',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '类型',
            prop: 'type',
            width: '130px',
            Tabkey: true,
          },
          {
            order: 2,
            label: '商品名称',
            prop: 'goods_name',
            width: '220px',
            Tabkey: true,
          },
          {
            order: 2.1,
            label: '规格',
            prop: 'specs',
            width: '80px',
            Tabkey: false,
          },
          {
            order: 2.2,
            label: '条码',
            width: '140px',
            prop: 'unit_code',
            sortable: false,
            Tabkey: false,
          },
          {
            order: 3,
            label: '单位',
            prop: 'unit_name',
            width: '90px',
            Tabkey: true,
          },
          {
            order: 4,
            label: '单价',
            prop: 'goods_price',
            width: '100px',
            Tabkey: true,
          },
          {
            order: 5,
            label: '数量',
            prop: 'quantity',
            width: '100px',
            Tabkey: true,
          },
          {
            order: 5.1,
            label: '可用库存',
            prop: 'depot_stock_num_text',
            width: '100px',
            Tabkey: false,
          },
          {
            order: 5.2,
            label: '实际库存',
            prop: 'balance_count_read',
            width: '100px',
            Tabkey: false,
          },
          {
            order: 6,
            label: '金额',
            prop: 'goods_money',
            width: '100px',
            Tabkey: true,
          },
          {
            order: 6.1,
            label: '生产日期',
            prop: 'goods_production_date',
            width: '180px',
            Tabkey: true,
          },
          {
            order: 7,
            label: '备注',
            prop: 'info',
            width: '200px',
            Tabkey: true,
          },
        ],
        jsfs: [
          {
            id: 1,
            name: '货到付款',
          },
          {
            id: 2,
            name: '账期结算',
          },
        ],
        pay_info: [{ pay_type: '', money: '0' }],
      }
    },
    computed: {
      tableEdit() {
        if (this.form.depot_id && this.cust_id) {
          return false
        } else {
          return true
        }
      },
      fluterList() {
        this.saveColumnSettings()
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
      editableFields() {
        return this.editForm.map((item) => {
          return this.fluterList
            .filter((col) => col.label == item)
            .map((col) => col.prop)[0]
          // this.fluterList.forEach(list => {
          //   if (item == list.label) {
          //     return list.prop
          //   }
          // })
        })
      },
    },
    watch: {
      shouNewAdd(val) {
        if (val) {
          this.tablelist = [
            {
              activity_code: '',
              activity_id: 0,
              activity_type: 0,
              balance_count: 0,
              balance_count_read: '',
              depot_stock_num: 0,
              depot_stock_num_text: '',
              edit: true,
              goods_child_id: null,
              goods_money: '',
              goods_name: '',
              goods_price: '',
              goods_production_date: '',
              goods_type: '',
              info: '',
              money: '',
              quantity: '',
              specs: '',
              unit_id: '',
            },
          ]
          depotList().then((res) => {
            console.log(res, '库存1111111111111111111111')
            // 获取默认值
            let d = res.data.filter((i) => i.is_default == 1)[0]
            console.log('ddd', d)
            this.form.depot_id = d.id
            this.depotSelect = res.data
          })
          this.getAllSelectList()
        } else {
          this.clearForm()
        }
      },
    },
    created() {
      this.loadColumnSettings()
      window.addEventListener('keydown', (e) => {
        if (e.key === 'Enter' && this.editingItem) {
        } else if (e.key === 'Escape' && this.editingItem) {
          this.editingItem = null
          this.currentEditingField = null
        }
      })
    },
    mounted() {
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
    },
    updated() {
      this.$nextTick(() => {
        this.$refs.tableSort.doLayout()
      })
    },
    methods: {
      clearForm() {
        this.$refs.form.resetFields()
        this.$refs.clientSearch.resetForm()
        this.pay_info = [{ pay_type: '', money: '0' }]
        this.cust_id = ''
        this.form.depot_id = ''
        this.form.staff_id = ''
        this.tablelist = [
          {
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            balance_count_read: '',
            depot_stock_num: 0,
            depot_stock_num_text: '',
            edit: true,
            goods_child_id: null,
            goods_money: '',
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            info: '',
            money: '',
            quantity: '',
            specs: '',
            unit_id: '',
          },
        ]
      },
      handleSave() {
        let list = JSON.parse(JSON.stringify(this.tablelist))
        let goods_info = []
        list.forEach((item) => {
          if (item.goods_id) {
            goods_info.push({
              goods_id: item.goods_id,
              unit_id: item.unit_id,
              goods_child_id: item.goods_child_id,
              quantity: item.quantity,
              goods_price: item.goods_price,
              goods_money: item.goods_money,
              goods_production_date: item.goods_production_date,
              info: item.info,
              activity_id: item.activity_id,
              activity_count: item.activity_count,
              goods_type: item.goods_type,
            })
          }
        })
        console.log(goods_info)
        let pay_info_ = JSON.parse(JSON.stringify(this.pay_info))
        let pay_type = []
        pay_info_.forEach((item) => {
          if (item.money) {
            pay_type.push(item)
          }
        })
        let order_info = {
          depot_id: this.form.depot_id,
          discounted_amount: this.form.discounted_amount,
          refund_type: this.form.refund_type,
          account_type: this.form.account_type,
          remark: this.form.remark,
          fun_ids: this.form.fun_ids.join(','),
          support_amount: this.form.support_amount,
        }
        let end = {
          cust_id: this.cust_id,
          staff_id: this.form.staff_id,
          order_info: JSON.stringify(order_info),
          pay_info: JSON.stringify(pay_type),
          goods_info: JSON.stringify(goods_info),
        }
        console.log(end)
        saveDepotSale(end).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.shouNewAdd = false
        })
      },
      //购货方
      clientSelect(val) {
        console.log(val)
        if (val) {
          this.form.is_fun = val.is_fun
          this.cust_id = val.id
          this.form.boss = val.boss
          this.form.mobile = val.mobile
          this.form.address = val.address
          this.form.account_type = Number(val.pay_way)
        }
        postAction('/saleAdmin/order-pre/funds-option', {
          cust_id: val.id,
        }).then((res) => {
          this.funselect = res.data.bill_list
          this.funds_zong = res.data.funds_zong
        })
      },
      //选项下拉
      async getAllSelectList() {
        let { data, code, msg } = await getAllSelect()
        if (code == 200) {
          console.log('所有下拉列表2')
          console.log(data)
          this.allSelectList = data
          this.allSelectList.arr_pay_type.forEach((i) => {
            if (i.name == '现金') {
              this.pay_info[0].pay_type = i.id
            }
          })
        }
      },
      //快捷录入
      async handlerremark(queryString, cb) {
        const { data } = await getInputList({
          keyword: queryString,
          depot_id: this.form.depot_id,
          custom_id: this.form.id,
        })
        if (data.length > 0) {
          let goodsdata = data.map((item, index) => {
            try {
              item.arr_unit.forEach((unit) => {
                if (unit.is_default == 2) {
                  item.unit_id = unit.id
                  item.goods_price = unit.sell_price
                  item.unit_name = unit.unit_name
                }
              })
              item.goods_type = 1
              item.type = '销常'
            } catch (err) {}
            return item
          })
          cb(goodsdata)
        } else {
          cb([])
        }
      },
      //快捷录入选择
      selectremark(item) {
        console.log(item)
        this.tablelist.unshift(item)
      },
      //添加商品行
      addRows(val) {
        console.log('添加rows')
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            item.arr_unit.forEach((unit) => {
              if (item.unit_id == unit.unit_id || unit.is_default == 2) {
                item.unit_name = unit.unit_name
                item.goods_price = unit.sell_price
              }
            })
            this.tablelist.splice(this.tablelist.length - 1, 0, {
              ...item,
              type: '销常',
            })
          })
          this.$message.success('添加成功')
          // this.utilsF()
        }
      },
      //单行添加商品
      handleSelect(val, row, index) {
        console.log(val, row, index, '批量添加')
        Object.assign(row, val, { goods_type: 1, type: '销常' })
        row.id = ''
        console.log('asdasd')
        if (val.arr_unit.length != 0) {
          row.unit_name = row.arr_unit.filter(
            (item) => item.unit_id == val.unit_id
          )[0].unit_name
        }
        row.goods_type_text = row.arr_goods_type.filter(
          (item) => item.id == row.goods_type
        )[0].name
        if (row.child.length > 0) {
          row.goods_name_print = row.goods_name
        }
        this.tablelist.push({
          activity_code: '',
          activity_id: 0,
          activity_type: 0,
          balance_count: 0,
          depot_stock_num: 0,
          edit: true,
          goods_child_id: null,
          goods_id: '',
          goods_money: '',
          goods_name: '',
          goods_price: '',
          goods_production_date: '',
          goods_type: 1,
          info: '',
          money: '',
          quantity: '',
          specs: '',
          unit_id: '',
          unit_name: '',
        })
      },
      //商品口味
      changeKouwei(val, row) {
        console.log('改变口味', val)
        row.goods_child_id = val.id
        row.goods_name = val.goods_name + ` (${val.attr})`
      },
      typeChange(v, row) {
        console.log(v, row)
        let type = row.arr_goods_type.filter((item) => item.id == v)[0]
        row.type = type.name
        let unitId = row.arr_unit.filter((i) => i.id == row.unit_id)
        if (v == 6 || v == 10 || v == 11 || v == 13 || v == 14 || v == 19) {
          if (unitId.length > 0) {
            row.goods_price = unitId[0].return_price
          }
        } else if (v == 17) {
          row.goods_price = unitId[0].sell_price_te
        } else {
          row.goods_price = unitId[0].sell_price
        }
      },
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0]
        row.unit_name = price.unit_name
        row.goods_price = price.sell_price
        row.goods_money = (
          Number(price.sell_price) * Number(row.quantity)
        ).toFixed(2)
      },
      // 表格合计
      getSummaries(param) {
        const { columns, data } = param
        console.log(columns, data)
        const sums = []
        columns.forEach((item) => {
          sums.push('')
        })
        let nums = 0
        let shu = 0

        sums[0] = '合计'
        data.forEach((item) => {
          if (item.goods_type == 1) {
            let num = Number(
              (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
            )
            nums += num
          } else if (item.goods_type == 6) {
            let num = Number(
              (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
            )
            nums -= num
            // this.form.total_amount = nums
          }
        })
        data.forEach((item) => {
          if (item.goods_type == 8) {
            let num = Number(
              (Number(item.goods_price) * Number(item.quantity)).toFixed(2)
            )
            nums -= num
          }

          if (!isNaN(item.quantity)) {
            shu += Number(item.quantity)
          }
        })
        sums[7] = shu.toFixed(2)
        // sums[10] = nums.toFixed(2) + '元'
        sums[10] = this.form.total_amount
        // this.form.total_amount = nums.toFixed(2)
        return sums
      },
      copyRow(index, row) {
        let rowS = JSON.parse(JSON.stringify(row))
        let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.tablelist.splice(index + 1, 0, rowa)
        // this.list.push(rowa)
        console.log(rowa)
        this.utilsF()
      },
      deleteRow(index, row) {
        if (index == this.tablelist.length - 1) {
          // alert('不能删除最后一行')
          this.$message.error('不能删除最后一行')
          return
        }
        this.tablelist.splice(index, 1)
        this.utilsF()
      },
      utilsF() {
        utils(this.tablelist, this)
      },
      addInput() {
        this.pay_info.push({ pay_type: '', money: '0' })
      },
      // 计算金额数
      moneyN(item, index) {
        let num = 0
        // if (Number(item.quantity) && Number(item.goods_price)) {
        //   num = Number(item.quantity) * Number(item.goods_price)
        // }
        console.log(item)
        if (
          item.goods_type == 2 ||
          item.goods_type == 3 ||
          item.goods_type == 10 ||
          item.goods_type == 11
        ) {
          this.tablelist[index].goods_money = 0
        } else {
          num = Number(item.quantity) * Number(item.goods_price)
          this.tablelist[index].goods_money = num.toFixed(2)
        }
      },
      // 金额返算
      summaryInput(val, row) {
        console.log(val)
        row.goods_price = (Number(val) / row.quantity).toFixed(2)
      },

      editItem(row, index) {
        const editObj = { ...row }
        this.editingItem = editObj
        this.editingRowIndex = index
        // this.currentEditingField = this.editableFields[0]
      },
      saveEdit() {
        if (!this.editingItem) return

        const index = this.tablelist.findIndex(
          (item) => item.id === this.editingItem.id
        )
        if (index !== -1) {
          this.$set(this.tablelist, index, { ...this.editingItem })
        }
        this.editingItem = null
        this.currentEditingField = null
      },
      handleTabKey(e, row, field, index) {
        e.preventDefault()
        const fields = this.editableFields
        const currentIndex = fields.indexOf(field)

        if (e.shiftKey) {
          // Shift + Tab: 移动到上一个字段或上一行
          if (currentIndex > 0) {
            this.currentEditingField = fields[currentIndex - 1]
          } else {
            if (index > 0) {
              this.saveEdit()
              const prevRow = this.tablelist[index - 1]
              this.editItem(prevRow, index - 1)
              this.currentEditingField = fields[fields.length - 1]
            }
          }
        } else {
          // Tab: 移动到下一个字段或下一行
          if (currentIndex < fields.length - 1) {
            this.currentEditingField = fields[currentIndex + 1]
          } else {
            if (index < this.tablelist.length - 1) {
              this.saveEdit()
              const nextRow = this.tablelist[index + 1]
              this.editItem(nextRow, index + 1)
              this.currentEditingField = fields[0]
            } else {
              this.saveEdit()
            }
          }
        }

        /*  if (e.shiftKey) {
        // Shift + Tab: 移动到上一个字段或上一行
        if (currentIndex > 0) {
          this.currentEditingField = fields[currentIndex - 1]
        } else {
          const currentRowIndex = this.tablelist.findIndex(item => item.id === row.id)
          if (currentRowIndex > 0) {
            this.saveEdit()
            const prevRow = this.tablelist[currentRowIndex - 1]
            this.editItem(prevRow,index - 1)
            this.currentEditingField = fields[fields.length - 1]
          }
        }
      } else {
        console.log(currentIndex,this.tablelist,fields)
        // Tab: 移动到下一个字段或下一行
        if (currentIndex < fields.length - 1) {
          console.log(fields[currentIndex + 1])
          this.currentEditingField = fields[currentIndex + 1]
        } else {

          const currentRowIndex = this.tablelist.findIndex(item => item.id === row.id)

          if (currentRowIndex < this.tablelist.length - 1) {
            this.saveEdit()
            console.log(currentRowIndex)
            const nextRow = this.tablelist[currentRowIndex + 1]
            this.editItem(nextRow, index + 1)
            this.currentEditingField = fields[0]
          } else {
            this.saveEdit()
          }
        }
      }*/
      },
      hanlerdragend(newWidth, oldWidth, column) {
        this.columns = this.columns.map((i) => {
          if (i.label == column.label) {
            i.width = newWidth + 'px'
          }
          return i
        })
        console.log(this.columns)
        this.saveColumnSettings()
      },
      //保存列设置到本地
      saveColumnSettings() {
        try {
          localStorage.setItem('checkList', JSON.stringify(this.checkList))
          localStorage.setItem('tableColumns', JSON.stringify(this.columns))
        } catch (e) {
          console.error('Failed to save column settings:', e)
        }
      },
      // 配置保存和加载
      loadColumnSettings() {
        try {
          const savedColumns = localStorage.getItem('checkList')
          if (savedColumns) {
            console.log(savedColumns)
            this.checkList = JSON.parse(savedColumns)
          }
          const savedTableColumns = localStorage.getItem('tableColumns')
          if (savedTableColumns) {
            this.columns = JSON.parse(savedTableColumns)
          }
        } catch (e) {
          console.error('Failed to load column settings:', e)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .custom-table-checkbox > div > div {
    .el-checkbox {
      display: block !important;
      margin: 0 0 $base-padding/4 0;
    }
  }
  .cell-content {
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    transition: background-color 0.2s;
    min-height: 24px;
    display: block;
    width: 100%;
  }

  .cell-content:hover {
    background-color: #f5f7fa;
  }

  /* 编辑状态下的单元格样式 */
  .el-table .editing-row .cell-content {
    background-color: #f5f7fa;
  }

  /* 输入框样式优化 */
  .el-input.is-focus,
  .el-select .el-input.is-focus,
  .el-date-editor.is-focus {
    box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2);
  }

  /* 确保下拉菜单在编辑单元格之上 */
  .el-select-dropdown,
  .el-date-picker {
    z-index: 3000 !important;
  }
  .trsher {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(-45deg);
  }
  .addbtn {
    //position: absolute;
    //top: 0;
    //right: -50px;
    //margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
  .el-dialog__body {
    padding: 0px 25px 0px 25px !important;
  }
</style>
