<template>
  <div></div>
</template>

<script>
  export default {
    name: 'SummaryOrder',
    props: {},
    data() {
      return {}
    },
    created() {},
    mounted() {},
    methods: {},
  }
</script>
