<template>
  <el-dialog
    :modal="false"
    :title="title + '      ' + form.dealer_name"
    :visible.sync="showDialog"
    width="1200px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-loading="loading" class="orderTest-container">
      <!--      <h3>{{form.dealer_name}}</h3>-->
      <el-form
        ref="form"
        :model="form"
        inline
        :disabled="view"
        label-position="right"
        label-width="90px"
      >
        <!-- <div class="orderStatus">{{ form.pay_status_text }}</div> -->
        <order-status
          v-if="status != 0"
          ref="orderStatus"
          :status.sync="status"
        ></order-status>
        <el-form-item label="购货方:" prop="cust_name">
          <el-input v-model="form.cust_name"></el-input>
        </el-form-item>
        <el-form-item label="联系人:" prop="boss">
          <el-input v-model="form.boss" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="form.ckzx_falg == 1" label="业务员:" prop="boss">
          <!-- <el-input v-model="form.staff_id"></el-input> -->
          <el-select
            v-model="form.staff_id"
            clearable
            style="width: 200px"
            placeholder=""
          >
            <el-option
              v-for="(i, idx) in staffList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <div></div>
        <el-form-item label="电话:" prop="mobile">
          <el-input v-model="form.mobile" disabled></el-input>
        </el-form-item>
        <el-form-item label="出货仓库:" prop="depot_name">
          <el-input v-model="form.depot_name"></el-input>
        </el-form-item>

        <div></div>

        <el-form-item label="地址:" prop="address">
          <el-input
            v-model="form.address"
            disabled
            style="width: 360px"
          ></el-input>
        </el-form-item>
        <div></div>

        <el-form-item label="单号:" prop="bill_code">
          <el-input v-model="form.bill_code" disabled></el-input>
        </el-form-item>
        <el-form-item label="日期:" prop="sale_time">
          <el-input v-model="form.sale_time + form.create_name" disabled></el-input>
        </el-form-item>

        <el-form-item v-if="!view" label="商品条码:" prop=""></el-form-item>
        <el-table stripe :data="form.goods" style="margin-bottom: 15px">
          <!-- 序号 -->
          <el-table-column align="center" type="index" width="50">
            <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="index"
                    :label="item.label"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <!-- 选择框 -->
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          ></el-table-column>
        </el-table>
        <el-form-item label="备注:" prop="remark" style="width: 100%">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item
          label="结算方式:"
          prop="account_type_text"
          style="width: 100%"
        >
          <el-input v-model="form.account_type_text"></el-input>
        </el-form-item>
        <el-form-item label="订单金额:" prop="total_amount">
          <el-input v-model="form.total_amount"></el-input>
        </el-form-item>
        <el-form-item label="销售金额:" prop="sale_amount">
          <el-input v-model="form.sale_amount"></el-input>
        </el-form-item>
        <el-form-item label="使用预存款:" prop="deposit_amount">
          <el-input v-model="form.deposit_amount"></el-input>
        </el-form-item>
        <el-form-item label="还货金额:" prop="return_amount">
          <el-input v-model="form.return_amount"></el-input>
        </el-form-item>
        <el-form-item label="优惠后:" prop="discounted_amount">
          <el-input v-model="form.discounted_amount"></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="退货金额" prop="refund_goods_amount">
          <el-input v-model.trim="form.refund_goods_amount" disabled></el-input>
        </el-form-item>
        <el-form-item label="退货方式" prop="refund_type_text">
          <el-input v-model="form.refund_type_text" disabled></el-input>
        </el-form-item>
        <el-form-item label="退货退款:" prop="refund_amount">
          <el-input v-model="form.refund_amount"></el-input>
        </el-form-item>
        <el-form-item v-if="form.is_fun" label="费用抵扣" prop="thtk">
          <el-input v-model.trim="form.support_amount" disabled></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="应收款:" prop="receiv_money">
          <el-input v-model="form.receiv_money"></el-input>
        </el-form-item>
        <el-form-item label="实收款:" prop="pay_amount">
          <el-input v-model="form.pay_amount"></el-input>
        </el-form-item>

        <el-table stripe :data="form.arr_collect" show-summary>
          <el-table-column
            prop="pay_type_name"
            label="收款方式"
            width="160px"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="金额"
            width="160px"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_name"
            label="收款人"
            width="160px"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_at"
            label="时间"
            width="160px"
            align="center"
          ></el-table-column>
        </el-table>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import OrderStatus from '@/baseComponents/orderStatus'
  import { depotDetail } from '@/api/depotManagement'
  import { getStaffList } from '@/api/setPrice'

  export default {
    name: '',
    components: { OrderStatus },
    data() {
      return {
        title: '仓库直销单',
        showDialog: false,
        loading: false,
        view: true,
        staffList: [],
        id: '',
        status: '0',
        checkList: [
          '类型',
          '商品名称',
          '条码',
          '单位',
          '单价',
          '数量',
          '金额',
          '生产日期',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '类型',
            prop: 'goods_type_text',
            width: '',
          },
          {
            order: 2,
            label: '商品名称',
            prop: 'goods_name_print',
            width: '',
          },
          {
            order: 2.2,
            label: '条码',
            width: '110px',
            prop: 'unit_code',
            sortable: false,
          },
          {
            order: 3,
            label: '单位',
            prop: 'unit_name',
            width: '',
          },
          {
            order: 4,
            label: '单价',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 5,
            label: '数量',
            prop: 'quantity',
            width: '',
          },
          {
            order: 6,
            label: '金额',
            prop: 'goods_money',
            width: '',
          },
          {
            order: 7,
            label: '生产日期',
            prop: 'goods_production_date',
            width: '',
          },
          {
            order: 8,
            label: '备注',
            prop: 'info',
            width: '',
          },
        ],
        form: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.$refs.form.resetFields()
        }
      },
    },
    created() {},
    mounted() {
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, code, msg } = await depotDetail({ id: this.id })
        this.form = data
        this.form.staff_id = this.form.staff_id + ''
        // data.bill_status_text == '未结清' ? 'wjq' : data.bill_status_text ==
        console.log('data', data.bill_status_text)
        if (data.bill_status_text == '未结清') {
          this.status = 'wjq'
        } else if (data.bill_status_text == '已结清') {
          this.status = 'yjq'
        } else if (data.bill_status_text == '已作废') {
          this.status = 'yzf'
        } else if (data.bill_status_text == '待对账') {
          this.status = 'ddz'
        } else if (data.bill_status_text == '已对账') {
          this.status = 'ydz'
        } else {
          this.status = '0'
        }
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
