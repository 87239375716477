var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            fullscreen: "",
            modal: false,
            title: "预存款下单",
            center: "",
            visible: _vm.innerVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: "",
                    model: _vm.form,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "购货方：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.cust_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cust_name", $$v)
                          },
                          expression: "form.cust_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.boss,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "boss", $$v)
                          },
                          expression: "form.boss",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "470px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出货仓库：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { placeholder: "请选择出货仓库" },
                          on: { change: _vm.handlerchange },
                          model: {
                            value: _vm.orderData.order_info.depot_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.orderData.order_info,
                                "depot_id",
                                $$v
                              )
                            },
                            expression: "orderData.order_info.depot_id",
                          },
                        },
                        _vm._l(_vm.depot.depot, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务员：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { placeholder: "请选择业务员" },
                          model: {
                            value: _vm.orderData.order_info.staff_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.orderData.order_info,
                                "staff_id",
                                $$v
                              )
                            },
                            expression: "orderData.order_info.staff_id",
                          },
                        },
                        _vm._l(_vm.depot.staff, function (lists, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: lists.name, value: lists.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "送货人：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { placeholder: "请选择送货人" },
                          model: {
                            value: _vm.orderData.order_info.deliver_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.orderData.order_info,
                                "deliver_id",
                                $$v
                              )
                            },
                            expression: "orderData.order_info.deliver_id",
                          },
                        },
                        _vm._l(_vm.DeliveryMan, function (listed, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: listed.name, value: listed.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品条码：" } },
                    [_c("el-input", { staticStyle: { width: "470px" } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "tableSort",
                  attrs: { stripe: "", data: _vm.tadlelist, height: "360px" },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { type: "index", align: "center" } },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "custom-table-checkbox",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.checkList,
                                    callback: function ($$v) {
                                      _vm.checkList = $$v
                                    },
                                    expression: "checkList",
                                  },
                                },
                                _vm._l(_vm.columns, function (item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { label: item.label },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [
                                  _c("vab-remix-icon", {
                                    attrs: { icon: "settings-line" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._l(_vm.columns, function (item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: item.prop,
                        align: item.align,
                        label: item.label,
                        width: item.width,
                      },
                      scopedSlots: _vm._u(
                        [
                          item.label == "类型"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.edit == false
                                      ? _c("div", [_vm._v("协议赠品")])
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "选择类型",
                                                },
                                                model: {
                                                  value: row.goods_type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "goods_type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.goods_type",
                                                },
                                              },
                                              _vm._l(
                                                _vm.goodsTypeSelect,
                                                function (item_) {
                                                  return _c("el-option", {
                                                    key: item_.id,
                                                    attrs: {
                                                      label: item_.name,
                                                      value: item_.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                },
                              }
                            : item.label == "商品名称"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    row.edit == false
                                      ? _c("div", [
                                          _vm._v(
                                            " " + _vm._s(row.goods_name) + " "
                                          ),
                                        ])
                                      : _c(
                                          "div",
                                          [
                                            _c("goods-search", {
                                              attrs: {
                                                "depot-id": String(
                                                  _vm.orderData.order_info
                                                    .depot_id
                                                ),
                                                "f-key": row.goods_name,
                                                "cust-id": _vm.form.cust_id,
                                                "goods-id": row.goods_id,
                                                kouwei: row.child,
                                                rowf: row,
                                                "goods-list-obj": {
                                                  promote_type: 1,
                                                  order_id: _vm.order_id,
                                                },
                                              },
                                              on: {
                                                "change-kouwei": function (
                                                  $event
                                                ) {
                                                  return _vm.changeKouwei(
                                                    $event,
                                                    row
                                                  )
                                                },
                                                "add-rows": _vm.addRows,
                                                "select-goods-all": function (
                                                  $event
                                                ) {
                                                  return _vm.selectGoods(
                                                    $event,
                                                    row,
                                                    $index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ]
                                },
                              }
                            : item.label == "单位"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "选择单位" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.unitChange($event, row)
                                          },
                                        },
                                        model: {
                                          value: row.unit_id,
                                          callback: function ($$v) {
                                            _vm.$set(row, "unit_id", $$v)
                                          },
                                          expression: "row.unit_id",
                                        },
                                      },
                                      _vm._l(row.arr_unit, function (item_) {
                                        return _c("el-option", {
                                          key: item_.unit_id,
                                          attrs: {
                                            label: item_.unit_name,
                                            value: item_.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ]
                                },
                              }
                            : item.label == "单价"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.edit == false
                                      ? _c("div", [_vm._v("0")])
                                      : _c(
                                          "div",
                                          [
                                            _c("el-input", {
                                              attrs: { placeholder: "单价" },
                                              model: {
                                                value: row.goods_price,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "goods_price",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.goods_price",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ]
                                },
                              }
                            : item.label == "下单数量"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: "下单数量" },
                                      model: {
                                        value: row.goods_num,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goods_num", $$v)
                                        },
                                        expression: "row.goods_num",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.label == "金额"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.goods_type == 132 ||
                                    row.goods_type == 2 ||
                                    row.goods_type == 3
                                      ? _c("div", [_vm._v(" 0 ")])
                                      : _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                row.edit == false
                                                  ? 0
                                                  : Number(row.goods_num) *
                                                      Number(row.goods_price)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                },
                              }
                            : item.label == "生产日期"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "130px" },
                                      attrs: { "value-format": "yyyy-MM-dd" },
                                      model: {
                                        value: row.goods_production_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            row,
                                            "goods_production_date",
                                            $$v
                                          )
                                        },
                                        expression: "row.goods_production_date",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.label == "备注"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入备注" },
                                      model: {
                                        value: row.remark,
                                        callback: function ($$v) {
                                          _vm.$set(row, "remark", $$v)
                                        },
                                        expression: "row.remark",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作", width: "110" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.copyRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", disabled: _vm.isCreating },
                  on: {
                    click: function ($event) {
                      return _vm.handleraddtus(3)
                    },
                  },
                },
                [_vm._v(" 创建仓库直销单 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", disabled: _vm.isCreating },
                  on: {
                    click: function ($event) {
                      return _vm.handleraddtus(2)
                    },
                  },
                },
                [_vm._v(" 创建访销单并调拨 ")]
              ),
              _vm.form.allot_button == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.isCreating },
                      on: {
                        click: function ($event) {
                          return _vm.handleraddtus(1)
                        },
                      },
                    },
                    [_vm._v(" 仅创建访销单 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.innerVisible = !_vm.innerVisible
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }