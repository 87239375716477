var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-top": "-10vh" },
          attrs: {
            title: "往期历史",
            visible: _vm.dialogVisible,
            center: "",
            width: "65%",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { staticStyle: { padding: "20px 0px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v(" 返回列表 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  attrs: { stripe: "", "row-key": "id", data: _vm.tabledata },
                  on: {
                    "row-click": function ($event) {
                      if ($event.target !== $event.currentTarget) {
                        return null
                      }
                      return _vm.handlerrowclick.apply(null, arguments)
                    },
                    "selection-change": _vm.handleChangeSelect,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { "reserve-selection": true, type: "selection" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "ID", align: "center", prop: "id" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "对账时间", align: "center", prop: "date" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "对账人",
                      align: "center",
                      prop: "add_name",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "本期应交账",
                      align: "center",
                      prop: "account_payable",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "本期实交账",
                      align: "center",
                      prop: "account_paid",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "本期未交账",
                      align: "center",
                      prop: "account_unpaid",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "备注",
                      align: "center",
                      prop: "remark_list",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "您真的要作废吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handlerzuofei(scope.row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.can_scrap == 1,
                                        expression: "scope.row.can_scrap == 1",
                                      },
                                    ],
                                    attrs: { slot: "reference", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 作废 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.batchSub },
                },
                [_vm._v(" 批量补交对账 ")]
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pageNo,
                  layout: _vm.layout,
                  "page-size": _vm.pageSize,
                  total: _vm.totalCount,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
          _c("wang-qi-dialog", {
            ref: "wangQiDialog",
            on: {
              refresh: function ($event) {
                return _vm.handlerData(_vm.rowinfo)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }