<!--
 * @Author: 候怀烨
 * @Date: 2021-01-18 10:18:49
 * @LastEditTime: 2021-03-26 10:02:17
 * @LastEditors: Please set LastEditors
 * @Description: 查看
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\cumulativePromotion\components\LinkPromotion.vue
-->
<template>
  <div>
    <el-dialog :modal="false" :visible.sync="linkshow" title="活动详情">
      <div>
        <div>
          <el-row>
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="活动名称：" prop="accum_name">
                <el-input
                  v-model="form.accum_name"
                  disabled
                  placeholder="请输入活动名称"
                  style="width: 250px"
                ></el-input>
              </el-form-item>
              <el-form-item label="起止时间：" prop="time">
                <el-date-picker
                  v-model="time"
                  disabled
                  type="daterange"
                  range-separator="-"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 250px"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="限定业务员：">
                <span style="margin-right: 15px">{{ user_data }}人</span>
                <!-- <el-button type="primary" @click="handleSet">设置</el-button> -->
              </el-form-item>
              <el-form-item label="活动备注：" prop="remark">
                <el-input
                  v-model="form.remark"
                  disabled
                  placeholder="活动备注"
                  type="textarea"
                  :rows="2"
                  style="width: 250px"
                ></el-input>
              </el-form-item>
              <el-card v-for="accList in form.acc_data" :key="accList.phase">
                <el-form-item label="活动方案：" prop="acc_type">
                  <!-- 按商品累计 -->
                  <el-radio v-model="accList.acc_type" label="1" disabled>
                    按商品累计
                  </el-radio>
                  <el-table
                    stripe
                    style="width: 100%"
                    :data="accList.goodlist"
                    height="200px"
                  >
                    <el-table-column
                      v-for="(list, index) in colemd1"
                      :key="index"
                      :label="list.label"
                      :align="list.align"
                      :prop="list.prop"
                    >
                      <template
                        v-if="list.label == '活动商品'"
                        #default="{ row }"
                      >
                        {{ row.goods_name }}
                      </template>
                      <template
                        v-else-if="list.label == '单位'"
                        #default="{ row }"
                      >
                        <el-select
                          v-model="row.unit_id"
                          disabled
                          placeholder="选择单位"
                          style="width: 70px"
                        >
                          <el-option
                            v-for="item in row.arr_unit"
                            :key="item.id"
                            :label="item.unit_name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </template>
                      <template v-else #default="{ row }">
                        {{ row[list.prop] }}
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot="header" slot-scope="{}">
                        <el-select v-model="accList.by_type">
                          <el-option label="按数量累计" value="1" />
                          <el-option label="按金额累计" value="2" />
                        </el-select>
                      </template>
                      <template #default="{ row }">
                        <el-input
                          v-if="accList.by_type == 1"
                          v-model="row.goods_num"
                          placeholder="数量"
                          disabled
                        />
                        <el-input
                          v-else
                          v-model="row.goods_money"
                          placeholder="金额"
                          disabled
                        />
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- 按金额累计 -->
                  <el-row style="padding: 10px">
                    <el-radio v-model="accList.acc_type" label="2" disabled>
                      按金额累计
                    </el-radio>
                    <span>累计下单=></span>
                    <el-input
                      v-model="accList.acc_total_amount"
                      disabled
                      placeholder="请输入累计下单数量"
                      style="width: 150px"
                      onkeyup="value=value.replace(/[^\d.]/g,'') "
                    />
                    <span style="margin-left: 20px">限定下单品牌:</span>
                    <span>{{ accList.brandlist.length }}</span>
                    <!-- <el-button
                    :disabled="form.acc_type == 1"
                    type="primary"
                    style="margin-left: 20px"
                    @click="handlerAddbrand"
                  >
                    设 置
                  </el-button> -->
                  </el-row>
                  <el-table stripe :data="accList.givelist" height="300px">
                    <el-table-column
                      v-for="(list, index) in colemd"
                      :key="index"
                      :label="list.label"
                      :align="list.align"
                      :prop="list.prop"
                      width=""
                    >
                      <template
                        v-if="list.label == '活动奖品'"
                        #default="{ row }"
                      >
                        {{ row.goods_name }}
                      </template>
                      <!-- <template v-else-if="list.label == '生产日期'" #default="{ row }">
                <el-date-picker
                  v-model="row.state_time"
                  style="width: 140px"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </template> -->
                      <template
                        v-else-if="list.label == '奖品数量'"
                        #default="{ row }"
                      >
                        {{ row.goods_num }}
                      </template>
                      <template
                        v-else-if="list.label == '单位'"
                        #default="{ row }"
                      >
                        <el-select
                          v-model="row.unit_id"
                          disabled
                          placeholder="选择单位"
                          style="width: 70px"
                          @change="unitChange($event, row)"
                        >
                          <el-option
                            v-for="item in row.arr_unit"
                            :key="item.id"
                            :label="item.unit_name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </template>
                      <template
                        v-else-if="list.label == '货值'"
                        #default="{ row }"
                      >
                        {{ Number(row.goods_num * row.goods_money).toFixed(2) }}
                      </template>

                      <template v-else #default="{ row }">
                        {{ row[list.prop] }}
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
                <el-form-item label="奖励现金：" prop="gift_money">
                  <el-input
                    v-model="accList.gift_money"
                    disabled
                    style="width: 250px"
                    placeholder="奖励金额"
                  />
                </el-form-item>
              </el-card>
              <el-form-item label="修改奖品：" prop="flag_edit_prize">
                <el-switch
                  v-model="form.flag_edit_prize"
                  disabled
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
                <span style="margin-left: 20px">允许超额修改：</span>
                <el-switch
                  v-model="form.flag_edit_excess"
                  disabled
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </el-form-item>
              <el-form-item prop="flag_abnormal">
                <el-radio-group v-model="form.flag_abnormal" disabled>
                  <el-radio
                    v-for="city in cities"
                    :key="city.id"
                    :label="city.id"
                  >
                    {{ city.name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </el-row>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="linkshow = !linkshow">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        linkshow: false,
        checked: true,
        checked1: false,
        time: [],
        form: {
          accum_name: '', //活动名称
          remark: '', //活动备注
          start_time: '', //开始时间
          end_time: '', //结束时间
          user_data: '', //限制业务数据  如[1,2,3]
          flag_edit_prize: 1, //是否可更换赠品  0否1可修改
          flag_abnormal: '', //报异常开关 0允许超额 1更换就异常 2超额报异常
          flag_edit_excess: 0, //是否可超额更换赠品  0不可 1可
          acc_data: [
            {
              phase: 1,
              acc_type: '1', //累计方式  1按商品 2按金额
              by_type: '1', //acc_type=1时 累计方式 1按数量 2按金额
              goodlist: [], //按照商品累计  商品数据
              givelist: [], //赠品数据
              brandlist: [], //按照金额  限制品牌数据 如[1,2,3]
              acc_total_amount: '', //按照金额  累计金额值
              gift_money: '', //奖励现金
            },
          ],
        },
        user_data: 0,
        cities: [
          {
            name: '更换奖品不提示异常',
            id: '0',
          },
          {
            name: '更换奖品提示异常',
            id: '1',
          },
          {
            name: '超额更换奖品提示异常',
            id: '2',
          },
        ],
        colemd1: [
          {
            order: 1,
            label: '活动商品',
            align: 'center',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 1,
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '220',
          },
          {
            order: 1,
            label: '单位',
            align: 'center',
            prop: 'unit_id',
            width: '220',
          },
          {
            order: 1,
            label: '售价',
            align: 'center',
            prop: 'goods_price',
            width: '220',
          },
        ],
        colemd: [
          {
            order: 1,
            label: '活动奖品',
            align: 'center',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 3,
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '100',
          },
          {
            order: 2,
            label: '单位',
            align: 'center',
            prop: 'unit_id',
            width: '80',
          },

          {
            order: 4,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '120',
          },
          {
            order: 6,
            label: '奖励数量',
            align: 'center',
            prop: 'goods_num',
            width: '170',
          },
          {
            order: 5,
            label: '货值',
            align: 'center',
            prop: 'goods_money',
            width: '120',
          },
        ],
        url: {
          linklist: '/promoteAdmin/accum/detail-new',
        },
      }
    },
    watch: {
      Promotion(val) {
        if (val == false) {
          this.user_data = 0
        }
      },
    },
    methods: {
      handlerData(row) {
        postAction(this.url.linklist, { id: row.id })
          .then((res) => {
            this.form.accum_name = res.data.accum_name
            this.form.id = res.data.id
            this.form.remark = res.data.remark
            this.form.start_time = res.data.start_time
            this.form.end_time = res.data.end_time
            this.form.flag_edit_prize = Number(res.data.flag_edit_prize)
            this.time = new Array(res.data.start_time, res.data.end_time)
            this.form.flag_edit_excess = Number(this.form.flag_edit_excess)
            this.user_data = res.data.user_data.length
            var acc = res.data.phase_data.map((list) => {
              var accdata = { ...list.info }
              if (list.give_list) {
                console.log('进来了吗')
                accdata.givelist = list.give_list
              } else {
                accdata.givelist = [
                  {
                    arr_unit: [],
                    goods_id: '',
                    goods_name: '',
                    goods_specs: '',
                    unit_id: '',
                    unit_name: '',
                    goods_num: '',
                    goods_price: '',
                  },
                ]
              }
              if (list.goods_list) {
                accdata.goodlist = list.goods_list
              } else {
                cdata.goodlist = [
                  {
                    arr_unit: [], //没有用的
                    goods_id: '',
                    goods_price: '', //没有用的
                    goods_name: '',
                    goods_specs: '',
                    unit_id: '',
                    unit_name: '',
                    goods_num: '',
                    goods_money: '',
                  },
                ]
              }
              if (list.brand_list) {
                console.log('进来了吗2')
                accdata.brandlist = list.brand_list
              } else {
                accdata.brandlist = []
              }
              console.log(accdata)
              accdata.phase = Number(accdata.phase)
              return accdata
            })
            console.log(acc, '整理后的数据')
            // this.$set(this.form, 'acc_data', acc)
            this.form.acc_data = acc
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
