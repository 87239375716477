<template>
  <div class="orderTest-container">
    <!--    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :title="title"
      top="5vh"
      :visible.sync="dialogFormVisible"
      width="1380px"
      :modal-append-to-body="false"
      fullscreen
      @close="close"
    >-->
    <!-- <div class="orderStatus">已派单</div> -->
    <!-- <el-alert :closable="false" :title="titleTip" type="success"></el-alert> -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      :inline="true"
    >
      <el-form-item label="购货方" prop="cust_name">
        <client-search
          ref="clientSearch"
          :keyword.sync="form.cust_name"
          :popper-class="'selectDC'"
          :ziobj="{
            is_closed: 0,
          }"
          @input-mine="custInput"
          @select-val="setCustName"
          @select-val-alldata="setAllInput"
        ></client-search>
      </el-form-item>
      <el-form-item label="联系人" prop="lxr" style="margin-right: 10px">
        <el-input
          v-model.trim="form.lxr"
          disabled
          style="width: 209px"
        ></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="dz" class="dz">
        <el-input
          v-model.trim="form.address"
          disabled
          style="width: 512px; margin-right: 130px"
        ></el-input>
      </el-form-item>

      <el-form-item label="仓库" prop="depot_id">
        <!--
            :disabled="isDepot"
           -->
        <el-select
          v-model="form.depot_id"
          placeholder="仓库"
          @change="changeDepot"
        >
          <el-option
            v-for="item_ in allSelectList.depot"
            :key="item_.id"
            :label="item_.name"
            :value="item_.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="送货人" prop="deliver_id">
        <el-select v-model="form.deliver_id" placeholder="送货人" clearable>
          <el-option
            v-for="item_ in shrList"
            :key="item_.id"
            :label="item_.name"
            :value="item_.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务员" prop="ywy">
        <!-- <el-input v-model.trim="form.ywy"></el-input> -->
        <el-select v-model="form.staff_id" placeholder="业务员" clearable>
          <el-option
            v-for="item_ in allSelectList.staff"
            :key="item_.id"
            :label="item_.name"
            :value="item_.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="扫码录入"
        prop="goods_code"
        style="margin-left: 20px"
      >
        <el-input
          v-model.trim="form.goods_code"
          :disabled="discode"
          clearable
          placeholder="使用扫码枪扫码添加商品"
          @keyup.enter.native="handlerkey"
        ></el-input>
      </el-form-item>
      <el-form-item label="快捷输入:">
        <el-autocomplete
          v-model="remark"
          :disabled="discode"
          class="inline-input"
          value-key="goods_name"
          :fetch-suggestions="handlerremark"
          placeholder="商品名称、简拼、条码"
          :trigger-on-focus="false"
          @select="handleSelect"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item v-if="followuser.length != 0" label="随车人">
        <el-select
          v-model="form.follow_users"
          placeholder="随车人员"
          clearable
          multiple
        >
          <el-option
            v-for="item_ in followuser"
            :key="item_.id"
            :label="item_.user_name"
            :value="item_.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- <el-button icon="el-icon-plus" type="primary" @click="addRow">
        添加
      </el-button> -->

    <!-- 表格 -->
    <el-table
      ref="tableSort"
      stripe
      :data="list"
      height="600px"
      :summary-method="getSummaries"
      show-summary
      :row-class-name="tableRowClassName"
      @cell-click="handlercellclick"
      @header-dragend="handlerheaderdragend"
      border
      resizable
    >
      <!-- 选择框 -->
      <!--        <el-table-column
          align="center"
          type="selection"
          width="90"
        ></el-table-column>-->
      <!-- 序号 -->
      <el-table-column prop="" align="center" label="序号" min-width="50px">
        <template #default="{ $index }">
          <span>{{ $index + 1 }}</span>
        </template>
      </el-table-column>

      <!-- 类型 -->
      <el-table-column
        prop="type"
        align="center"
        label="类型"
        min-width="120px"
      >
        <template #default="{ row, $index }">
          <template
            v-if="row.index === tabClickIndex && tabClickLabel === '类型'"
          >
            <!-- <el-input v-model="row.type" style="width: 100px" /> -->
            <el-select
              v-model="row.goods_type"
              placeholder="选择类型"
              style="width: 100px"
              @change="
                handleTypeChange($event, row)
                moneyN(row, $index)
              "
            >
              <el-option
                v-for="item_ in row.arr_goods_type"
                :key="item_.id"
                :label="item_.name"
                :value="item_.id"
              ></el-option>
            </el-select>
          </template>
          <div v-else>
            {{ row.type }}
            <i v-show="!row.type" class="el-icon-caret-right trsher" />
          </div>
        </template>
      </el-table-column>
      <!-- 商品名称 -->
      <el-table-column
        prop="goods_name"
        align="center"
        label="商品名称"
        min-width="260px"
      >
        <template #default="{ $index, row }">
          <template
            v-if="row.index === tabClickIndex && tabClickLabel === '商品名称'"
          >
            <!-- <el-autocomplete
                v-model="row.goods_name"
                style="width: 100%"
                class="inline-input"
                popper-class="selectDC"
                :fetch-suggestions="querySearch2"
                value-key="goods_name"
                placeholder="请输入商品名称、简拼"
                @select="handleSelect($index, row, $event)"
                @input="addRow($index, row, $event)"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search"
                  @click="iconClick($index, row)"
                ></i>
                <template slot-scope="{ item }">
                  <div class="wrap" style="display: flex">
                    <div class="name" style="width: 200px; margin-right: 120px">
                      {{ item.goods_name }}
                    </div>
                    <span class="addr">{{ item.specs }}</span>
                  </div>
                </template>
              </el-autocomplete> -->
            <goods-search
              :goods_keyword="row.goods_name"
              :f-key="row.goods_name"
              :goods-id="row.goods_id"
              :kouwei="row.child"
              :rowf="row"
              :depot-id="form.depot_id"
              :disab="tableEdit"
              :cust-id="form.id"
              @add-rows="addRows(row, $event)"
              @select-goods-all="selectGoods($event, row, $index)"
              @change-kouwei="changeKouwei($event, row)"
            ></goods-search>
          </template>
          <div v-else class="goodsname">
            <div :class="row.isDuplicate ? 'namewithy' : 'namewith'">
              {{ row.goods_name }}
            </div>
            <!--              <i-->
            <!--                v-show="!row.goods_name"-->
            <!--                class="el-icon-search"-->
            <!--                style="text-align: right"-->
            <!--              />-->
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="specs"
        align="center"
        label="规格"
        min-width="110px"
      >
        <template #default="{ row }">
          {{ row.specs }}
        </template>
      </el-table-column>
      <el-table-column
        prop="unit_code"
        align="center"
        label="条码"
        min-width="110px"
      >
        <template #default="{ row }">
          {{ currUnitCode(row) }}
        </template>
      </el-table-column>
      <!-- 单位 -->
      <el-table-column prop="type" align="center" label="单位" width="140px">
        <template #default="{ row, $index }">
          <template
            v-if="row.index === tabClickIndex && tabClickLabel === '单位'"
          >
            <!-- <el-input v-model="row.type" style="width: 100px" /> -->
            <el-select
              v-model="row.unit_id"
              placeholder="选择单位"
              style="width: 90px"
              :disabled="row.goods_name == ''"
              @change="unitChange($event, row), moneyN(row, $index)"
            >
              <el-option
                v-for="item in row.arr_unit"
                :key="item.id"
                :label="item.unit_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
          <div v-else>
            <span>{{ row.unit_name }}</span>
            <i v-show="!row.unit_name" class="el-icon-caret-right trsher" />
          </div>
        </template>
        <!-- <template #default="{ row }">
            {{ row.units }}
          </template> -->
      </el-table-column>
      <!-- 单价 -->
      <el-table-column
        prop="goods_price"
        align="center"
        label="单价"
        min-width="110px"
      >
        <template #default="{ $index, row }">
          <template
            v-if="row.index === tabClickIndex && tabClickLabel === '单价'"
          >
            <el-input
              ref="input"
              v-model="row.goods_price"
              :disabled="row.goods_name == '' || isEditPrice == 0"
              style="width: 80px"
              onkeyup="value=value.replace(/[^\d.]/g,'') "
              @input="moneyN(row, $index)"
            />
            <!--
                onkeyup="value=value.replace(/[^\-?\d.]/g,'')"

                -->
          </template>
          <div v-else>
            {{ row.goods_price }}
            <i v-show="!row.goods_price" class="el-icon-caret-right trsher" />
          </div>
        </template>
      </el-table-column>
      <!-- 订购数量 -->
      <el-table-column
        prop="quantity"
        align="center"
        label="订购数量"
        min-width="110px"
      >
        <template #default="{ $index, row }">
          <template
            v-if="row.index === tabClickIndex && tabClickLabel === '订购数量'"
          >
            <el-input
              v-model="row.quantity"
              :disabled="row.goods_name == ''"
              style="width: 80px"
              onkeyup="value=value.replace(/[^\d.]/g,'') "
              @input="moneyN(row, $index)"
              @focus="inputfocus(row)"
            />
          </template>
          <div v-else>
            {{ row.quantity }}
            <i v-show="!row.quantity" class="el-icon-caret-right trsher" />
          </div>
        </template>
        <!-- <template #default="{ row }">
            {{ row.quantity }}
          </template> -->
      </el-table-column>
      <!-- 可用库存 -->
      <el-table-column
        prop="depot_stock_num_read"
        align="center"
        label="可用库存"
        min-width="110px"
      >
        <template #default="{ row }">
          {{ row.depot_stock_num_read }}
        </template>
      </el-table-column>
      <!-- 实际库存 -->
      <el-table-column
        prop="balance_count_read"
        align="center"
        label="实际库存"
        min-width="110px"
      >
        <template #default="{ row }">
          {{ row.balance_count_read }}
        </template>
      </el-table-column>
      <!-- 金额 -->
      <el-table-column
        prop="goods_money"
        align="center"
        label="金额"
        min-width="90px"
      >
        <!--          <template #default="{ row }">
            &lt;!&ndash; {{this.row_goods_money(row)}} &ndash;&gt;
            <span
              v-if="
                row.goods_type != 2 &&
                row.goods_type != 3 &&
                row.goods_type != 10 &&
                row.goods_type != 11
              "
            >
              {{ Number(row.quantity * row.goods_price).toFixed(2) }}
            </span>
            <span v-else>0</span>
          </template>-->

        <template #default="{ $index, row }">
          <template
            v-if="
              row.index === tabClickIndex &&
              tabClickLabel === '金额' &&
              row.quantity > 0
            "
          >
            <el-input
              ref="input"
              v-model="row.goods_money"
              :disabled="
                row.goods_name == '' || isEditPrice == 0 || row.quantity == 0
              "
              style="width: 80px"
              onkeyup="value=value.replace(/[^\d.]/g,'') "
              @input="moneyreverse(row, $index)"
            />
            <!--
                onkeyup="value=value.replace(/[^\-?\d.]/g,'')"

                -->
          </template>
          <div v-else>
            {{ row.goods_money }}
            <i v-show="!row.goods_money" class="el-icon-caret-right trsher" />
          </div>
        </template>
      </el-table-column>
      <!-- 生产日期 -->
      <el-table-column
        prop="goods_production_date"
        align="center"
        label="生产日期"
        min-width="140px"
      >
        <template #default="{ row }">
          <!-- {{ row.production_date }} -->
          <!-- <el-input v-model="row.goods_production_date"></el-input> -->
          <el-date-picker
            v-if="row.index === tabClickIndex && tabClickLabel === '生产日期'"
            v-model="row.goods_production_date"
            value-format="yyyy-MM-dd"
            style="width: 130px"
          ></el-date-picker>
          <div v-else>
            {{ row.goods_production_date }}
            <i
              v-show="!row.goods_production_date"
              class="el-icon-caret-right trsher"
            />
          </div>
        </template>
      </el-table-column>
      <!-- 备注 -->
      <el-table-column
        prop="info"
        align="center"
        label="备注"
        min-width="110px"
      >
        <template #default="{ row }">
          <!-- {{ row.info }} -->
          <el-input
            v-if="row.index === tabClickIndex && tabClickLabel === '备注'"
            v-model="row.info"
          ></el-input>
          <div v-else>
            {{ row.info }}
            <i v-show="!row.info" class="el-icon-caret-right trsher" />
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" fixed="right" width="160">
        <template #default="{ $index, row }">
          <!-- <el-button type="text" @click.native.prevent="addRow(row)">
              添加
            </el-button> -->
          <el-button type="text" @click.native.prevent="copyRow($index, row)">
            复制
          </el-button>
          <el-button type="text" @click.native.prevent="deleteRow($index, row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格结束 -->

    <!-- 底部文字 -->
    <el-form
      ref="form2"
      :model="form2"
      label-suffix="："
      :inline="true"
      style="margin-top: 15px"
    >
      <!--        label-width="120px"-->
      <el-form-item label="备注" prop="remark" style="width: 100%">
        <el-input v-model="form2.remark" style="width: 530px"></el-input>
      </el-form-item>
      <el-form-item label="下单时间" prop="remark" style="width: 100%">
        <el-date-picker
          v-model="form2.create_at"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择下单时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="订单金额" prop="dgze">
        {{ form2.dgze }}
        <!--          <el-input v-model.trim="form2.dgze" disabled></el-input>-->
      </el-form-item>
      <!--        查看编辑显示-->
      <!--        <el-form-item label="使用预存款" prop="syyck">
          <el-input v-model.trim="form2.syyck" disabled></el-input>
          {{form2.syyck}}
        </el-form-item>
        <el-form-item label="还货" prop="hhje">
          <el-input v-model.trim="form2.hhje" disabled></el-input>
          {{form2.hhje}}
        </el-form-item>-->
      <el-form-item label="退货金额" prop="thje">
        <!--          <el-input v-model.trim="form2.thje" disabled></el-input>-->
        {{ form2.thje }}
      </el-form-item>
      <div>
        <el-form-item>
          <span
            class="el-icon-plus"
            style="color: red; fontweight: bold"
          ></span>
          <el-form-item label="销售金额" prop="xsje">
            <!--              <el-input v-model.trim="form2.xsje" disabled></el-input>-->
            {{ form2.xsje }}
          </el-form-item>
        </el-form-item>
        <el-form-item>
          <span
            class="el-icon-minus"
            style="color: #41d741; fontweight: bold"
          ></span>
          <el-form-item label="优惠" prop="yhje">
            <el-input
              v-model.trim="form2.yhje"
              :max="Number(form2.xsje)"
              @input="yhInput"
              @focus="handlerclero"
            />
          </el-form-item>
        </el-form-item>
        <el-form-item label="优惠后" prop="yhh">
          <el-input
            v-model.trim="form2.yhh"
            :min="0"
            :max="1000000000"
            type="number"
            @focus="handlerclero"
            @input="yhhInput"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span
            class="el-icon-minus"
            style="color: #41d741; fontweight: bold"
          ></span>
          <el-form-item label="退货退款" prop="thtk">
            <!--            <el-input v-model.trim="form2.thtk" disabled></el-input>-->
            {{ form2.thtk }}
          </el-form-item>
        </el-form-item>
        <el-form-item prop="refund_type">
          <el-select
            v-model="form2.refund_type"
            placeholder="退货方式"
            style="width: 186px"
          >
            <el-option
              v-for="item_ in allSelectList.arr_refund_type"
              :key="item_.id"
              :label="item_.name"
              :value="item_.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <!--        查看编辑显示-->
      <!--        <el-form-item>
          <span class="el-icon-minus" style="color: #41d741;fontWeight: bold;"></span>
          <el-form-item label="兑付现金" prop="thtk">
            <el-input v-model.trim="form2.dfxj" @input="yshandler" @focus="handlerclero"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item>
          <span class="el-icon-minus" style="color: #41d741;fontWeight: bold;"></span>
          <el-form-item label="兑奖现金" prop="thtk">
            <el-input v-model.trim="form2.dfjj" @input="yshandler" @focus="handlerclero"></el-input>
          </el-form-item>
        </el-form-item>-->
      <el-form-item v-if="form.is_fun">
        <span
          class="el-icon-minus"
          style="color: #41d741; fontweight: bold"
        ></span>
        <el-form-item label="费用抵扣" prop="thtk">
          <el-input
            v-model.trim="form2.fydk"
            @input="yshandler"
            @focus="handlerclero"
          ></el-input>
        </el-form-item>
        <el-select
          v-model="form2.funds_type"
          :placeholder="'请选费用' + `（合计${funds_zong}）`"
          style="width: 186px"
          multiple
          collapse-tags
          @change="handlerfydk"
        >
          <el-option
            v-for="item_ in funselect"
            :key="item_.id"
            :label="item_.funds_type + `(金额${item_.left_amount})`"
            :value="item_.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div>
        <el-form-item>
          <span style="color: #41d741; fontweight: bold">=</span>
          <el-form-item label="应收款" prop="ysk">
            {{ form2.ysk }}
            <!--            <el-input v-model.trim="form2.ysk" disabled></el-input>-->
          </el-form-item>
        </el-form-item>
        <el-form-item label="结算方式" prop="account_type">
          <el-select
            v-model="form2.account_type"
            placeholder="结算方式"
            style="width: 186px"
            @change="jsfsChange"
          >
            <el-option
              v-for="item_ in allSelectList.arr_account_type"
              :key="item_.id"
              :label="item_.name"
              :value="item_.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="(item1, index) in form2.xssk"
          :key="index"
          label="销售收款"
          label-width="160px"
        >
          <div style="display: flex">
            <el-input
              v-model.trim="item1.money"
              style="width: 90px; margin-right: 20px"
              @focus="handlerclero"
            ></el-input>
            <el-select v-model="item1.pay_type" style="width: 90px">
              <el-option
                v-for="item_1 in allSelectList.arr_pay_type"
                :key="item_1.id"
                :label="item_1.name"
                :value="item_1.id"
              ></el-option>
            </el-select>
          </div>
          <i
            v-if="index == 0"
            class="el-icon-circle-plus addbtn"
            @click="addInput"
          ></i>
        </el-form-item>
      </div>

      <!--        <el-form-item label="退货金额" prop="thje">
          <el-input v-model.trim="form2.thje" disabled></el-input>
        </el-form-item>-->
      <!-- <el-form-item label="退货金额" prop="thje">
          <el-input v-model.trim="form2.thje" disabled></el-input>
        </el-form-item> -->
      <!--        <el-form-item label="退货方式" prop="refund_type">
          <el-select
            v-model="form2.refund_type"
            placeholder="退货方式"
            style="width: 186px"
          >
            <el-option
              v-for="item_ in allSelectList.arr_refund_type"
              :key="item_.id"
              :label="item_.name"
              :value="item_.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退货退款" prop="thtk">
          <el-input v-model.trim="form2.thtk" disabled></el-input>
        </el-form-item>-->
    </el-form>
    <!-- 底部按钮 -->
    <!-- pre_process_door=0
distribute_only=0 -->

    <div v-if="btnShow" slot="footer" class="dialog-footer">
      <template
        v-if="btnShow.pre_process_door == 0 && btnShow.distribute_only == 0"
      >
        <el-button type="warning" @click="saveAlloct">
          添加并生成调拨单
        </el-button>
        <el-button type="primary" @click="save">添加</el-button>
      </template>
      <template
        v-else-if="
          btnShow.pre_process_door == 0 && btnShow.distribute_only == 1
        "
      >
        <el-button type="warning" @click="saveAlloct">
          添加并生成调拨单
        </el-button>
      </template>
      <template v-if="btnShow.pre_process_door == 1">
        <el-button type="primary" @click="save">添加</el-button>
      </template>
      <el-button @click="close">取 消</el-button>
    </div>
    <!--    </el-dialog>-->
    <!-- 表格输入框弹窗 -->
    <table-input-window
      ref="tableInputWindow"
      @add-rows="addRows"
    ></table-input-window>
    <input-window
      ref="inputWindow"
      @change-client="changeClient"
    ></input-window>
  </div>
</template>

<script>
  import _ from 'lodash'
  import {
    getAdvanceOrderEditTableList,
    getAdvanceOrderClientSelectList,
    driverList,
    addOrder,
    getAllSelect,
    addOrderD,
  } from '@/api/advanceOrder'
  import { getStaffList } from '@/api/setPrice'
  import { getInputList } from '@/api/goodsSearchInput'
  import TableInputWindow from '@/baseComponents/goodsChooseDialog'
  import InputWindow from './components/inputWindow'
  import ClientSearch from '@/baseComponents/clientSearch'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { postAction } from '@/api/Employee'
  import { depotList } from '@/api/depotManagement'
  import { markDuplicatesById } from '@/utils/array'
  import { mapActions } from 'vuex'
  export default {
    name: 'AddOrder',
    components: {
      TableInputWindow,
      InputWindow,
      ClientSearch,
      GoodsSearch,
    },
    // props: {
    //   btnShow: {
    //     type: [Boolean, Object],
    //     default: () => false,
    //   },
    // },
    data() {
      return {
        btnShow: false,
        remark: '',
        tabClickIndex: null,
        tabClickLabel: '',
        edit: true,
        selectRow: {},
        cust_id: '',
        //随车人
        followuser: [],
        // 所有下拉
        allSelectList: {},
        // 单号
        orderCode: '',
        form: {
          is_fun: 0,
          id: '', //客户id
          depot_id: '', //仓库id
          deliver_id: '', //司机id
          cust_name: '', //购货方
          boss: '', //联系人
          mobile: '',
          address: '', //地址
          lxr: '',
          goods_code: '',
          staff_id: '', //业务员ID
        },
        chckList: [],
        ywyList: [],
        shrList: [],
        rules: {
          cust_name: [
            {
              required: true,
              trigger: ['change'],
              message: '请输入购货方',
            },
          ],
          depot_id: [
            { required: true, trigger: 'blur', message: '请选择仓库' },
          ],
          //   author: [{ required: true, trigger: 'blur', message: '请输入作者' }],
        },
        title: '添加订单',
        dialogFormVisible: false,
        addRowDialog: false,
        // btnShow: null,
        // 表格
        checkList: [
          '类型',
          '商品名称',
          '单位',
          '规格',
          '条码',
          '单价',
          '订购数量',
          '可用库存',
          '实际库存',
          '金额',
          '生产日期',
          '备注',
        ],
        columns: [
          {
            order: 0,
            label: '类型',
            // width: '110px',
            prop: 'type',
            sortable: false,
          },
          {
            order: 1,
            label: '商品名称',
            width: '210px',
            prop: 'goods_name',
            sortable: false,
          },
          {
            order: 2,
            label: '单位',
            // width: '110', //'110px',
            prop: 'units',
            sortable: false,
          },
          {
            order: 3,
            label: '规格',
            // width: '110px',
            prop: 'specs',
            sortable: false,
          },
          {
            order: 3.2,
            label: '条码',
            // width: '110px',
            prop: 'unit_code',
            sortable: false,
          },
          {
            order: 4,
            label: '单价',
            // width: '110px',
            prop: 'price',
            sortable: false,
          },
          {
            order: 5,
            label: '订购数量',
            // width: '110px',
            prop: 'order_num',
            sortable: false,
          },
          {
            order: 6,
            label: '可用库存',
            // width: '90px',
            prop: 'inventory',
            sortable: false,
          },
          {
            order: 6.5,
            label: '实际库存',
            // width: '90px',
            prop: 'depot_stock_num_read',
            sortable: false,
          },
          {
            order: 7,
            label: '金额',
            // width: '110px',
            prop: 'money',
            sortable: false,
          },
          {
            order: 8,
            label: '生产日期',
            // width: '110px',
            prop: 'goods_production_date',
            sortable: false,
          },
          {
            order: 9,
            label: '备注',
            // width: '110px',
            prop: 'remark',
            sortable: false,
          },
        ],
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          title: '',
        },
        // 添加行
        unitsList: [],
        goodsList: [
          {
            lable: '',
            value: '',
          },
        ],
        typeList: [],
        // addRowFrom: {
        //   type: '',
        //   goods_name: '',
        //   units: '',
        //   remark: '',
        //   price: '',
        //   order_num: '',
        //   inventory: '',
        //   money: '',
        // },
        funselect: [],
        funds_zong: '',
        form2: {
          // bottom form
          create_at: '',
          refund_type: 1, //退货方式 1现金 2转存货 3抵欠款
          account_type: '', //结算方式 1货到付款 2账期结算
          funds_type: [], //费用抵扣
          remark: '',
          dgze: '',
          xsje: '',
          syyck: '',
          yhje: 0.0,
          yhh: '',
          hhje: 0.0,
          thtk: '',
          dfxj: 0.0,
          dfjj: 0.0,
          fydk: 0.0,
          thje: '',
          ysk: '',
          jsfs: '',
          bz: '',
          thfs: '',
          xssk: [
            {
              money: '',
              pay_type: '',
            },
          ],
        },
      }
    },

    computed: {
      discode() {
        if (this.form.id != '') {
          return false
        } else {
          return true
        }
      },
      currUnitCode() {
        return (row) => {
          console.log(row.child, row, '口味变化')
          if (row.child && row.child.length > 0) {
            let a = row.child.filter((i) => i.id == row.goods_child_id)[0]
              .small_unit_code
            return a
          } else {
            if (row.arr_unit && row.arr_unit.length > 0) {
              let a = row.arr_unit.filter(
                (i) => (i.unit_id || i.id) == row.unit_id
              )[0].unit_code
              return a
            } else {
              return ''
            }
          }
        }
      },
      isEditPrice() {
        return localStorage.getItem('isEditPrice')
      },
      tableEdit() {
        if (this.form.depot_id && this.form.id) {
          return false
        } else {
          return true
        }
      },
      // 是否可选择仓库
      isDepot() {
        if (
          this.list.length == 0 ||
          (this.list.length == 1 && this.list[0].goods_name == '')
        ) {
          return false
        } else {
          return true
        }
      },
      titleTip() {
        return '单号:' + this.orderCode + ' 下单时间： ' + this.form.sale_time
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        console.log(finallyArray)
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      $route(to, from) {
        console.log(to, from, '路由')
      },
      'form2.ysk'(val) {
        let c = JSON.parse(JSON.stringify(this.form2.ysk))
        if (this.form2.account_type == 1) {
          this.form2.xssk[0].money = 0
        } else {
          this.form2.xssk[0].money = 0
        }
      },
      dialogFormVisible(n) {
        if (n) {
          let a = this.allSelectList.arr_pay_type[0].id
          this.form2.xssk[0].pay_type = a
          // this.getTableList()
          // this.form2.xssk = [
          //   {
          //     money: '450',
          //     pay_type: '现金',
          //   },
          // ]
          depotList().then((res) => {
            console.log(res)
            // 获取默认值
            let d = res.data.filter((i) => i.is_default == 1)[0]
            console.log('ddd', d)
            this.form.depot_id = Number(d.id)
            this.depotSelect = res.data
            this.changeDepot(d.id)
          })
        } else {
          this.resetForm()
          this.list = [
            {
              activity_code: '',
              activity_id: 0,
              activity_type: 0,
              balance_count: 0,
              depot_stock_num_read: 0,
              edit: true,
              child: [],
              arr_unit: [],
              goods_child_id: null,
              goods_money: 0,
              goods_name: '',
              goods_price: '',
              goods_production_date: '',
              goods_type: '',
              info: '',
              money: '',
              quantity: '',
              specs: '',
              unit_id: '',
            },
          ]
          this.shrList = []
          this.form.cust_name = ''
        }
      },
      'form.id'(val) {
        if (val) {
          this.handlerselect()
          // this.getDepotList()
        } else {
          this.chckList = []
        }
      },
      'form2.fydk'(val) {
        this.yshandler()
      },
      'form2.xsje'(val) {
        // if (this.form2.yhje != 0 || this.form2.yhje != '') {
        //   this.form2.yhh = val - this.form2.yhje
        // } else {
        //   if (this.form2.yhh != 0 || this.form2.yhh != '') {
        //     this.form2.yhje = val - this.form2.yhh
        //   }
        // }
        this.form2.yhh =
          Number(val).toFixed(2) - Number(this.form2.yhje).toFixed(2)

        this.yshandler()
      },
    },

    created() {
      this.getAllSelectList()
      // 添加行
      if (this.list.length == 0) {
        this.list.push({
          activity_code: '',
          activity_id: 0,
          activity_type: 0,
          balance_count: 0,
          depot_stock_num_read: 0,
          edit: true,
          child: [],
          arr_unit: [],
          goods_child_id: null,
          goods_money: 0,
          goods_name: '',
          goods_price: '',
          goods_production_date: '',
          goods_type: '',
          info: '',
          money: '',
          quantity: '',
          specs: '',
          unit_id: '',
        })
      }
      // 获取业务员下拉
      this.getSaleManList()
    },
    mounted() {
      console.log(this.$route.params, '路由参数')
      if (this.$route.params.btnShow) {
        this.btnShow = this.$route.params.btnShow
        depotList().then((res) => {
          console.log(res)
          // 获取默认值
          let d = res.data.filter((i) => i.is_default == 1)[0]
          console.log('ddd', d)
          this.form.depot_id = Number(d.id)
          this.depotSelect = res.data
          this.changeDepot(d.id)
        })
      }
      this.handlerfollowuserlist()
    },

    methods: {
      ...mapActions({
        delVisitedRoute: 'tabsBar/delVisitedRoute',
      }),
      handlerheaderdragend(newWidth, oldWidth, column, event){
        console.log(newWidth, oldWidth, column, event)
      },
      handlerclero(v) {
        v.target.value
        if (v.target.value == 0) {
          v.target.value = ''
        }
      },
      yshandler(v) {
        this.form2.ysk =
          Number(this.form2.xsje) -
          Number(this.form2.yhje) -
          Number(this.form2.thtk) -
          Number(this.form2.dfxj) -
          Number(this.form2.dfjj) -
          Number(this.form2.fydk)
      },
      handlerfydk(v) {
        let fydk = 0
        console.log(v)
        v.map((list) => {
          fydk += Number(
            this.funselect.filter((item) => item.id == list)[0].left_amount
          )
        })
        this.form2.fydk = fydk
      },
      handlerselect() {
        postAction('/saleAdmin/order-pre/funds-option', {
          cust_id: this.form.id,
        }).then((res) => {
          this.funselect = res.data.bill_list
          this.funds_zong = res.data.funds_zong
        })
      },
      async handlerremark(queryString, cb) {
        const { data } = await getInputList({
          keyword: queryString,
          depot_id: this.form.depot_id,
          custom_id: this.form.id,
        })
        if (data.length > 0) {
          let goodsdata = data.map((item, index) => {
            try {
              item.arr_unit.forEach((unit) => {
                if (unit.is_default == 2) {
                  item.unit_id = unit.id
                  item.goods_price = unit.sell_price
                  item.unit_name = unit.unit_name
                }
              })
              item.goods_type = 1
              item.type = '销常'
            } catch (err) {}
            return item
          })
          cb(goodsdata)
        } else {
          cb([])
        }
      },
      async handlerkey() {
        if (this.form.goods_code) {
          const { data } = await getInputList({
            goods_code: this.form.goods_code,
            depot_id: this.form.depot_id,
            custom_id: this.form.id,
          })
          if (data.length > 0) {
            let goodsdata = data.map((item, index) => {
              try {
                item.arr_unit.forEach((unit) => {
                  if (unit.is_default == 2) {
                    item.unit_id = unit.id
                    item.goods_price = unit.sell_price
                    item.unit_name = unit.unit_name
                  }
                })
                item.goods_type = 1
                item.type = '销常'
              } catch (err) {}
              return item
            })
            if (this.list.length <= 1 && this.list[0].goods_name == '') {
              goodsdata[0].quantity += 1
              this.list.splice(this.list.length - 1, 0, goodsdata[0])
            } else {
              var goodstow = false
              this.list.forEach((list) => {
                if (list.goods_name != '') {
                  if (list.param_code == goodsdata[0].param_code) {
                    list.quantity += 1
                    goodstow = true
                  }
                }
              })
              if (!goodstow) {
                goodsdata[0].quantity += 1
                this.list.splice(this.list.length - 1, 0, goodsdata[0])
              }
            }
          }
          this.form.goods_code = ''
        }
      },
      handlercellclick(row, column, cell, event) {
        // console.log(row, column, cell, event)
        this.tabClickIndex = row.index
        this.tabClickLabel = column.label
      },
      tableRowClassName({ row, rowIndex }) {
        // 把每一行的索引放进row
        row.index = rowIndex
      },

      async handlerfollowuserlist() {
        return await postAction('saleAdmin/order-pre/option', {}).then(
          (res) => {
            this.followuser = res.data.order_follow_users
          }
        )
      },
      inputfocus(row) {
        if (row.quantity == 0) {
          row.quantity = ''
        }
      },
      // 获取 显示什么按钮
      // 商品搜索下拉
      async fetchData(ky = '') {
        if (!this.form.id) {
          this.$message.error('请选择客户')
          return false
        } else {
          let { data, code, msg } = await getInputList({
            pageNo: 1,
            pageSize: -1,
            keyword: ky,
            custom_id: this.form.id,
            depot_id: this.form.depot_id,
          })
          console.log(data)
          if (code == 200) {
            return data
          } else {
            this.$message.error(msg)
          }
        }
      },
      // 表格内商品名称输入框搜索
      querySearch2(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            cb(val)
          })
        }
      },
      jsfsChange(val) {
        if (val == 1) {
          this.form2.xssk[0].money = 0
        } else {
          this.form2.xssk[0].money = 0
        }
      },
      yhInput(val) {
        let v = Number(val).toFixed(2)
        if (v < 0 || v == NaN) {
          this.form2.yh = ''
        }
        this.form2.yhh = (Number(this.form2.xsje) - v).toFixed(2)
        this.yshandler()
      },
      yhhInput(val) {
        console.log(val)
        let v = Number(val)
        if (v < 0 || v == NaN) {
          this.form2.yhh = ''
        }
        console.log(v)

        if (val != '') {
          this.form2.yhje = (Number(this.form2.xsje) - v).toFixed(2)
          this.form2.ysk =
            v -
            Number(this.form2.thtk).toFixed(2) -
            Number(this.form2.dfxj).toFixed(2) -
            Number(this.form2.dfjj).toFixed(2) -
            Number(this.form2.fydk).toFixed(2)
          console.log(this.form2.yhje)
        } else {
          this.form2.ysk = Number(
            this.form2.xsje - this.form2.thtk - this.form2.fydk
          ).toFixed(2)
        }
        this.yshandler()
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = ''
        if (
          row.goods_type == 6 ||
          row.goods_type == 10 ||
          row.goods_type == 11 ||
          row.goods_type == 13 ||
          row.goods_type == 14
        ) {
          price = row.arr_unit.filter((item) => item.id == e)[0].return_price
        } else {
          price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        }
        row.unit_name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        console.log('price', price)
        // row.goods_price = price
        this.$set(row, 'goods_price', price)
        // this.calcForm2(this.list)
      },
      handleSelect(item) {
        console.log(item)
        this.list.unshift(item)
        this.remark = ''
        markDuplicatesById(this.list, 'goods_id')
      },
      // 计算金额数
      moneyN(item, index) {
        let num = 0
        /*  if (Number(item.quantity) && Number(item.goods_price)) {
          num = Number(item.quantity) * Number(item.goods_price)
        }*/
        if (
          item.goods_type == 2 ||
          item.goods_type == 3 ||
          item.goods_type == 10 ||
          item.goods_type == 11
        ) {
          this.$set(this.list[index], 'goods_money', 0)
        } else {
          num = Number(item.quantity) * Number(item.goods_price)
          this.$set(this.list[index], 'goods_money', num.toFixed(2))
        }
      },
      moneyreverse(item, index) {
        let num = 0
        if (Number(item.quantity) && Number(item.goods_money)) {
          num = Number(item.goods_money) / Number(item.quantity)
        }
        this.$set(this.list[index], 'goods_price', num.toFixed(2))
      },
      moneyNum(item) {
        let num = 0
        if (Number(item.quantity) && Number(item.goods_price)) {
          num = Number(item.quantity) * Number(item.goods_price)
        }
        return num.toFixed(2)
      },
      // // 表格添加一行
      // addRow
      // 表格当前行变化
      currentChange(newRow, oldRow) {},
      async getAllSelectList() {
        let { data, code, msg } = await getAllSelect()
        if (code == 200) {
          console.log('所有下拉列表2')
          console.log(data)
          this.allSelectList = data
        }
      },
      // 表格合计
      getSummaries(param) {
        const { columns, data } = param
        console.log(columns, data)
        const sums = []
        this.calcForm2(data)
        console.log('变类型')

        data.forEach((list, index) => {
          console.log(list, '每一列')
          columns.forEach((column, index) => {
            if (index === 0) {
              sums[index] = '合计'
            }
            if (column.label == '订购数量') {
              if (isNaN(sums[index])) {
                sums[index] = 0
              }
              if (
                list.goods_type != 6 &&
                list.goods_type != 10 &&
                list.goods_type != 11 &&
                list.goods_type != 19
              ) {
                sums[index] += Number(list.quantity)
              }
            }
            if (column.label == '金额') {
              sums[index] = this.form2.dgze
            }
          })
        })

        /*columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        // if (index === 12) {
        //   sums[
        //     index
        //   ] = `<el-button type="text" @click.native.prevent="addRow(row)"> 添加 </el-button>`
        //   return
        // }
        const values = data.map((item) => Number(item[column.property]))
        console.log(values)
        if (!values.every((value) => isNaN(value))) {

          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              console.log(column,"這打印的")
              // if(){}
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          console.log(sums)
          if (index == 8) {
            sums[index] = Number(sums[index]).toFixed(2)
          } else if (index == 11) {
            sums[index] = Number(this.form2.dgze).toFixed(2) + ' 元'
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = ' '
        }
      })*/
        // sums[9] = this.form2.dgze
        return sums
      },
      thfsChange() {
        // this.calcForm2(this.list, false)
        return false
      },
      // 计算form2input
      calcForm2(data, o = true) {
        console.log(data)
        let dgze = 0,
          xsje = 0,
          thje = 0,
          yhje = 0,
          yhh = this.form2.yhh,
          thtk = 0,
          ysk = 0
        data.forEach((item) => {
          // 订购总额
          // 销常
          if (
            item.goods_type == 1 ||
            item.goods_type == 17 ||
            item.goods_type == 18
          ) {
            dgze += Number(item.goods_money)
            xsje += Number(item.goods_money)
            // yhh += Number(item.goods_money)
          } else if (item.goods_type == 6 || item.goods_type == 19) {
            // 退货金额
            thje += Number(item.goods_money)
            dgze -= Number(item.goods_money)
            // this.form2.thje =
          }
        })
        this.form2.dgze = Number(dgze).toFixed(2)
        this.form2.xsje = Number(xsje).toFixed(2)
        this.form2.thje = Number(thje).toFixed(2)

        if (o) {
          // this.form2.yhh = Number(yhh).toFixed(2)
          this.form2.yhh = this.form2.yhh
        } else {
          this.form2.yhh = this.form2.yhh
        }
        // if (this.form2.yhh == this.form2.xsje || this.form2.yhh == 0) {
        //   this.form2.yhh = yhh
        // }
        // 退货退款
        if (this.form2.refund_type == 1) {
          this.form2.thtk = thje
        } else {
          this.form2.thtk = 0
        }
        // 应收款
        if (yhh == 0 || yhh == '') {
          console.log('yhh 是0 ', yhh)
          this.form2.ysk =
            this.form2.xsje -
            this.form2.thtk -
            this.form2.yhje -
            this.form2.fydk
        } else {
          console.log('yhh bushi是0 ', yhh, this.form2.thtk)
          console.log(yhh - Number(this.form2.thtk))
          let c =
            JSON.parse(JSON.stringify(yhh)) -
            JSON.parse(JSON.stringify(this.form2.thtk)) -
            this.form2.fydk
          console.log('c', c.toFixed(2))
          let a = c.toFixed(2)
          this.form2.ysk = JSON.parse(JSON.stringify(a))
        }
        // this.form2.yhje=(Number(this.form2.xsje)-Number(this.form2.yyh)).toFixed(2)
      },
      changeClient(row) {
        this.form.ghf = row.goods_name
      },
      handleTypeChange(v, row) {
        console.log('v', v, row)
        if (row.arr_goods_type != undefined) {
          row.type = row.arr_goods_type.filter(
            (list) => list.id == row.goods_type
          )[0].name
        }
        let unitId = row.arr_unit.filter((i) => i.id == row.unit_id)
        if (v == 6 || v == 10 || v == 11 || v == 13 || v == 14 || v == 19) {
          if (unitId.length > 0) {
            row.goods_price = unitId[0].return_price
          }
          // row.goods_price = 0
        } else if (v == 17) {
          row.goods_price = unitId[0].sell_price_te
        } else {
          row.goods_price = unitId[0].sell_price
        }
      },
      // 表格内商品名称输入框搜索
      createStateFilter(queryString) {
        return (state) => {
          return (
            state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          )
        }
      },
      async querySearch(val, cb) {
        let selectData = this.selectRes.data.client
        // let clientRes = await request({
        //   url: '/advance/clientSelect',
        //   method: 'get',
        // })
        // this.selectRes = clientRes.data.client
        console.log(selectData)
        var results = val
          ? selectData.filter(this.createStateFilter(val))
          : selectData
        cb(results)
      },
      tableSearch() {
        console.log(1)
      },
      addInput() {
        this.form2.xssk.push({ money: '', pay_type: '' })
      },
      // 添加行
      // 添加行
      addRow(index, row, $event) {
        console.log(index, $event)
        if (this.list.length == index + 1) {
          this.list.push({
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            depot_stock_num_read: 0,
            child: [],
            edit: true,
            goods_child_id: null,
            goods_money: 0,
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            info: '',
            money: '',
            quantity: '',
            specs: '',
            unit_id: '',
          })
        }
      },
      autoAddRowA() {
        this.list.push({
          edit: true,
          goods_name: '',
          id: +new Date(),
          inventory: '',
          money: '',
          price: '',
          goods_production_date: '',
          remark: '',
          type: '销售',
          units: '',
          specifications: '',
        })
      },
      autoAddRow(index) {
        console.log(index)
        console.log(this.list)

        if (index == this.list.length - 1) {
          this.autoAddRowA()
        }
      },
      // cell编辑
      // saveCellEdit(row) {
      //   edit = false
      //   row.oUnits = row.units
      //   row.oGoods_name = row.goods_name
      //   row.oType = row.type
      //   row.oPrice = row.price
      //   row.oOrder_num = row.order_num
      //   row.oProduction_date = row.production_date
      //   // row.originalTitle = row.title
      // },
      // cancelCellEdit(row) {
      //   // row = row.selectRow
      //   row.units = row.oUnits
      //   row.goods_name = row.oGoods_name
      //   row.type = row.oType
      //   row.price = row.oPrice
      //   row.order_num = row.oOrder_num
      //   row.production_date = row.oProduction_date
      //   edit = false
      //   console.log(row)
      // },
      // 业务员下拉
      async getSaleManList() {
        let { data } = await getStaffList()
        this.ywyList = data
      },
      iconClick(index) {
        console.log(index)
        if (!this.form.id) {
          this.$message.error('请选择客户')
          return false
        } else {
          this.$refs['tableInputWindow'].showDialog = true
          this.$refs['tableInputWindow'].custId = this.form.id
          this.$refs['tableInputWindow'].depotId = this.form.depot_id
          this.$refs['tableInputWindow'].custId = this.form.id
        }
        // this.tableInputWindow = true
      },

      addRows(row, val) {
        console.log('添加rows', row, val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item, index) => {
            item.arr_unit.forEach((unit) => {
              if (unit.is_default == 2) {
                item.unit_id = unit.id
                item.goods_price = unit.sell_price
              }
            })
            item.unit_name = item.arr_unit.filter(
              (list) => list.id == item.unit_id
            )[0].unit_name

            if (item.arr_goods_type != undefined) {
              item.type = item.arr_goods_type.filter(
                (list) => list.id == item.goods_type
              )[0].name
            }

            if (item.unit_id == '') {
              item.arr_unit.forEach((unit) => {
                if (unit.unit_type == 1) {
                  item.unit_id = unit.id
                  item.goods_price = unit.sell_price
                }
              })
            }
            if (item.child) {
              item.child.forEach((unit) => {
                if (unit.id == val.goods_child_id) {
                  item.goods_code = unit.small_unit_code
                }
              })
            }
            if (index == 0) {
              if (row.goods_name != '') {
                console.log(row.goods_name)
                Object.assign(row, item)
              } else {
                this.list.splice(this.list.length - 1, 0, item)
              }
            } else {
              console.log(item, '添加的每一项')
              this.list.splice(this.list.length - 1, 0, item)
            }
          })
          this.$message.success('添加成功')
        }
        markDuplicatesById(this.list, 'goods_id')
        console.log(this.list, '标记重复')
      },
      addRowSave() {
        console.log(this.addRowFrom)
        let data = this.addRowFrom
        Object.assign(data, { id: this.list.length + 1 })
        this.list.push(data)
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        // this.getTableList()
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        // this.getTableList()
      },
      showDialog(row) {
        this.dialogFormVisible = true
        // // 获取表格数据
        // getAdvanceOrderEditTableList().then((res) => {
        //   this.list = res
        // })
      },
      // 出货仓库下拉
      // async getDepotList() {
      //   let { data } = await getAdvanceOrderEditTableList({
      //     id: this.form.id,
      //   })
      //   this.chckList = data.arr_depot
      // },
      copyRow(index, row) {
        let rowS = JSON.parse(JSON.stringify(row))
        let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.list.splice(index + 1, 0, rowa)
        // this.list.push(rowa)
        console.log(rowa)
        markDuplicatesById(this.list, 'goods_id')
      },
      deleteRow(index, row) {
        if (index == this.list.length - 1) {
          // alert('不能删除最后一行')
          this.$message.error('不能删除最后一行')
          return
        }
        this.list.splice(index, 1)
        this.calcForm2(this.list)
        markDuplicatesById(this.list, 'goods_id')
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        // this.dialogFormVisible = false
        this.delVisitedRoute(this.$route.fullPath)
        this.$router.push('advanceOrder')
      },
      closeRow() {
        this.addRowDialog = false
        // this.$refs['addRowFrom'].resetFields() // 重置表单
      },
      // 添加并生成调拨单
      saveAlloct() {
        console.log('调拨单')
        let followUsers = null
        if (
          this.form.follow_users == null ||
          this.form.follow_users == undefined
        ) {
          followUsers = []
        } else {
          followUsers = JSON.parse(JSON.stringify(this.form.follow_users))
        }
        let order_info = JSON.stringify({
          create_at: this.form2.create_at,
          depot_id: this.form.depot_id,
          deliver_id: this.form.deliver_id,
          discounted_amount: this.form2.yhh, // 销售优惠后金额
          refund_type: this.form2.refund_type, // 退货方式 1现金 2转存货 3抵欠款
          account_type: this.form2.account_type, // 结算方式 1货到付款 2账期结算
          remark: this.form2.remark,
          support_amount: this.form2.fydk, //费用抵扣
          fun_ids: this.form2.funds_type.join(','), //费用抵扣选项
          cash_amount: this.form2.dfxj, //兑付现金
          prize_amount: this.form2.dfjj, //兑付金额
          staff_id: this.form.staff_id, // 业务员id
          follow_users: followUsers.join(','),
        })
        let goods_info = JSON.parse(JSON.stringify(this.list))
        // 处理表格最后一行空
        goods_info.forEach((item, index) => {
          if (item.goods_name == '') {
            goods_info.splice(index, 1)
          }
        })
        let pay_info = JSON.stringify(this.form2.xssk)
        let data = {
          cust_id: this.form.id,
          order_info,
          goods_info: JSON.stringify(goods_info),
          pay_info,
        }
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            console.log('end data')
            console.log(data)
            addOrderD(data).then((res) => {
              if (res.code == 200) {
                this.$message.success('添加并调拨成功')
                this.$emit('refresh')
                this.close()
                this.delVisitedRoute(this.$route.fullPath)
                this.$router.push('advanceOrder')
              }
            })
          } else {
            return false
          }
        })
      },
      save() {
        let followUsers = null
        if (
          this.form.follow_users == null ||
          this.form.follow_users == undefined
        ) {
          followUsers = []
        } else {
          followUsers = JSON.parse(JSON.stringify(this.form.follow_users))
        }
        let order_info = JSON.stringify({
          create_at: this.form2.create_at,
          depot_id: this.form.depot_id,
          deliver_id: this.form.deliver_id,
          discounted_amount: this.form2.yhh, // 销售优惠后金额
          refund_type: this.form2.refund_type, // 退货方式 1现金 2转存货 3抵欠款
          account_type: this.form2.account_type, // 结算方式 1货到付款 2账期结算
          support_amount: this.form2.fydk, //费用抵扣
          fun_ids: this.form2.funds_type.join(','), //费用抵扣选项
          cash_amount: this.form2.dfxj, //兑付现金
          prize_amount: this.form2.dfjj, //兑付金额
          remark: this.form2.remark,
          staff_id: this.form.staff_id, // 业务员id
          follow_users: followUsers.join(','),
        })
        let goods_info = JSON.parse(JSON.stringify(this.list))
        // 处理表格最后一行空
        goods_info.forEach((item, index) => {
          if (item.goods_name == '') {
            goods_info.splice(index, 1)
          }
          delete item.arr_unit
          delete item.arr_goods_type
        })
        console.log('goods_info')
        console.log(goods_info)
        let pay_info = JSON.stringify(this.form2.xssk)
        let data = {
          cust_id: this.form.id,
          order_info,
          goods_info: JSON.stringify(goods_info),
          pay_info,
        }
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            console.log('end data')
            console.log(data)
            addOrder(data).then((res) => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.$emit('refresh')
                this.close()
                this.delVisitedRoute(this.$route.fullPath)
                this.$router.push('advanceOrder')
              }
            })
          } else {
            return false
          }
        })
      },
      // 出货仓库改变
      changeDepot(val) {
        driverList({ depot_id: val }).then((res) => {
          if (res.code == 200) {
            console.log(res.data)
            this.shrList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      setCustName(val) {
        this.form.cust_name = val
      },
      resetForm() {
        this.$refs.form.resetFields()
        this.$refs.form2.resetFields()
        this.form2.xssk = [
          {
            money: '',
            pay_type: '',
          },
        ]
        this.$refs.clientSearch.resetForm()
      },
      custInput(val) {
        console.log('custInput')
        console.log(val)
        this.cust_id = ''
        this.form.address = ''
        this.form.id = ''
        this.form.lxr = ''
      },
      setAllInput(val) {
        console.log('setAllInput')
        console.log(val)
        if (val != '') {
          if (val.pay_way !== '') {
            // 选中结算方式
            this.form2.account_type = Number(val.pay_way)
          }

          this.cust_id = ''
          let lxr = { lxr: val.boss + ' ' + val.mobile }
          console.log(val, lxr)
          const depot_id = this.form.depot_id
          this.form = Object.assign(val, lxr, { depot_id })
        } else {
          this.form2.account_type = ''
          this.form.id = ''
          this.form.address = ''
          this.form.area_name = ''
          this.form.boss = ''
          this.form.lxr = ''
        }
      },
      selectGoods(val, row, index) {
        this.$set(row, 'type', '')
        console.log('selectGoods', val, row, index)
        console.log(row)
        row.goods_name = val.goods_name

        Object.assign(row, val, { goods_type: 1 })
        row.id = ''
        if (val.child) {
          val.child.forEach((unit) => {
            if (unit.id == val.goods_child_id) {
              row.goods_code = unit.small_unit_code
            }
          })
        }
        if (val.arr_unit.length != 0) {
          console.log(
            val.arr_unit.filter((item) => item.id == val.unit_id)[0].unit_name
          )
          row.unit_name = row.arr_unit.filter(
            (item) => item.id == val.unit_id
          )[0].unit_name
        }
        if (row.arr_goods_type != undefined) {
          row.type = row.arr_goods_type.filter(
            (list) => list.id == row.goods_type
          )[0].name
        }

        if (this.list.length == index + 1) {
          this.list.push({
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            depot_stock_num: 0,
            child: [],
            edit: true,
            goods_child_id: null,
            goods_id: null,
            goods_money: 0,
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            info: '',
            money: '',
            quantity: '',
            specs: '',
            unit_id: '',
            unit_name: '',
          })
        }
        // this.addRow(this.list.length - 1, row, '1')
        markDuplicatesById(this.list, 'goods_id')
      },
      changeKouwei(val, row) {
        console.log('改变口味', val)
        row.goods_child_id = val.id
        row.goods_name = val.goods_name + ` (${val.attr})`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .icon-btn {
    position: absolute;
    top: 8px;
    right: 13px;
    border: 0px solid transparent;
  }
  .el-form-item__content {
    position: relative;
    margin: 5px 0;
  }
  .el-input__inner {
    padding-right: 30px;
  }
  .setFilter {
    top: -4px;
    left: 8px;
    margin-bottom: -40px;
  }
  .addbtn {
    position: absolute;
    top: 0;
    right: -50px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
  .el-dialog__body {
    padding: 5px 20px;
  }
</style>
<style lang="scss">
  .goodsname {
    display: grid;
    grid-template-columns: auto auto;
  }
  .namewith {
    width: 160px;
  }
  .namewithy {
    width: 160px;
    color: #ef833b;
  }
  .goodsname > i {
  }
  .trsher {
    position: absolute;
    top: 0px;
    right: 0px;
    //z-index: 2001;
    transform: rotate(-45deg);
  }
  //<i class="el-icon-caret-right trsher">
</style>
