export function parseStringToObjects(str) {
  const regex = /(-?\d*\.?\d+)\s*([^\d\s-]+)/g
  const result = []
  let match

  while ((match = regex.exec(str)) !== null) {
    const num = match[1]
    const unit = match[2]
    result.push({ num, unit })
    // parseInt(match[1]);
  }

  return result
}
