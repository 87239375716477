var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-container" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { lg: 14, md: 11, sm: 24, xl: 14, xs: 24 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符"),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { lg: 9, md: 12, sm: 24, xl: 9, xs: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "registerForm",
                  staticClass: "register-form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.registerRules,
                    size: "mini",
                  },
                },
                [
                  _c("div", { staticClass: "title-tips" }, [
                    _vm._v(_vm._s(_vm.translateTitle("注册"))),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          attrs: {
                            placeholder: _vm.translateTitle("请输入姓名"),
                            "auto-complete": "off",
                            type: "text",
                          },
                          model: {
                            value: _vm.form.username,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "username",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.username",
                          },
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { slot: "prefix", icon: "user-line" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: _vm.translateTitle("请输入电话"),
                            maxlength: "11",
                            "show-word-limit": "",
                            type: "text",
                          },
                          model: {
                            value: _vm.form.phone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.phone",
                          },
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { slot: "prefix", icon: "smartphone-line" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "select", attrs: { prop: "province_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "省" },
                          on: { change: _vm.shengChange },
                          model: {
                            value: _vm.form.province_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "province_id", $$v)
                            },
                            expression: "form.province_id",
                          },
                        },
                        _vm._l(_vm.shengSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.title },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "select", attrs: { prop: "province_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "市" },
                          model: {
                            value: _vm.form.city_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "city_id", $$v)
                            },
                            expression: "form.city_id",
                          },
                        },
                        _vm._l(_vm.shiSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.title },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "comapny" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: _vm.translateTitle("请输入公司"),
                            type: "text",
                          },
                          model: {
                            value: _vm.form.comapny,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "comapny",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.comapny",
                          },
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { slot: "prefix", icon: "building-line" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "select",
                      attrs: { label: "", prop: "indust_id" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { clearable: "", placeholder: "请选择行业" },
                          model: {
                            value: _vm.form.indust_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "indust_id", $$v)
                            },
                            expression: "form.indust_id",
                          },
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { slot: "prefix", icon: "briefcase-2-line" },
                            slot: "prefix",
                          }),
                          _vm._l(_vm.hangeyeSelect, function (i, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { value: i.id, label: i.name },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "select", attrs: { prop: "post_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { clearable: "", placeholder: "请选择职务" },
                          model: {
                            value: _vm.form.post_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "post_id", $$v)
                            },
                            expression: "form.post_id",
                          },
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { slot: "prefix", icon: "briefcase-2-line" },
                            slot: "prefix",
                          }),
                          _vm._l(_vm.zhiwuSelect, function (i, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { value: i.id, label: i.name },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "code" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "180px" },
                              attrs: { placeholder: "请输入验证码" },
                              model: {
                                value: _vm.form.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "code", $$v)
                                },
                                expression: "form.code",
                              },
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: {
                                  slot: "prefix",
                                  icon: "chat-private-line",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "10px",
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _vm.codeImg
                                ? _c("img", {
                                    staticStyle: { width: "100%" },
                                    attrs: { src: _vm.codeImg, alt: "" },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.getCodeData },
                        },
                        [_vm._v(" 看不清，换一张 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "register-btn",
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleRegister.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.translateTitle("注册")) + " ")]
                      ),
                      _c("router-link", { attrs: { to: "/login" } }, [
                        _c("div", { staticStyle: { "margin-top": "20px" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.translateTitle("登录")) + " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { lg: 1, md: 1, sm: 24, xl: 1, xs: 24 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符"),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }