var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.shouNewAdd,
        center: "",
        fullscreen: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.shouNewAdd = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "购货方:", prop: "cust_name" } },
                [
                  _c("client-search", {
                    ref: "clientSearch",
                    staticStyle: { width: "192.6px" },
                    attrs: { "popper-class": "selectDC" },
                    on: { "select-val-alldata": _vm.clientSelect },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "仓库:", prop: "depot_name" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择",
                        size: "mini",
                      },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.depotSelect, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.depot_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.allSelectList.ckzx_falg == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "业务员:", prop: "staff_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { clearable: "", size: "mini" },
                          model: {
                            value: _vm.form.staff_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "staff_id", $$v)
                            },
                            expression: "form.staff_id",
                          },
                        },
                        _vm._l(_vm.staffList, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "快捷输入:" } },
                [
                  _c("el-autocomplete", {
                    staticClass: "inline-input",
                    staticStyle: { width: "220px" },
                    attrs: {
                      size: "mini",
                      disabled: _vm.tableEdit,
                      "value-key": "goods_name",
                      "fetch-suggestions": _vm.handlerremark,
                      placeholder: "请输入内容",
                      "trigger-on-focus": false,
                    },
                    on: { select: _vm.selectremark },
                    model: {
                      value: _vm.remark,
                      callback: function ($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人:", prop: "boss" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { disabled: "", size: "mini" },
                    model: {
                      value: _vm.form.boss,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "boss", $$v)
                      },
                      expression: "form.boss",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话:", prop: "mobile" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { disabled: "", size: "mini" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址:", prop: "address" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "620px" },
                    attrs: { disabled: "", size: "mini" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  ref: "tableSort",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tablelist,
                    height: "560",
                    border: "",
                    size: "mini",
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                  },
                  on: { "header-dragend": _vm.hanlerdragend },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { type: "index", width: "40px" } },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "custom-table-checkbox",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "space-between",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          model: {
                                            value: _vm.checkList,
                                            callback: function ($$v) {
                                              _vm.checkList = $$v
                                            },
                                            expression: "checkList",
                                          },
                                        },
                                        _vm._l(
                                          _vm.columns,
                                          function (item, index) {
                                            return _c("el-checkbox", {
                                              key: index,
                                              attrs: { label: item.label },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [
                                  _c("vab-remix-icon", {
                                    attrs: { icon: "settings-line" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._l(_vm.fluterList, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                _vm.currentEditingField == item.prop &&
                                _vm.editingItem &&
                                _vm.editingRowIndex === $index
                                  ? [
                                      item.prop === "type"
                                        ? [
                                            _c(
                                              "el-select",
                                              {
                                                directives: [
                                                  {
                                                    name: "focus",
                                                    rawName: "v-focus",
                                                  },
                                                ],
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.typeChange($event, row),
                                                      _vm.utilsF(),
                                                      _vm.moneyN(row, $index)
                                                  },
                                                },
                                                nativeOn: {
                                                  keydown: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "tab",
                                                        9,
                                                        $event.key,
                                                        "Tab"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.handleTabKey(
                                                      $event,
                                                      row,
                                                      item.prop,
                                                      $index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: row.goods_type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "goods_type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.goods_type",
                                                },
                                              },
                                              _vm._l(
                                                row.arr_goods_type,
                                                function (item_) {
                                                  return _c("el-option", {
                                                    key: item_.id,
                                                    attrs: {
                                                      label: item_.name,
                                                      value: item_.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        : item.prop === "goods_name"
                                        ? [
                                            _c("goods-search", {
                                              directives: [
                                                {
                                                  name: "focus",
                                                  rawName: "v-focus",
                                                },
                                              ],
                                              ref: "goodsSearch",
                                              refInFor: true,
                                              attrs: {
                                                "f-key": row.goods_name,
                                                kouwei: row.child,
                                                rowf: row,
                                                "cust-id": _vm.cust_id,
                                                "depot-id": _vm.form.depot_id,
                                              },
                                              on: {
                                                "add-rows": _vm.addRows,
                                                "change-kouwei": function (
                                                  $event
                                                ) {
                                                  return _vm.changeKouwei(
                                                    $event,
                                                    row
                                                  )
                                                },
                                                "select-goods-all": function (
                                                  $event
                                                ) {
                                                  return _vm.handleSelect(
                                                    $event,
                                                    row,
                                                    $index
                                                  )
                                                },
                                              },
                                              nativeOn: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "tab",
                                                      9,
                                                      $event.key,
                                                      "Tab"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.handleTabKey(
                                                    $event,
                                                    row,
                                                    item.prop,
                                                    $index
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        : item.prop === "unit_name"
                                        ? [
                                            _c(
                                              "el-select",
                                              {
                                                directives: [
                                                  {
                                                    name: "focus",
                                                    rawName: "v-focus",
                                                  },
                                                ],
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.unitChange(
                                                      $event,
                                                      row
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  keydown: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "tab",
                                                        9,
                                                        $event.key,
                                                        "Tab"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.handleTabKey(
                                                      $event,
                                                      row,
                                                      item.prop,
                                                      $index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: row["unit_id"],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "unit_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row['unit_id']",
                                                },
                                              },
                                              _vm._l(
                                                row.arr_unit,
                                                function (item_) {
                                                  return _c("el-option", {
                                                    key: item_.id,
                                                    attrs: {
                                                      label: item_.unit_name,
                                                      value: item_.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        : item.prop === "goods_price"
                                        ? [
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "focus",
                                                  rawName: "v-focus",
                                                },
                                              ],
                                              attrs: {
                                                placeholder: "商品单价",
                                                onkeyup:
                                                  "value=value.replace(/[^\\-?\\d.]/g,'')",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.moneyN(row, $index),
                                                    _vm.utilsF()
                                                },
                                              },
                                              nativeOn: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "tab",
                                                      9,
                                                      $event.key,
                                                      "Tab"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.handleTabKey(
                                                    $event,
                                                    row,
                                                    item.prop,
                                                    $index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(row, item.prop, $$v)
                                                },
                                                expression: "row[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.prop === "quantity"
                                        ? [
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "focus",
                                                  rawName: "v-focus",
                                                },
                                              ],
                                              attrs: {
                                                onkeyup:
                                                  "value=value.replace(/[^\\-?\\d.]/g,'')",
                                                placeholder: "数量",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.moneyN(row, $index),
                                                    _vm.utilsF()
                                                },
                                              },
                                              nativeOn: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "tab",
                                                      9,
                                                      $event.key,
                                                      "Tab"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.handleTabKey(
                                                    $event,
                                                    row,
                                                    item.prop,
                                                    $index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(row, item.prop, $$v)
                                                },
                                                expression: "row[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.prop === "goods_money"
                                        ? [
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "focus",
                                                  rawName: "v-focus",
                                                },
                                              ],
                                              attrs: { placeholder: "金额" },
                                              on: {
                                                input: function ($event) {
                                                  _vm.summaryInput($event, row),
                                                    _vm.utilsF()
                                                },
                                              },
                                              nativeOn: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "tab",
                                                      9,
                                                      $event.key,
                                                      "Tab"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.handleTabKey(
                                                    $event,
                                                    row,
                                                    item.prop,
                                                    $index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(row, item.prop, $$v)
                                                },
                                                expression: "row[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.prop === "goods_production_date"
                                        ? [
                                            _c("el-date-picker", {
                                              directives: [
                                                {
                                                  name: "focus",
                                                  rawName: "v-focus",
                                                },
                                              ],
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                type: "date",
                                                placeholder: "选择日期",
                                                format: "yyyy 年 MM 月 dd 日",
                                                "value-format": "yyyy-MM-dd",
                                              },
                                              nativeOn: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "tab",
                                                      9,
                                                      $event.key,
                                                      "Tab"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.handleTabKey(
                                                    $event,
                                                    row,
                                                    item.prop,
                                                    $index
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  row.goods_production_date,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "goods_production_date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.goods_production_date",
                                              },
                                            }),
                                          ]
                                        : item.prop === "info"
                                        ? [
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "focus",
                                                  rawName: "v-focus",
                                                },
                                              ],
                                              attrs: {
                                                placeholder: "商品备注",
                                              },
                                              nativeOn: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "tab",
                                                      9,
                                                      $event.key,
                                                      "Tab"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.handleTabKey(
                                                    $event,
                                                    row,
                                                    item.prop,
                                                    $index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row.info,
                                                callback: function ($$v) {
                                                  _vm.$set(row, "info", $$v)
                                                },
                                                expression: "row.info",
                                              },
                                            }),
                                          ]
                                        : [
                                            _vm._v(
                                              " " + _vm._s(row[item.prop]) + " "
                                            ),
                                          ],
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "cell-content",
                                          on: {
                                            click: function ($event) {
                                              _vm.editItem(row, $index)
                                              _vm.currentEditingField =
                                                item.prop
                                            },
                                          },
                                        },
                                        [
                                          item.prop === "type" ||
                                          item.prop === "goods_name" ||
                                          item.prop === "unit_name" ||
                                          item.prop === "goods_price" ||
                                          item.prop === "quantity" ||
                                          item.prop === "goods_money" ||
                                          item.prop ===
                                            "goods_production_date" ||
                                          item.prop === "info"
                                            ? [
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row[item.prop] || " "
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("i", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !row[item.prop],
                                                        expression:
                                                          "!row[item.prop]",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "el-icon-caret-right trsher",
                                                  }),
                                                ]),
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row[item.prop] || " "
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ],
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", align: "center", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.copyRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c("div", { staticStyle: { "margin-top": "20px" } }),
              _c(
                "el-form-item",
                { attrs: { label: "订单金额" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.total_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "total_amount", $$v)
                      },
                      expression: "form.total_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结算方式" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "192.6px" },
                      attrs: { clearable: "", placeholder: "结算方式" },
                      model: {
                        value: _vm.form.account_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "account_type", $$v)
                        },
                        expression: "form.account_type",
                      },
                    },
                    _vm._l(_vm.jsfs, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "800px" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "销售金额" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.sale_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "sale_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.sale_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货金额" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.refund_goods_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "refund_goods_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.refund_goods_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货方式" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "186px" },
                      attrs: { placeholder: "退货方式" },
                      on: { change: _vm.utilsF },
                      model: {
                        value: _vm.form.refund_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "refund_type", $$v)
                        },
                        expression: "form.refund_type",
                      },
                    },
                    _vm._l(_vm.allSelectList.arr_refund_type, function (item_) {
                      return _c("el-option", {
                        key: item_.id,
                        attrs: { label: item_.name, value: item_.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退货退款" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.refund_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "refund_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.refund_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优惠" } },
                [
                  _c("el-input", {
                    on: {
                      input: function ($event) {
                        return _vm.utilsF()
                      },
                    },
                    model: {
                      value: _vm.form.discount_amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "discount_amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.discount_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应收款" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.receiv_money,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "receiv_money", $$v)
                      },
                      expression: "form.receiv_money",
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.pay_info, function (item1, index) {
                return _c(
                  "el-form-item",
                  { key: index, attrs: { label: "实收款:" } },
                  [
                    _c("label", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "90px",
                              "margin-right": "20px",
                            },
                            attrs: { type: "number" },
                            model: {
                              value: item1.money,
                              callback: function ($$v) {
                                _vm.$set(
                                  item1,
                                  "money",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "item1.money",
                            },
                          }),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "90px" },
                              model: {
                                value: item1.pay_type,
                                callback: function ($$v) {
                                  _vm.$set(item1, "pay_type", $$v)
                                },
                                expression: "item1.pay_type",
                              },
                            },
                            _vm._l(
                              _vm.allSelectList.arr_pay_type,
                              function (item_1) {
                                return _c("el-option", {
                                  key: item_1.id,
                                  attrs: {
                                    label: item_1.name,
                                    value: item_1.id,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      index == 0
                        ? _c("i", {
                            staticClass: "el-icon-circle-plus addbtn",
                            on: { click: _vm.addInput },
                          })
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
              },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.shouNewAdd = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }